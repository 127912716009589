import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, delay, mergeMap, of, withLatestFrom } from 'rxjs';
import { CommonUtilsService } from 'taxilla-library';

import {
    ClearEninvoiceInstancesPagination,
    ClearEninvoiceSearchReports,
    ClearEninvoiceSearchReportsPagination,
    ClearEninvoiceSelectedInstanceWorkflow,
    ClearInstancesPagination,
    ClearSearchReports,
    ClearSearchReportsPagination,
    DownloadEninvoiceSearchReport,
    DownloadSearchReport,
    EninvoiceSelectedInstanceGenerateReport,
    ExecuteWorkflowStage,
    GenerateReport,
    GetAppBridgeNodes,
    GetAppMetaData,
    GetEninvoiceAppMetaData,
    GetEninvoiceInstance,
    GetEninvoiceSearchReports,
    GetEninvoiceSearchReportsPagination,
    GetEninvoiceSelectedInstanceReports,
    GetEninvoiceSelectedInstanceWorkflow,
    GetEninvoiceTransformationChains,
    GetOutboundChains,
    GetReports,
    GetSearchReports,
    GetSearchReportsPagination,
    GetWorkflow,
    SearchEninvoiceInstances,
    SearchInstances,
    SetEninvoiceInstancesPagination,
    SetEninvoiceInstancesSearchCriteria,
    SetEninvoiceSelectedAppDetails,
    SetEninvoiceSelectedInstanceId,
    SetInstancesPagination,
    SetSearchInstancesCriteria,
    SetWorkflow,
    SubmitEninvoiceWorkflow,
    UpdateEninvoiceSearchReportsPagination,
    UpdateSearchReportsPagination,
} from '../actions';
import { ClearSearchInstances, GetInstance } from '../actions/instances.actions';
import {
    getCurrentOrganizationId$,
    getEninvoiceAppMetaData$,
    getEninvoiceInstancesPagination$,
    getEninvoiceSelectedApp$,
    getEninvoiceSelectedBridgeApp$,
    getEninvoiceSelectedInstance$,
    getEninvoiceSelectedInstanceId$,
    getEninvoiceSelectedRequestId$,
    getSubscribedApps$,
} from '../selectors';

@Injectable()
export class EninvoiceEffects {
    constructor(private actions$: Actions, private store$: Store) {}

    setEninvoiceSelectedAppDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetEninvoiceSelectedInstanceId, SetEninvoiceSelectedAppDetails),
            debounceTime(100),
            mergeMap((_action) => {
                return of(GetEninvoiceAppMetaData());
            })
        );
    });

    getEninvoiceAppMetaData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceAppMetaData),
            withLatestFrom(
                this.store$.select(getSubscribedApps$),
                this.store$.select(getEninvoiceSelectedBridgeApp$),
                this.store$.select(getEninvoiceSelectedApp$),
                this.store$.select(getEninvoiceSelectedInstance$)
            ),
            mergeMap(([_action, apps, bridge, app, instance]) => {
                if (!apps || apps.length === 0) {
                    setTimeout(() => {
                        this.store$.dispatch(GetEninvoiceAppMetaData());
                    }, 250);
                    return [];
                }
                if (bridge?.assetType === 'BRIDGE_ASSET' && !app?.restApiName) {
                    setTimeout(() => {
                        this.store$.dispatch(GetEninvoiceAppMetaData());
                    }, 250);
                    return of(
                        GetAppBridgeNodes({
                            assetId: bridge?.assetMetaUId,
                            serviceId: bridge?.serviceId,
                        })
                    );
                }
                if (!app?.assetMetaUId) {
                    return [];
                }
                return of(
                    GetAppMetaData({
                        serviceId: app?.serviceId,
                        assetId: instance?.assetId || app?.assetMetaUId,
                    })
                );
            })
        );
    });

    clearEninvoiceInstancesPagination = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClearEninvoiceInstancesPagination),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([_action, app, organizationId]) => {
                if (!app?.assetMetaUId) {
                    return [];
                }
                this.store$.dispatch(
                    ClearInstancesPagination({
                        serviceId: app?.serviceId,
                        organizationId,
                    })
                );
                this.store$.dispatch(
                    ClearSearchInstances({
                        serviceId: app.serviceId,
                        criteriaName: 'eninvoice',
                        organizationId,
                    })
                );
                return [];
            })
        );
    });

    setEninvoiceInstancesPagination = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetEninvoiceInstancesPagination),
            withLatestFrom(
                this.store$.select(getEninvoiceSelectedApp$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getEninvoiceInstancesPagination$)
            ),
            mergeMap(([action, app, organizationId, pagination]) => {
                if (!app?.assetMetaUId) {
                    return [];
                }
                if (!!pagination.size && pagination.size !== action.size) {
                    this.store$.dispatch(
                        ClearInstancesPagination({
                            serviceId: app?.serviceId,
                            organizationId,
                        })
                    );
                    this.store$.dispatch(
                        ClearSearchInstances({
                            serviceId: app.serviceId,
                            criteriaName: 'eninvoice',
                            organizationId,
                        })
                    );
                }
                setTimeout(() => {
                    this.store$.dispatch(
                        SetInstancesPagination({
                            serviceId: app?.serviceId,
                            organizationId,
                            ...action,
                        })
                    );
                }, 10);
                return [];
            })
        );
    });

    setEninvoiceInstancesSearchCriteria = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetEninvoiceInstancesSearchCriteria),
            withLatestFrom(
                this.store$.select(getEninvoiceSelectedApp$),
                this.store$.select(getEninvoiceAppMetaData$),
                this.store$.select(getEninvoiceSelectedRequestId$)
            ),
            mergeMap(([action, app, metaData, requestId]) => {
                if (!app?.assetMetaUId || !metaData?.restAPIName) {
                    return [];
                }
                if (action.criteria) {
                    return of(
                        SetSearchInstancesCriteria({
                            serviceId: app?.serviceId,
                            criteriaName: 'eninvoice',
                            searchCriteria: action.criteria,
                        })
                    );
                }
                const primaryEntity = metaData?.getPrimaryEntity();
                const searchText = action.freeTextSearch;
                const rules = [
                    {
                        operator: 'IN',
                        fieldId: 'instanceState.raw',
                        restrictionType: 'SingleRestrictionMetadata',
                        value: ['IN_PROGRESS', 'REQUEST_COMPLETED'],
                    },
                ];
                const searchableFields = primaryEntity?.fields?.filter((field) => field.searchable && field.show);
                if (searchText?.length > 0 && searchableFields?.length > 0) {
                    const subRules = [];
                    searchableFields.forEach((field) => {
                        subRules.push({
                            fieldId: field?.uid,
                            operator: 'CONTAINS',
                            restrictionType: 'SingleRestrictionMetadata',
                            value: `*${action.freeTextSearch}*`,
                        });
                    });
                    rules.push({
                        condition: 'OR',
                        restrictionType: 'JoinedRestrictionMetadata',
                        rules: subRules,
                    } as any);
                }
                if (requestId?.length > 0) {
                    rules.push({
                        fieldId: 'requestId',
                        operator: 'EQ',
                        restrictionType: 'SingleRestrictionMetadata',
                        value: requestId as any,
                    });
                }
                return of(
                    SetSearchInstancesCriteria({
                        serviceId: app?.serviceId,
                        criteriaName: 'eninvoice',
                        searchCriteria: {
                            entityId: primaryEntity?.uid,
                            restApiServiceName: app?.restApiName,
                            sort: {},
                            filterCriteria: {
                                entityFilterCriterias: [
                                    {
                                        entityUid: primaryEntity?.uid,
                                        criteriaDefinitions: [
                                            {
                                                type: 'CriteriaDefinition',
                                                criteriaName: primaryEntity?.uid,
                                                restriction: {
                                                    condition: 'AND',
                                                    rules,
                                                    restrictionType: 'JoinedRestrictionMetadata',
                                                },
                                            },
                                        ],
                                    },
                                ],
                                criteriaRelations: {
                                    condition: 'AND',
                                    rules: [
                                        {
                                            operator: 'EQ',
                                            value: primaryEntity?.uid,
                                            fieldId: 'Criteria',
                                            restrictionType: 'SingleRestrictionMetadata',
                                        },
                                    ],
                                    restrictionType: 'JoinedRestrictionMetadata',
                                },
                            },
                            size: 100,
                            loadRequests: true,
                        },
                    })
                );
            })
        );
    });

    getEninvoiceSelectedInstanceReports = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceSelectedInstanceReports),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$), this.store$.select(getEninvoiceSelectedInstance$)),
            mergeMap(([_action, app, instance]) => {
                return of(
                    GetReports({
                        instanceId: instance.assetDataId,
                        serviceId: app?.serviceId,
                        assetId: instance.assetId,
                        noAlerts: _action.noAlerts,
                        forceFetch: _action.forceFetch,
                    })
                );
            })
        );
    });

    eninvoiceSelectedInstanceGenerateReport = createEffect(() => {
        return this.actions$.pipe(
            ofType(EninvoiceSelectedInstanceGenerateReport),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$), this.store$.select(getEninvoiceSelectedInstance$)),
            mergeMap(([_action, app, instance]) => {
                return of(
                    GenerateReport({
                        instanceId: instance.assetDataId,
                        serviceId: app?.serviceId,
                        chainName: _action.chainName,
                        repositoryId: _action.repositoryId,
                        transformationName: undefined,
                        noAlerts: false,
                        timeStamp: CommonUtilsService.getOrgAndUserOffsetTimeStamp(),
                    })
                );
            })
        );
    });

    searchInstances = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchEninvoiceInstances),
            delay(10),
            withLatestFrom(
                this.store$.select(getEninvoiceSelectedApp$),
                this.store$.select(getEninvoiceAppMetaData$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([_action, app, metaData, organizationId]) => {
                if (!app?.assetMetaUId || !metaData?.restAPIName) {
                    return [];
                }
                return of(
                    SearchInstances({
                        criteriaName: 'eninvoice',
                        organizationId,
                        serviceId: app?.serviceId,
                        isBackgroundProcess: false,
                        noAlerts: false,
                        forceFetch: _action.forceFetch,
                    })
                );
            })
        );
    });

    getEninvoiceSearchReportsPagination = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceSearchReportsPagination),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    GetSearchReportsPagination({
                        serviceId: app?.serviceId,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    updateEninvoiceSearchReportsPagination = createEffect(() => {
        return this.actions$.pipe(
            ofType(UpdateEninvoiceSearchReportsPagination),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    UpdateSearchReportsPagination({
                        serviceId: app?.serviceId,
                        pageSize: _action.pageSize,
                        pageIndex: _action.pageIndex,
                        pagingState: _action.pagingState,
                        timeRange: _action.timeRange,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    clearEninvoiceSearchReportsPagination = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClearEninvoiceSearchReportsPagination),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    ClearSearchReportsPagination({
                        serviceId: app?.serviceId,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    getEninvoiceSearchReports = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceSearchReports),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    GetSearchReports({
                        serviceId: app?.serviceId,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    clearEninvoiceSearchReports = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClearEninvoiceSearchReports),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    ClearSearchReports({
                        serviceId: app?.serviceId,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    getEninvoiceTransformationChains = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceTransformationChains),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    GetOutboundChains({
                        serviceId: app?.serviceId,
                    })
                );
            })
        );
    });

    downloadEninvoiceSearchReport = createEffect(() => {
        return this.actions$.pipe(
            ofType(DownloadEninvoiceSearchReport),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([_action, app]) => {
                return of(
                    DownloadSearchReport({
                        serviceId: app?.serviceId,
                        assetId: app?.assetMetaUId,
                        requestId: _action.requestId,
                        repositoryId: _action.repositoryId,
                        transformationName: _action.transformationName,
                        noAlerts: false,
                    })
                );
            })
        );
    });

    GetEninvoiceSelectedInstanceWorkflow = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceSelectedInstanceWorkflow),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$), this.store$.select(getEninvoiceSelectedInstanceId$)),
            mergeMap(([_action, app, instanceId]) => {
                if (!instanceId) {
                    return [];
                }
                return of(
                    GetWorkflow({
                        instanceId: instanceId,
                        serviceId: app?.serviceId,
                        noAlerts: _action.noAlerts,
                        restApiName: app?.restApiName,
                        notFirstIteration: _action?.notFirstIteration,
                        configurations: _action?.configurations,
                    })
                );
            })
        );
    });

    ClearEninvoiceSelectedInstanceWorkflow = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClearEninvoiceSelectedInstanceWorkflow),
            withLatestFrom(this.store$.select(getEninvoiceSelectedInstance$)),
            mergeMap(([_action, instance]) => {
                if (!instance?.assetDataId) {
                    return [];
                }
                return of(
                    SetWorkflow({
                        instanceId: instance.assetDataId,
                        workflows: [],
                    })
                );
            })
        );
    });

    EninvoiceSubmitWorkflow = createEffect(() => {
        return this.actions$.pipe(
            ofType(SubmitEninvoiceWorkflow),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$), this.store$.select(getEninvoiceSelectedInstance$)),
            mergeMap(([_action, app, instance]) => {
                if (!instance?.assetDataId) {
                    return [];
                }
                return of(
                    ExecuteWorkflowStage({
                        apiAction: _action.action.name,
                        instanceIds: [instance.assetDataId],
                        comment: _action.comment,
                        formFields: _action.formFields,
                        serviceId: app?.serviceId,
                        executeTimestamp: _action.executeTimestamp,
                    })
                );
            })
        );
    });

    getEninvoiceInstance = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetEninvoiceInstance),
            withLatestFrom(this.store$.select(getEninvoiceSelectedApp$)),
            mergeMap(([action, app]) => {
                return of(
                    GetInstance({
                        serviceId: app?.serviceId,
                        instanceId: action.instanceId,
                        noAlerts: false,
                    })
                );
            })
        );
    });
}
