import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AssetService } from '../../models/assetservice.class';
import { Instance } from '../../models/instance.interface';
import { RecordReports } from '../../models/record/recordreports.class';
import { Report } from '../../models/report.interface';
import { FilterCriteria } from '../../models/searchcriteria.global';
import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class InstancesService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService, private store$: Store) {}

    /**
     * Method to get instance status
     * @param data Contains instanceId,
     * @param callbacks Contains successCallback,
     */
    getInstanceStatus = (data: { instanceId: string; serviceId?: string; restApiName?: string; noAlerts?: boolean }) => {
        return new Promise<{
            [property: string]: string;
        }>((resolve, reject) => {
            this._bridge.checkInstanceStatus(
                data,
                (res: { response: { [property: string]: string }; success: boolean }) => {
                    resolve((res && res.response) || {});
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get invoice status';
                    this.store$.dispatch(AlertError({ message: msg }));
                    reject(msg);
                }
            );
        });
    };

    /**
     * Method to fetch reports
     * @param data Contains service data, instance id,
     * @param analytics Reports meta data
     * @param callbacks Contains Success callback method,
     */
    fetchReportsData = (
        data: { instanceId: string; serviceId: string; service: AssetService; noAlerts: boolean },
        analytics,
        callbacks: { successCallback: (reports: RecordReports) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getAnalyticReports(
            { instanceId: data.instanceId, serviceId: data.serviceId, restApiName: data.service.restApiName, noAlerts: data.noAlerts },
            (res) => {
                let reports: RecordReports;
                if (res && res.reports) {
                    reports = new RecordReports(analytics, (res && res.reports) || [], data.instanceId);
                } else {
                    reports = new RecordReports(analytics, (res && res.response) || [], data.instanceId);
                }
                callbacks.successCallback(reports);
            },
            (res) => {
                if (callbacks && callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                }
                this._utils.alertError((res && res.msg) || 'Failed to get reports for ' + data.service.name);
            }
        );
    };

    /**
     * Method to fetch reports by using reports meta data
     * @param data Contains service data, instance id,
     * @param callbacks Contains Success callback method,
     */
    getAnalyticReportsMetaData = (
        data: { service: AssetService; instanceId?: string; providerOrgId?: string; assetId?: string; noAlerts?: boolean },
        callbacks: {
            successCallback: (reports: { [property: string]: RecordReports[] }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getAnalyticsMetaData(
            data.assetId || data.service.assetMetaUId,
            (res) => {
                callbacks.successCallback((res && res.response && res.response.analytics) || {});
            },
            (res) => {
                if (callbacks && callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                }
                this._utils.alertError((res && res.msg) || 'Failed to get reports meta data for ' + data.service.name);
            },
            data.noAlerts
        );
    };

    /**
     * Method to fetch reports
     * @param data Contains service data, instance id,
     * @param analytics Reports meta data
     * @param callbacks Contains Success callback method,
     */
    getAnalyticReportsWithNames = (
        data: { instanceId: string; service: AssetService; noAlerts: boolean },
        analytics?,
        callbacks?: { successCallback?: (reports: RecordReports) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getAnalyticReports(
            {
                instanceId: data.instanceId,
                serviceId: data.service.serviceId,
                restApiName: data.service.restApiName,
                noAlerts: data.noAlerts,
            },
            (res) => {
                if (analytics) {
                    let reports: RecordReports;
                    if (res && res.reports) {
                        reports = new RecordReports(analytics, (res && res.reports) || [], data.instanceId);
                    } else {
                        reports = new RecordReports(analytics, (res && res.response) || [], data.instanceId);
                    }
                    callbacks.successCallback(reports);
                } else {
                    callbacks.successCallback(res);
                }
            },
            (res) => {
                if (callbacks && callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                }
                this._utils.alertError((res && res.msg) || 'Failed to get reports for ' + data.service.name);
            }
        );
    };

    /**
     * Method to generate report
     * @param data Contains report, service data, user data, instance id,
     * @param callbacks Contains success callback
     */
    generateReport = (
        data: {
            chainName: string | string[];
            service?: AssetService;
            instanceId: string;
            userName: string;
            repositoryId: string;
            noAlerts?: boolean;
        },
        callbacks: { successCallback: (...args: any[]) => void; failureCallback?: (res) => void }
    ) => {
        const payload = {
            assetDetail: {
                assetId: data.service.assetMetaUId,
                userName: data.userName,
                assetDataId: data.instanceId,
                workflowStageDetail: {
                    status: 'IN_PROGRESS',
                },
            },
            chainNames: typeof data.chainName === 'string' ? [data.chainName] : data.chainName,
            repositoryId: data.repositoryId,
        };
        !data.noAlerts && this._utils.showLoading();
        this._bridge.generateReport(
            {
                payload,
                serviceId: data.service.serviceId,
                restApiName: data.service.restApiName,
                noAlerts: data.noAlerts,
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                callbacks.successCallback(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                callbacks.failureCallback && callbacks.failureCallback(res);
                this._utils.alertError((res && res.msg) || 'Failed to generate report');
            }
        );
    };

    /**
     * Method to process non-workflow instance
     * @param data Contains request ID, instance ID, service data, optional bridge data,
     * @param callbacks Contains Success callback method
     */
    processInstance = (
        data: {
            requestId?: string;
            instanceId: string;
            service?: AssetService;
            bridge?: AssetService;
            assetDataIds: string[];
            assetId?: string;
        },
        callbacks: {
            successCallback: () => void;
            failureCallback?: (res) => void;
        }
    ) => {
        const service = data.service || '';
        const payload = {
            requestId: data.requestId,
            assetId: data.assetId || service['assetMetaUId'],
            assetDataId: data.instanceId,
            assetDataIds: data.assetDataIds,
            serviceSpecId: service['serviceId'],
            locationId: '',
            reprocessing: true,
            hasWorkflow: false,
            taskId: null,
            taskName: null,
        };
        this._bridge.processInstance(
            {
                instanceId: data.instanceId,
                payload,
            },
            () => {
                callbacks.successCallback();
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to process data';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to get instances by sending requestId and status (optional)
     * @param data Contains request ID, status (optional),
     * @param successCallback Contains success callback method,
     */
    getInstanceByRequestId = (
        data: {
            requestId: string;
            status?: string;
            fetchSize?: number;
            previousPagingState?: String;
            nextPagingState?: String;
            noAlerts?: boolean;
            restApiName?: string;
            serviceId?: string;
        },
        callbacks: {
            successCallback: (
                instances: {
                    assetDataId?: string;
                    assetId?: string;
                    createdBy?: string;
                    createdOn?: string;
                    instanceState?: string;
                    instanceStatus?: string;
                    requestId?: string;
                }[]
            ) => void;
            failureCallback?: (res) => void;
        },
        responseToBeSet?
    ) => {
        this._bridge.getInstanceByRequestId(
            {
                requestId: data.requestId,
                status: data.status,
                noAlerts: data.noAlerts,
                restApiName: data.restApiName,
                serviceId: data.serviceId,
            },
            (res) => {
                const instances = (res && res.response && res.response.instances && res.response.instances.assetInstances) || {};
                if (responseToBeSet && responseToBeSet === 'needCompleteResponse') {
                    callbacks.successCallback(res);
                } else {
                    callbacks.successCallback(instances);
                }
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get instance');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    /**
     * Method to create instance from bridge
     * @param data Contains bridge data, service data, check if asset is first item in bridge, payload,
     * @param callbacks Contains success callback method,
     */
    createInstanceFromBridge = (
        data: {
            bridge: AssetService;
            isNotFirstAssetInBridge?: boolean;
            payload: FormData;
            service: AssetService;
            isAssetToAssetTransformation: boolean;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.createInstanceFromBridge(
            {
                bridge: data.bridge,
                isNotFirstAssetInBridge: data.isNotFirstAssetInBridge,
                payload: data.payload,
                service: data.service,
                isAssetToAssetTransformation: data.isAssetToAssetTransformation,
            },
            (res) => {
                callbacks.successCallback(res || {});
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to create instance in ' + data.service.name;
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to save search configuration
     * @param data Contains which includes asset name and primary entity UID, search criteria, filter name, id
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    saveSearchConfiguration = (data: { objectId: string; criteria: string; filterName: string; id?: string }) => {
        return new Observable((observer) => {
            this._bridge.saveSearchConfiguration(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get search configuration
     * @param data Contains which includes primary entity UID
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    getSearchConfiguration = (objectId: string) => {
        return new Observable((observer) => {
            this._bridge.getSearchConfiguration(
                objectId,
                (res) => {
                    observer.next(res?.response || []);
                    observer.complete();
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get saved configurations';
                    this._utils.alertError(msg);
                    observer.error(msg);
                    observer.complete();
                }
            );
        });
    };

    getSearchConfigurationObservable = (objectId: string) => {
        return new Observable((observer) => {
            this._bridge.getSearchConfiguration(
                objectId,
                (res) => {
                    observer.next(res?.response?.searchFilters);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to delete search configuration
     * @param data Contains which includes primary entity UID
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    deleteSearchConfiguration = (data: { id: string }) => {
        return new Observable((observer) => {
            this._bridge.deleteSearchConfiguration(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    const msg = res?.msg || 'Failed to delete saved configurations';
                    this._utils.alertError(msg);
                    observer.error(msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get default search filters
     * @param data Contains which includes asset ID
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    getDefaultSearchFilters = (assetId: string) => {
        return new Observable<{
            'default-filters': string[];
            filterCriterias: {
                [property: string]: FilterCriteria;
            };
        }>((observer) => {
            this._bridge.getDefaultSearchFilters(
                assetId,
                (res) => {
                    observer?.next(res);
                    observer?.complete();
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get default filters';
                    this._utils.alertError(msg);
                    observer.error(msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to search instances
     * @param data Contains which includes  assetName, defaultFilterName, entityId, restApiServiceName, from, size,
     * @param callbacks Contains Success callback method, Failure callback method
     */
    fetchInstancesBasedOnDefaultFilters = (
        data: {
            assetName: string;
            defaultFilterName: string;
            entityId: string;
            restApiServiceName: string;
            from?: number;
            size?: number;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.fetchInstancesBasedOnDefaultFilters(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to search for instances';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to search instances
     * @param data Contains which includes  name and restApiName, search criteria,
     * @param callbacks Contains Success callback method, Failure callback method
     */
    searchInstance = (
        data: {
            name: string;
            restApiName: string;
            criteria: string | { [property: string]: any };
        },
        callbacks: {
            successCallback: (response: {
                assetDataId: string;
                assetId: string;
                createdOn: string;
                identifier: string;
                instanceState: string;
                instanceStatus: string;
                requestId: string;
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.searchInstance(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to search for instances';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to search instances
     * @param data Contains which includes  name and restApiName, search criteria,
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getAnalyticReports = ({
        instanceId,
        serviceId,
        restApiName,
        noAlerts,
    }: {
        instanceId: string;
        serviceId: string;
        restApiName?: string;
        noAlerts: boolean;
    }) => {
        return new Promise<Report[]>((resolve, reject) => {
            this._bridge.getAnalyticReports(
                { instanceId, serviceId, restApiName, noAlerts },
                (res) => {
                    resolve(res?.response || []);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to search for instances' }));
                    reject(res);
                }
            );
        });
    };

    searchInstances = (data: {
        defaultFilterName?: string;
        entityCriterias: any[];
        size: number;
        loadAssetRequests: boolean;
        searchAfter: any;
        noAlerts: boolean;
    }) => {
        return new Promise<{ instances: any[]; searchAfter?: any; pagingState?: any; queryOptions?: any }>((resolve, reject) => {
            this._bridge.searchInstances(
                data,
                (res) => {
                    const response = res && res.response;
                    if (response && response.searchAfter) {
                        if (data.size && response.instances && response.instances.length < data.size) {
                            delete response.searchAfter;
                        }
                    }
                    resolve(response || {});
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to search for instances';
                    this.store$.dispatch(AlertError({ message: msg }));
                    reject();
                }
            );
        });
    };

    /**
     * Method to get generatedReports
     * @param data Contains assetId, assetDataId data,
     * @param callbacks Contains success callback method,
     */
    getGeneratedReports = (
        data: { assetDataId: string; restApiName?: string; assetMetaUId?: string; requestId?: string },
        callbacks: { successCallback?: (response: any) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getGeneratedReports(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to fetch instance reports
     * @param data Contains assetMetaUId and assetDataId
     * @param callbacks Contains Success callback method, Failure callback method
     */
    fetchReports = (data: { assetDataId: string; restApiName?: string; assetMetaUId?: string; requestId?: string; noAlerts?: boolean }) => {
        return new Promise<{ reports: Report[]; orgIdVsName: { [property: string]: string } }>((resolve, reject) => {
            this._bridge.fetchReports(
                data,
                (res) => {
                    if (res?.response?.report_details) {
                        const reportsObject = res.response.report_details || {};
                        const reports = [];
                        Object.keys(reportsObject || {}).forEach((key) => {
                            const orgReports = reportsObject[key];
                            Object.keys(orgReports).forEach((reportListKey) => {
                                const reportsList = orgReports[reportListKey] || [];
                                reportsList.forEach((report) => reports.push(report));
                            });
                        });
                        resolve({
                            orgIdVsName: res.response.orgIdVsName,
                            reports: reports,
                        });
                    } else {
                        resolve({
                            orgIdVsName: res.response?.orgIdVsName,
                            reports: res?.reports,
                        });
                    }
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get reports';
                    this.store$.dispatch(AlertError({ message: msg }));
                    reject();
                }
            );
        });
    };

    /**
     * Method to get instances
     */
    getInstances = (
        data: {
            requestId?: string;
            status?: string;
            fetchSize?: number;
            previousPagingState?: string;
            nextPagingState?: string;
            noAlerts?: boolean;
            restApiName: string;
            loadAssetRequests?: boolean;
            timeRange?: any[];
        },
        callbacks: {
            successCallback: (response, pagingState: any) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getInstances(
            data,
            (res) => {
                const instances = res?.response?.instances?.assetInstances || [];
                const message = res.response.msg;
                if (message?.length > 0 && !data.noAlerts) {
                    this._utils.alertError(message);
                }
                callbacks.successCallback(instances, res?.response?.instances?.nextPagingState);
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to get instances';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to get instances
     */
    getInstance = (data: { serviceId?: string; instanceId: string; noAlerts?: boolean; restApiName?: string }) => {
        return new Promise<Instance>((resolve, reject) => {
            this._bridge.getInstance(
                data,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to get instance' }));
                    reject();
                }
            );
        });
    };

    /**
     * Method to search instances by serviceId
     */
    searchErrorReports = (
        data: {
            defaultFilterName?: string;
            entityCriterias: any[];
            assetName: string;
            restApiServiceName: string;
            size: number;
            from: number;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.searchErrorReports(
            data,
            (res) => {
                callbacks.successCallback((res && res.response && res.response) || []);
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to get instances';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to get Integration Refresh Instance Status
     * @param data Contains requestId, instanceId, serviceId data,
     * @param callbacks Contains success callback method,
     */
    getIntegrationRefreshStatusForInstance = (
        data: { requestId: string; instanceId: string; restApiName: string; orgId: string },
        callbacks: { successCallback?: (response: any) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getIntegrationRefreshStatusForInstance(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get Integration Refresh Request Status
     * @param data Contains requestId, serviceId data,
     * @param callbacks Contains success callback method,
     */
    getIntegrationRefreshStatusForRequest = (
        data: { restApiName: string; requestId: string; orgId: string },
        callbacks: { successCallback?: (response: any) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getIntegrationRefreshStatusForRequest(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get an  integration status for instance
     * @param data Contains requestId, assetId,
     * @param callbacks Contains success callback method,
     */
    getIntegrationStatusForInstance = (
        data: { restApiName: string; instanceId: string; orgId: string },
        callbacks: { successCallback: (statuses: any) => void; failureCallback?: (response) => void }
    ) => {
        this._bridge.getIntegrationStatusForInstance(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration status');
                }
            }
        );
    };

    /**
     * Method to get transformation chain names
     */
    getTransformationChains = (assetMetaUid: string) => {
        return new Observable((observer) => {
            this._bridge.getTransformationChains(
                assetMetaUid,
                (res) => {
                    observer.next(res?.response || []);
                    observer.complete();
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get instances';
                    this._utils.alertError(msg);
                    observer.error(msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to update request
     * @param data Contains assetMetaUId and assetDataId
     * @param callbacks Contains Success callback method, Failure callback method
     */
    updateCurrentRequest = (
        data: {
            instanceId: string;
            requestId?: string;
            bridgeRestApiName?: string;
            assetRestApiname: string;
            chainName?: string;
            autoExecuteRules?: boolean;
            payload?: FormData;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.updateCurrentRequest(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get incomplete instances
     * @param data Contains serviceId, assetDataIds
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getIncompleteInstances = (
        data: {
            restApiName: string;
            assetDataIds: string[];
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getIncompleteInstances(
            data,
            (res) => {
                callbacks.successCallback(res && res.response && res.response.instances);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get pending instances
     * @param data Contains orgId, unitId, bridgeRestApiName, sourceAssetRestApiName, targetAssetRestApiName, filteringAttributes, queryOptions
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getPendingInstances = (
        data: {
            payload: {
                orgId: string;
                unitId: string;
                bridgeRestApiName: string;
                sourceAssetRestApiName: string;
                targetAssetRestApiName?: string;
                targetReportName?: string;
                filteringAttributes: {};
                queryOptions: {};
            };
            noAlerts: boolean;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getPendingInstances(
            data,
            (res) => {
                callbacks.successCallback(res && res.response && res.response.response);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to download audit log
     */
    downloadAuditLogs = (
        data: { restApiName: string; instanceId: string; taskId: string },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.downloadAuditLogs(data, callbacks.successCallback, (res) => {
            this._utils.alertError((res && res.msg) || 'Failed to download logs');
            callbacks && callbacks.failureCallback(res);
        });
    };

    /**
     * Method to cancel instances
     */
    cancelInstances = (
        data: {
            instanceId: string | string[];
            comment?: string;
            restApiName: string;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this.cancelInstancesViaPromise(data)
            .then((res) => callbacks?.successCallback(res))
            .catch(() => callbacks.failureCallback?.());
    };

    /**
     * Method to cancel instances
     */
    cancelInstancesViaPromise = (data: { instanceId: string | string[]; comment?: string; restApiName: string }) => {
        const payload = [];
        if (typeof data.instanceId === 'string') {
            const payloadObject = {
                instanceId: data.instanceId,
                comment: data.comment,
            };
            payload.push(payloadObject);
        } else {
            data.instanceId.forEach((element) => {
                const payloadObject = {
                    instanceId: element,
                    comment: data.comment,
                };
                payload.push(payloadObject);
            });
        }
        return new Promise((resolve, reject) => {
            this._bridge.cancelInstance(
                {
                    restApiName: data.restApiName,
                    payload,
                },
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to Cancel Instance' }));
                    reject();
                }
            );
        });
    };

    /**
     * Method to get generated audit reports
     */
    getGeneratedAuditReports = (
        data: { assetId: string; instanceId: string; noAlerts?: boolean },
        callbacks: {
            successCallback: (
                response: {
                    chainName: string;
                    reportCategory?: string;
                    reportName: string;
                    reportUrl: string;
                    requestId: string;
                    transformationName: string;
                    transformationRepositoryId: string;
                }[],
                orgIdVsNameMap?: { [property: string]: string }
            ) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getGeneratedAuditReports(
            data,
            (res) => {
                callbacks.successCallback(res?.response?.auditReports || []);
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to get audit reports';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to get audit report metadata
     */
    getAuditReportMetadata = (
        data: { assetId: string; noAlerts?: boolean },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getAuditReportMetadata(
            data,
            (res) => {
                callbacks.successCallback(res && res.response && res.response['transformations']);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get audit report metadata');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to generate audit report
     */
    generateAuditReport = (
        data: {
            assetId: string;
            instanceId: string;
            chainName: string;
            noAlerts?: boolean;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.generateAuditReport(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to generate audit report');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to create download request
     */

    createDownloadRequest = (data: {
        restApiName: string;
        payload: {
            transformations: string[];
            instanceIds?: any[];
            searchCriteria?: any;
        };
    }) => {
        return new Observable((observer) => {
            this._bridge.createDownloadRequest(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get widget data
     */
    getWidgetCoordinates = (
        data: {
            unitId: string;
            widgetId: string;
            showLoader: boolean;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getWidgetCoordinates(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get download requests');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to delete widget data
     */
    deleteWidgetCoordinates = (
        data: {
            unitId: string;
            widgetId: string;
            showLoader: boolean;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.deleteWidgetCoordinates(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get download requests');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to update widget data
     */
    updateWidgetCoordinates = (
        data: {
            unitId: string;
            widgetId: string;
            coordinates: any[];
            noAlerts: boolean;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.updateWidgetCoordinates(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get download requests');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get instance errors
     */
    getInstanceState = (data: { instanceId: string; restApiName: string; noAlerts?: boolean }) => {
        return new Promise<{
            instanceInfo: {
                errors: string[];
                warnings: string[];
            };
        }>((resolve, reject) => {
            this._bridge.getInstanceStatus(
                data,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to get process errors/warnings.' }));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get reconciliation processes
     */
    getReconciliationProcesses = (data: {
        requestId?: string;
        pageSize?: number;
        pagingState?: string;
        noAlerts?: boolean;
        restApiName: string;
        groupId?: string;
        sourceId?: string;
    }) => {
        return new Promise((resolve, reject) => {
            this._bridge.getReconciliationProcesses(
                data,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get instances';
                    this._utils.alertError(msg);
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get reconciliation processes
     */
    getReconRequestGroupCount = (restApiName: string, requestId: string, group: string, sourceId?: string) => {
        const promise = this._bridge.getReconRequestGroupCount(restApiName, requestId, group, sourceId);
        promise.catch((e) => {
            const msg = e?.msg || 'Failed to get count';
            this._utils.alertError(msg);
        });
        return promise;
    };

    /**
     * Method to get Report Processes
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    getReportProcesses = (
        data: {
            serviceId: string;
            size?: number;
            previousPagingState?: string;
            noAlerts?: boolean;
            timeRange?: any[];
        },
        successCallback: (response) => void,
        failureCallback?: (response) => void
    ) => {
        this._bridge.getReportProcesses(
            data,
            (res) => {
                successCallback({
                    reports: res?.response?.requests?.assetRequests || [],
                    pagingState: res?.response?.requests?.nextPagingState,
                });
            },
            (response) => {
                failureCallback(response);
            }
        );
    };

    generateSearchReport = (data, successCallback: (response) => void, failureCallback: (response) => void) => {
        this._bridge.generateErrorReport(
            data,
            (res) => {
                successCallback(res?.response || []);
            },
            (res) => {
                failureCallback(res);
            }
        );
    };
}
