<div class="mat-elevation-z2 matTableContainer" *transloco="let translate;">
    <div class="tableWrapper" *ngIf="show && columnKeyPair">
        <table aria-describedby="library table" mat-table [dataSource]="dataSource" multiTemplateDataRows class="pAdjust">
            <ng-container *ngFor="let columnId of selectedColumns; let i = index; trackBy: trackBySelectedColumnsMethod">
                <ng-container matColumnDef="{{columnId}}" [sticky]="!noSticky && i < 1" [stickyEnd]="!noSticky && (i > selectedColumns.length -3)">
                    <ng-container [ngSwitch]="columnTypePair[columnId]">
                        <ng-container *ngSwitchCase="'text'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader" title="{{columnKeyPair[columnId]?.name}}">
                                <span class="columnName" [class.adjustWidth]="!(!enableColumnEdit || fieldsDisabledForEdit.includes(columnId))">
                                    <mat-icon class="title_icon {{columnKeyPair[columnId]?.icon}}" *ngIf="columnKeyPair[columnId]?.icon">{{columnKeyPair[columnId]?.icon}}</mat-icon>
                                    {{columnKeyPair[columnId]?.name | pascalToCamel}}
                                </span>
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell" [title]="(element.isDeleted ? 'This record was deleted' : (!element.inAudit && columnKeyPair[columnId]?.uiTypeMetadata !== 'TEXTAREA' && element?.uiTypeMetadata !== 'TEXTAREA'  ? (element[columnId] || '--') : '')) | TruncateHTMLTags" (click)="copyToInboundRequestId(element)">
                                <ng-template *ngTemplateOutlet="textTemplate; context: { element: element, columnId: columnId }"></ng-template>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'GRID'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <ng-template *ngTemplateOutlet="gridTemplate; context: { element: element, columnId: columnId, column: columnKeyPair[columnId], showGridIcon: element.showGridIcon }"></ng-template>
                            </td>
                            <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'input'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <ng-container [ngSwitch]="(cellDataTypes[element.rowIndex][columnId].readOnly)">
                                    <ng-container *ngSwitchCase="true">
                                        {{element[columnId]}}
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <table-input [column]="columnKeyPair[columnId]" [element]="element" [columnId]="columnId" [cellDataTypes]="cellDataTypes"></table-input>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'SELECTRECORD'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                <ng-container *ngIf="columnKeyPair[columnId].showCheckboxInHeader">
                                    <mat-checkbox matBadge="{{selectedRecordsCount > 0 ? selectedRecordsCount : null}}" matBadgeOverlap="false" class="selectAllBtn" [(ngModel)]="selectAllRecords" (ngModelChange)='toggleRecordsSelection(selectAllRecords)' id="columnId" [disabled]="!dataSource || (dataSource && !dataSource.data) || (dataSource.data && dataSource.data.length === 0)"></mat-checkbox>
                                </ng-container>
                                <ng-container *ngIf="!columnKeyPair[columnId].showCheckboxInHeader">
                                    {{columnKeyPair[columnId]?.name}}
                                </ng-container>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <mat-checkbox class="recordSelectCheckboxContainer" [(ngModel)]="element.selected" (ngModelChange)="onRecordSelectionChange(element)"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select'">

                        </ng-container>
                        <ng-container *ngSwitchCase="'group-select'">

                        </ng-container>
                        <ng-container *ngSwitchCase="'ACCORDIAN'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <div class="d-flex" *ngIf="element['accordian'] === false && element['fileName']">
                                    <span class="accordian_body_text" (click)="downloadUplodedFile(element, element[columnId] )" [title]="element[columnId] || '--'">{{element[columnId]}}</span>
                                </div>
                                <div class="d-flex" *ngIf="element['downloadable'] === true">
                                    <span class="accordian_body_text" (click)="downloadUplodedFile(element, element[columnId] )" [title]="element[columnId] || '--'">{{element[columnId]}}</span>
                                </div>
                                <div class="d-flex" *ngIf="element['downloadable'] === false">
                                    <span [title]="element[columnId] || '--'">{{element[columnId]}}</span>
                                </div>
                                <div class="d-flex" *ngIf="element['accordian'] === 'noData'">
                                    <span [title]="element[columnId] || '--'">{{element[columnId]}}</span>
                                </div>
                                <mat-accordion class="tableColumnAccordian" *ngIf="element['accordian'] === true">
                                    <mat-expansion-panel hideToggle #source>
                                        <mat-expansion-panel-header>
                                            <div class="col-9 p-0">
                                                <mat-panel-title> <span class="accordian_body_text" stop-propagation (click)="downloadUplodedFile(element, element[columnId][0])">{{element[columnId][0]}}</span>
                                                </mat-panel-title>
                                            </div>
                                            <mat-icon class="title_icon" *ngIf="!source.expanded">add_circle_outline</mat-icon>
                                            <mat-icon class="title_icon" *ngIf="source.expanded">remove_circle_outline</mat-icon>
                                        </mat-expansion-panel-header>
                                        <div class="d-flex" *ngFor="let accordian of element[columnId] | slice : 1" class="accordian_body">
                                            <span class="accordian_body_text" (click)="downloadUplodedFile(element, accordian )">{{accordian}}</span>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'file'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell file-browse" stop-propagation>
                                <span class="tableFilesUploadContainer">
                                    <fileUpload [data]="attachmentData" [clearFiles]="clearFiles" (newValue)="fileChanged($event, element)" [appendedFileName]="element.fileName" (fileName)="changedFileName($event, element)"></fileUpload>
                                </span>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'link'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell" stop-propagation>
                                <ng-container [ngSwitch]="element[columnId] && (element[columnId].indexOf('http://') > (-1) || element[columnId].indexOf('https://') > (-1))">
                                    <ng-container *ngSwitchCase="true">
                                        <span class="downloadLink">
                                            <a [href]="element[columnId]" target="_blank" rel="noopener">{{translate('Download')}}</a>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <span class="downloadLink" (click)="downloadSourceTemplateUrl(element)">{{translate('Download')}}</span>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'PASSWORD'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                <mat-icon class="title_icon {{columnKeyPair[columnId].icon}}" *ngIf="columnKeyPair[columnId].icon">{{columnKeyPair[columnId].icon}}</mat-icon>
                                {{columnKeyPair[columnId]?.name}}
                                <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <span *ngIf="element[columnId]">*****</span>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container [ngSwitch]="columnTypePair[columnId]">
                                <ng-container *ngSwitchCase="'ALL_COLUMNS_LIST'">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader logMenuIcon">
                                        <span class="material-icons logIcon" [matMenuTriggerFor]="logMenu">menu</span>
                                        <mat-menu #logMenu="matMenu" [class]="'tableLogMenu'" xPosition="after" yPosition="below">
                                            <div class="logMenu" stop-propagation>
                                                <div class="logFilter" stop-propagation>
                                                    <mat-form-field>
                                                        <input matInput class="filterInput" autocomplete="off" placeholder="{{translate('Filter Table Columns')}}" [(ngModel)]="searchColumns" />
                                                        <button mat-button *ngIf="searchColumns" matSuffix mat-icon-button aria-label="Clear" (click)="searchColumns = ''">
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                    </mat-form-field>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex justify-content-around align-items-center checkStyle" stop-propagation>
                                                        <mat-checkbox class="selectAllBtn" [(ngModel)]="selectAll" (ngModelChange)='selectAllFilters(selectAll)' id="selectAllLabel">{{translate('Select All')}}
                                                        </mat-checkbox>
                                                    </div>
                                                    <material-button [disabled]="clonedColumns?.length === 0" [label]="translate('Apply')" (onTrigger)="cloneSubmit()">
                                                    </material-button>
                                                </div>
                                                <div class="logListItems" *ngFor="let column of allColumns | FilterPipe: 'name' : searchColumns; let i = index; trackBy: trackByAllColumnsMethod" stop-propagation>
                                                    <div class="d-flex align-items-center tempList" [hidden]="!((!searchColumns || searchColumns.length === 0 || (searchColumns.length > 0 && column.name.toLowerCase().indexOf(searchColumns.toLowerCase()) > (-1))) && actionColumns.indexOf(column.id) === (-1) && !(hideTableColumns && hideTableColumns.indexOf(column.id) > (-1)) && !column.hideInAllColumnsList)">
                                                        <div class="actionCheckBox mCheckbox">
                                                            <mat-checkbox [hidden]="column.type === 'SELECTRECORD' || column.name === 'dynamicActions'" [checked]="clonedColumns?.indexOf(column.id) > (-1)" (change)="checkboxSelect($event.checked, column)">
                                                                {{column.name}}
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-menu>
                                        <ng-container *ngIf='columnSearchRequired'>
                                            <div class="searchHiddenDiv">

                                            </div>
                                        </ng-container>
                                        <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="matTableCell"> </td>
                                </ng-container>
                                <ng-container *ngSwitchCase="'ACTIONS'">
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader alignCenter">
                                        <div>
                                            {{translate('Actions')}}
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="matTableCell alignCenter">
                                        <div class="actionsMenu tableActionsMenu">
                                            <ng-container *ngFor="let action of recordActions; trackBy: trackByActionsMethod">
                                                <span [hidden]='!(actionVisibility[element.recordId][action.displayName])'>
                                                    <ng-container *ngIf="(!action.conditionalDisplay || (action.byFieldValue && ((action.conditionType === 'NOT_EQUALS' && element[action.conditionalFieldId] !== action.conditionalFieldValue) || (action.conditionType === 'EQUALS' && element[action.conditionalFieldId] === action.conditionalFieldValue) || (action.conditionType === 'EXISTS' && element[action.conditionalFieldId] !== undefined))))">
                                                        <button mat-icon-button class="actionButtons" title="{{action.displayName}}" (click)="action.onActionTrigger && action.onActionTrigger(element, action)">
                                                            <span class="material-symbols-outlined matTableActionIcon material-icons-filled tableMatActionIcon" [ngClass]="{'displayActionColor': action.iconName === 'warning'}" [hidden]="action.iconName === undefined">
                                                                {{action.iconName}}
                                                            </span>
                                                        </button>
                                                    </ng-container>
                                                </span>
                                            </ng-container>
                                        </div>
                                        <ng-container *ngIf="!recordActions || recordActions.length === 0">
                                            <button mat-icon-button>{{translate('No Actions Found')}}</button>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                        {{columnKeyPair[columnId]?.name}}
                                        <mat-checkbox class="columnToUpdate" [(ngModel)]="columnsToUpdate[columnId]" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)"></mat-checkbox>
                                        <span class="material-icons fontSizeCls" [class.displayNone]="!enableColumnEdit || fieldsDisabledForEdit.includes(columnId)" (click)="editColumnEvent.emit(columnId)">edit</span>
                                        <ng-container *ngTemplateOutlet="searchInputTemplate; context: { column: columnId }"></ng-container>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="matTableCell" [title]="element.isDeleted ? 'This record was deleted' : (element.inAudit && columnKeyPair[columnId]?.uiTypeMetadata == 'TEXTAREA' ? element[columnId] || '--' : '')">
                                        <ng-container [ngSwitch]="element.inAudit">
                                            <ng-container *ngSwitchCase="true">
                                                <ng-container *ngIf="(columnKeyPair[columnId]?.uiTypeMetadata === 'TEXTAREA' || element?.uiTypeMetadata === 'TEXTAREA');else normalText;">
                                                    <span class="angular-editor-textarea plain-text oldAuditValue" [innerHTML]="decodeTextFieldValue(element?.[columnId + '_oldValue'])"> </span>
                                                    <span class="angular-editor-textarea plain-text newAuditValue" [innerHTML]="decodeTextFieldValue(element?.[columnId + '_oldValue'])"> </span>
                                                </ng-container>
                                                <ng-template #normalText>
                                                    <span class="oldAuditValue"> old: {{element?.[columnId + '_oldValue']}} </span>
                                                    <span class="newAuditValue"> new: {{element?.[columnId + '_newValue']}} </span>
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <ng-container *ngIf="(columnKeyPair[columnId]?.uiTypeMetadata === 'TEXTAREA' || element?.uiTypeMetadata !== 'TEXTAREA');else normalText;">
                                                    <span class="angular-editor-textarea plain-text" [innerHTML]="decodeTextFieldValue(element[columnId])"> </span>
                                                </ng-container>
                                                <ng-template #normalText>
                                                    <span [class.dataTypeTextAlign]="['INT','LONG','FLOAT','DOUBLE','DECIMAL'].includes(columnKeyPair[columnId]?.inputType)"> {{element[columnId]}} </span>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td class="expandedTableRow" mat-cell *matCellDef="let element" [attr.colspan]="selectedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="tableLoading">
                <td mat-footer-cell class="matTableMessages" *matFooterCellDef colspan="100%">
                    <div class="loaderBlock isLoading"> </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="noData">
                <td mat-footer-cell class="matTableMessages" *matFooterCellDef colspan="100%">
                    {{noRecordMsg && (pagination?.value?.count > 0) ? translate('No records') : noRecordMsg && !(pagination?.value?.count > 0)? noRecordMsg : translate('No records found')}}
                </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
                <td mat-footer-cell class="matTableMessages noRecordMessage" *matFooterCellDef colspan="100%">{{translate('No records found')}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="selectedColumns; sticky: !noSticky" [ngClass]="{'tableHeaderPadding': columnSearchRequired}"></tr>
            <tr mat-row *matRowDef="let element; columns: selectedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" [class.isDeleted]="element.isDeleted" [class.disabled]="element.disabled" [class.matTableError]="element.errorsCount && element.errorsCount > 0" [class.matTableWarning]="element.warningsCount && element.warningsCount > 0" (click)="onSingleClick(element)" (dblclick)="onDoubleClick(element)" [title]="element.isDeleted ? 'This record was deleted' : ((element.tooltip && element.tooltip.length > 0) ? element.tooltip : '')">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            <tr mat-footer-row *matFooterRowDef='["tableLoading"]' [ngClass]="{'hide': (dataSource !== null && !loading)}" [class.loadingTable]="loading"></tr>
            <tr mat-footer-row *matFooterRowDef='["noData"]' [ngClass]="{'hide': !(dataSource !== null && dataSource.data.length === 0 && !loading)}"></tr>
            <tr mat-footer-row *matFooterRowDef='["noRecords"]' [ngClass]="{'hide': !noRecordsFound}"></tr>
        </table>
    </div>
    <ng-container *ngIf="(dataSource?.data?.length > 0 || pageIndexSelected > 0 || pagination?.value?.pageIndex > 0) && defaultPaginator">
        <mat-paginator [hidden]="dataSource?.data?.length === 0" #defaultMatPaginator [pageSizeOptions]="[5, 10, 20]" [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]='10' showFirstLastButtons="true"></mat-paginator>
    </ng-container>
    <ng-container *ngIf="(dataSource?.data?.length > 0 || pageIndexSelected > 0 || pagination?.value?.pageIndex > 0) && newCustomPaginator">
        <div class="newCustomPagination">
            <ul class="newCustomPaginationUl">
                <li class="newCustomPaginationLi newCustomPaginationPageIndex">
                    <mat-form-field appRemoveAriaOwns>
                        <mat-label>Page Index</mat-label>
                        <mat-select [(value)]="pageIndexSelected" [disabled]='customPageIndex.length < 2'>
                            <mat-option *ngFor="let index of customPageIndex" [value]='index' (click)="fetchItemsInPageWithPageIndex.emit(index)">{{index}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
                <li class="newCustomPaginationLi newCustomPaginationPageIndex">
                    <mat-form-field appRemoveAriaOwns>
                        <mat-label>Page Size</mat-label>
                        <mat-select [(value)]="sizeSelected">
                            <mat-option *ngFor="let size of customSize" [value]='size' (click)="refreshItemsWithNewPageSize.emit(size)">{{size}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
                <li class="newCustomPaginationLi newCustomPaginationArrows">
                    <mat-icon class="leftArrow" [ngClass]="{'disable': disablePrevious}" title="previous" (click)="fetchItemsInPage.emit('previous')">chevron_left</mat-icon>
                    <mat-icon class="rightArrow" title="next" [ngClass]="{'disable': disableNext}" (click)="fetchItemsInPage.emit('next')">chevron_right</mat-icon>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="(dataSource?.data?.length > 0 || pageIndexSelected > 0 || pagination?.value?.pageIndex > 0) && pagination && pagination.value && pagination.value.count > 0">
        <ng-container *ngIf="hidePaginator !== true && pagination">
            <material-paginator [paginationSubject]="pagination" [showCount]="showCount" (fetchItemsInPage)="fetchItemsInPage.emit($event)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize.emit($event)">
            </material-paginator>
        </ng-container>
    </ng-container>
</div>

<ng-template #textTemplate let-element="element" let-columnId="columnId">
    <ng-container [ngSwitch]="element.inAudit">
        <ng-container *ngSwitchCase="true">
            <ng-container *ngIf="(columnKeyPair[columnId]?.uiTypeMetadata === 'TEXTAREA' || element?.uiTypeMetadata === 'TEXTAREA');else normalText;">
                <span class="angular-editor-textarea plain-text oldAuditValue" [innerHTML]="decodeTextFieldValue(element?.[columnId + '_oldValue'])"> </span>
                <span class="angular-editor-textarea plain-text newAuditValue" [innerHTML]="decodeTextFieldValue(element?.[columnId + '_oldValue'])"> </span>
            </ng-container>
            <ng-template #normalText>
                <span class="oldAuditValue"> {{element?.[columnId + '_oldValue']}} </span>
                <span class="newAuditValue"> {{element?.[columnId + '_newValue']}} </span>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container *ngIf="(columnKeyPair[columnId]?.uiTypeMetadata === 'TEXTAREA' || element?.uiTypeMetadata === 'TEXTAREA');else normalText;">
                <span class="angular-editor-textarea plain-text" [innerHTML]="decodeTextFieldValue(element[columnId])"> </span>
            </ng-container>
            <ng-template #normalText>
                <span [class.dataTypeTextAlign]="['INT','LONG','FLOAT','DOUBLE','DECIMAL'].includes(columnKeyPair[columnId]?.inputType)" [innerHTML]="decodeTextFieldValue(element[columnId])"> {{element[columnId]}} </span>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #gridTemplate let-element="element" let-columnId="columnId" let-column="column" let-showGridIcon="showGridIcon">
    <button mat-icon-button class="actionButtons" (click)="clickedCell(column, $event, element)" [hidden]="showGridIcon !== undefined ? (!showGridIcon) : false">
        <span class="material-icons matTableActionIcon material-icons-filled"> grid_on </span>
    </button>
</ng-template>
