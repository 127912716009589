import { createAction, props } from '@ngrx/store';

import { NotificationEvent } from '../../interface/notifications-event.interface';
import { ReconEvent } from '../../interface/recon-event.interface';

export enum types {
    GET_NOTIFICATIONS = '[NOTIFICATIONS] Get Notifications',
    SET_NOTIFICATIONS = '[NOTIFICATIONS] Set Notifications',
    CLEAR_NOTIFICATIONS = '[NOTIFICATIONS] Clear Notifications',
    GET_RECON_EVENTS = '[NOTIFICATIONS] Get Recon Events',
    SET_RECON_EVENTS = '[NOTIFICATIONS] Set Recon Events',
    CLEAR_RECON_EVENTS = '[NOTIFICATIONS] Clear Recon Events',
    POLL_EVENTS = '[NOTIFICATIONS] Poll for Events',
    POLL_CHECK_APP_EVENTS = '[NOTIFICATIONS] Check App Poll Events',
    POLL_CHECK_RECON_EVENTS = '[NOTIFICATIONS] Check Recon Poll Events',
    CLEAR_EVENT_POLLING = '[NOTIFICATIONS] Clear Polling',
    POLLING_TERMINATED = '[NOTIFICATIONS] Polling Terminated',
    GET_PROCESS_LOGS = '[PROCESS_LOGS] Get Process Logs',
    SET_PROCESS_LOGS = '[PROCESS_LOGS] Set Process Logs',
    CLEAR_PROCESS_LOGS = '[PROCESS_LOGS] Clear Process Logs',
    SET_PAGINATION = '[PAGINATION] Set Pagination',
    GET_NOTIFICATIONS_VIA_PAGINATION = '[NOTIFICATIONS] Get Notification Via Pagination',
    SET_NOTIFICATIONS_VIA_PAGINATION = '[NOTIFICATIONS] Set Notification Via pagination',
    GET_EMAIL_NOTIFICATIONS = '[NOTIFICATIONS] Get Email Notifications',
    SET_EMAIL_NOTIFICATIONS = '[NOTIFICATIONS] Set Email Notifications',
    SET_EMAIL_PAGINATION = '[NOTIFICATIONS] Set Email Pagination',
    CLEAR_EMAIL_NOTIFICATIONS = '[NOTIFICATIONS] Clear Email Notifications',
    SET_EVENT_TYPES = '[NOTIFICATIONS] Set Event Types',
    GET_EVENT_TYPES = '[NOTIFICATIONS] Get Event Types',
    CLEAR_EVENT_TYPES = '[NOTIFICATIONS] Clear Event Types',
    RESEND_EMAIL = '[NOTIFICATIONS] Resend Email',
    SET_EVENT_SUBSCRIPTIONS = '[NOTIFICATIONS] Set Event Subscriptions',
    CLEAR_EVENT_SUBSCRIPTIONS = '[NOTIFICATIONS] Clear Event Subscriptions',
    GET_EVENT_SUBSCRIPTIONS = '[NOTIFICATIONS] Get Event Subscriptions',
    GET_SUBSCRIPTIONS_VIA_PAGINATION = '[NOTIFICATIONS] Get Subscriptions Via Pagination',
    DELETE_EVENT_SUBSCRIPTION = '[NOTIFICATIONS] Delete Event Subscriptions',
    UPDATE_EVENT_SUBSCRIPTION = '[NOTIFICATIONS] Update Event Subscriptions',
    CREATE_EVENT_SUBSCRIPTION = '[NOTIFICATIONS] Create Event Subscriptions',
    DELETE_EVENT_SUBSCRIPTION_SUCCESS = '[NOTIFICATIONS] Delete Event Subscription Success',
}

export const GetNotifications = createAction(
    types.GET_NOTIFICATIONS,
    props<{
        eventType: string;
        resourceId: string;
        pageIndex?: number;
        fetchSize: number;
        noPagingState?: boolean;
        noAlerts?: boolean;
        pollingResourceType?: 'REQUEST' | 'INSTANCE' | 'REPORTS' | 'BULK_REPORTS' | 'AUDIT_REPORTS';
        timeStamp?: number;
        noAutoClear?: boolean;
    }>()
);

export const GetSubscriptionsViaPagination = createAction(
    types.GET_SUBSCRIPTIONS_VIA_PAGINATION,
    props<{
        orgId: string;
        fetchSize: number;
        pagingState: string;
        eventType: string[];
        index: number;
        groupId?: string;
    }>()
);

export const SetEventSubscriptions = createAction(
    types.SET_EVENT_SUBSCRIPTIONS,
    props<{
        index: number;
        fetchSize: number;
        pagingState: string;
        eventSubscriptions: any[];
    }>()
);

export const UpdateEventSubscription = createAction(
    types.UPDATE_EVENT_SUBSCRIPTION,
    props<{
        data: {
            orgId: string;
            eventType: string;
            subscriptionId: string;
            criterion: string;
            groupId: string;
            toEmails: string[];
            ccEmails: string[];
            bccEmails: string[];
            subjectTemplate: string;
            contentTemplate: string;
            attachment: string;
            includeHierarchy: boolean;
        };
    }>()
);

export const CreateEventSubscription = createAction(
    types.CREATE_EVENT_SUBSCRIPTION,
    props<{
        data: {
            orgId: string;
            eventType: string;
            subscriptionId: string;
            criterion: string;
            groupId: string;
            toEmails: string[];
            ccEmails: string[];
            bccEmails: string[];
            subjectTemplate: string;
            contentTemplate: string;
            attachment: string;
            includeHierarchy: boolean;
        };
    }>()
);

export const ClearEventSubscriptions = createAction(types.CLEAR_EVENT_SUBSCRIPTIONS);

export const DeleteEventSubscription = createAction(
    types.DELETE_EVENT_SUBSCRIPTION,
    props<{
        data: {
            orgId: string;
            eventType: string;
            subscriptionId: string;
            groupId: string;
        };
    }>()
);

export const DeleteEventSubscriptionSuccess = createAction(
    types.DELETE_EVENT_SUBSCRIPTION_SUCCESS,
    props<{
        subscriptionId: string;
    }>()
);

export const GetEventTypes = createAction(types.GET_EVENT_TYPES);

export const SetEventTypes = createAction(
    types.SET_EVENT_TYPES,
    props<{
        eventTypes: any[];
    }>()
);

export const ClearEventTypes = createAction(types.CLEAR_EVENT_TYPES);

export const SetEmailPagination = createAction(
    types.SET_EMAIL_PAGINATION,
    props<{
        groupId?: string;
        eventType?: string;
        subscriptionId?: string;
        size?: number;
        pageState?: any;
        fromDate?: string;
        status?: string;
        mailId?: string;
        noAlerts?: boolean;
        index?: number;
    }>()
);

export const SetEmailNotifications = createAction(
    types.SET_EMAIL_NOTIFICATIONS,
    props<{
        fetchSize?: number;
        pagingState: string;
        emailNotifications: any[];
        index?: number;
    }>()
);

export const ResendEmail = createAction(
    types.RESEND_EMAIL,
    props<{
        messageId: string;
        emailId?: string;
    }>()
);

export const ClearEmailNotifications = createAction(types.CLEAR_NOTIFICATIONS);

export const SetPagination = createAction(
    types.SET_PAGINATION,
    props<{
        resourceId: string;
        size: number;
        index: number;
        eventType: string;
        pagingState?: string;
    }>()
);

export const GetNotificationsViaPagination = createAction(
    types.GET_NOTIFICATIONS_VIA_PAGINATION,
    props<{
        resourceId: string;
        eventType: string;
        noAlerts: boolean;
    }>()
);

export const SetNotifications = createAction(
    types.SET_NOTIFICATIONS,
    props<{
        eventType?: string;
        resourceId: string;
        pagingState: string;
        notifications: NotificationEvent[];
    }>()
);

export const ClearNotifications = createAction(
    types.CLEAR_NOTIFICATIONS,
    props<{
        resourceId: string;
    }>()
);

export const GetReconEvents = createAction(
    types.GET_RECON_EVENTS,
    props<{
        serviceId: string;
        resourceId: string;
        noAlerts?: boolean;
        pollingResourceType?: 'REQUEST';
    }>()
);

export const SetReconEvents = createAction(
    types.SET_RECON_EVENTS,
    props<{
        resourceId: string;
        events: ReconEvent[];
        pagingState: string;
    }>()
);

export const ClearReconEvents = createAction(
    types.CLEAR_RECON_EVENTS,
    props<{
        resourceId: string;
    }>()
);

export const PollForEvents = createAction(
    types.POLL_EVENTS,
    props<{
        appType: 'RECON' | 'APP';
        resourceType: 'REQUEST' | 'INSTANCE' | 'REPORTS' | 'BULK_REPORTS' | 'AUDIT_REPORTS';
        resourceId: string;
        serviceId: string;
        delay?: boolean;
        timeStamp: number;
        noAutoClear?: boolean;
        noAlerts?: boolean;
    }>()
);

export const CheckAppPollEvents = createAction(
    types.POLL_CHECK_APP_EVENTS,
    props<{
        resourceType: 'REQUEST' | 'INSTANCE' | 'REPORTS' | 'BULK_REPORTS' | 'AUDIT_REPORTS';
        resourceId: string;
        timeStamp: number;
        noAutoClear?: boolean;
    }>()
);

export const CheckReconPollEvents = createAction(
    types.POLL_CHECK_RECON_EVENTS,
    props<{
        resourceType: 'REQUEST';
        resourceId: string;
    }>()
);

export const ClearEventPolling = createAction(
    types.CLEAR_EVENT_POLLING,
    props<{
        resourceId: string;
    }>()
);

export const PollingTerminated = createAction(
    types.POLLING_TERMINATED,
    props<{
        resourceId: string;
        resourceType: 'INSTANCE' | 'REPORTS';
        timeStamp?: number;
    }>()
);

export const GetProcessLogs = createAction(
    types.GET_PROCESS_LOGS,
    props<{
        resourceId: string;
        fetchSize: number;
        pagingState?: string;
    }>()
);

export const SetProcessLogs = createAction(
    types.SET_PROCESS_LOGS,
    props<{
        resourceId: string;
        pagingState: string;
        processLogs: NotificationEvent[];
    }>()
);

export const ClearProcessLogs = createAction(
    types.CLEAR_PROCESS_LOGS,
    props<{
        resourceId: string;
        fetchSize: number;
        pagingState: string;
    }>()
);
