import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { BroadcasterService } from 'ng-broadcaster';
import { EntityFilterCriterias, SearchRule, SessionLogoutSuccessfull } from 'taxilla-library';

import { SessionExpiredModalComponent } from '../../components/session-expired-modal/session-expired-modal.component';
import * as FileTypes from '../../constants/filetype.json';
import { ViewRecordType } from '../../interface/viewrecordtype';
import { AesUtil } from '../../models/aesutil.class';
import { AppConfigurator, TimelineFilter } from '../../models/app-configurator.class';
import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { Entity } from '../../models/entity.class';
import { Field, TargetField } from '../../models/field.class';
import { Organization } from '../../models/organization.class';
import { Message } from '../../models/record/message.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { SearchFilter } from '../../models/searchfilter.class';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private idsList: { [property: string]: string[] } = {};

    constructor(
        private _broadcaster: BroadcasterService,
        private _router: Router,
        private dialog: MatDialog,
        private R: RootScopeService,
        public _commonutils: CommonUtilsService,
        private title: Title,
        private store$: Store
    ) {
        this.setOffsetTime();
        /**
         * prototype methods
         */
        Array.prototype['clone'] = function () {
            return this.slice(0);
        };
        window['encrypter'] = this.encryptString;
        // combineLatest([this.store$.select(getCurrentOrganizationId$), this.store$.select(getCurrentLocationIdsHierarchy$)]).subscribe(
        //     ([id, ids]) => {
        //         this.idsList[id] = ids;
        //     }
        // );
    }
    static offsetTime: number;

    isInIFrame = window.location !== window.parent.location;

    timeoutDuration: number;
    sessionTimer;
    guid = UtilsService.guid;
    checkInteger = UtilsService.checkInteger;
    checkFloat = UtilsService.checkFloat;
    checkMaxLength = UtilsService.checkMaxLength;
    checkMinLength = UtilsService.checkMinLength;
    checkMinValue = UtilsService.checkMinValue;
    transformDate = CommonUtilsService.transformDate;
    transformDateToLocale = CommonUtilsService.transformDateToLocale;
    setOffsetTimeout: any;
    targetFields: TargetField[];
    transformDateToDefaultFormat = CommonUtilsService.transformDateToDefaultFormat;

    /**
     * Method to generate random string
     */
    static s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(10)
            .substring(0, 1);
    };

    /**
     * Method to generate random guid
     */
    static guid = (length = 16, withoutTimestamp?: boolean) => {
        let str = '';
        for (let i = 0; i < length; i++) {
            const rn = UtilsService.s4();
            str += rn + '';
        }
        return (!withoutTimestamp ? new Date().getTime() : '') + str;
    };

    /**
     * Regex method to check the if the input is integer or not.
     */
    static checkInteger = (number) => {
        const pattern = /^\d+$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input have spaces.
     */
    hasBlankSpaces = (value) => {
        const pattern = /\s/;
        return pattern.test(value);
    };

    /**
     * Method to check if the given number is Double only
     */
    checkDouble = (number) => {
        const pattern = /^-?[0-9]\d*(\.\d+)?$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input is float.
     */
    static checkFloat = (number) => {
        const pattern = /^[+-]?([0-9]*[.])?[0-9]+/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input string contains any invalid characters like ( > < . : ) characters.
     */
    invalidChars = (str) => {
        const pattern2 = /^(.)*<(.)*>(.)*$/;
        const pattern3 = /^(.)*:(.)*$/;
        return pattern2.test(str) || pattern3.test(str);
    };

    /**
     * Regex method to check the if the input string contains alphabets.
     */
    acceptAlphabets = (str) => {
        const regex = /^[A-Za-z+ +]*$/;

        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Must contain alphabets
     * @param str
     * @returns boolean
     */
    mustContainAlphabets = (str: string) => {
        return str?.match(/[A-Za-z]/g);
    };

    /**
     * Regex method to check the if the input string is a valid email.
     */
    acceptEmail = (email, isArray?: boolean) => {
        const regex = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
        let result = true;
        if (isArray) {
            email.forEach((mail) => {
                result = result && mail.match(regex);
            });
        } else {
            result = email.match(regex);
        }
        return result;
    };

    /**
     * Regex method to check the if the input string  is a valid phone number.
     */
    checkPhoneNumber = (str) => {
        const phoneno = /^(?!0{10})([0-9]{10})$/;
        return str && str.match(phoneno) && str.trim().length >= 5 && str.trim().length <= 10;
    };

    acceptUserId = (userId) => {
        // var regex = /^[[A-Z]{1}[A-Za-z0-9+ +.+'+\-+\x26]{0,49}]*$/;
        const regex = /^[A-Za-z0-9_.@\s-]*$/;
        if (userId) {
            if (userId.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid password.
     */
    checkPassword = (password, passwordRegex?, passwordMsg?) => {
        const pattern = passwordRegex ? new RegExp(passwordRegex) : /^(?=.*[A-Z])(?=.*[%@?!$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
        if (
            !pattern.test(password) ||
            /\s/.test(password) ||
            password.indexOf('<') !== -1 ||
            password.indexOf('>') !== -1 ||
            password.indexOf(':') !== -1 ||
            password.indexOf('.') !== -1
        ) {
            return (
                passwordMsg ||
                'Enter a combination of minimum 8 alpha numerics with atleast 1 upper case,1 lower case letter and a special symbol (like ! @ $ % * & ?) without spaces.'
            );
        }
        return true;
    };

    setOffsetTime = () => {
        this._commonutils.setOffsetTime();
    };

    setTimeZoneOffset = (
        data: {
            tz: any;
            serverTimeZoneOffset: any;
        },
        callback
    ) => {
        const tz = data.tz;
        const tzOffset: any = this._commonutils.getTimeZoneOffset(tz);
        const userTimeZoneOffset = !isNaN(parseInt(tzOffset, undefined)) ? parseInt(tzOffset) : -330; // Hardcoding Indian timezoneoffset when invalid value comes up
        const serverTimeZoneOffset = !isNaN(parseInt(data.serverTimeZoneOffset)) ? parseInt(data.serverTimeZoneOffset) : 0;
        const totalOffset = userTimeZoneOffset - serverTimeZoneOffset;
        this._commonutils.setInStorage('timeZoneOffset', totalOffset);
        this._commonutils.setInStorage('serverTimeZoneOffset', serverTimeZoneOffset);
        this._commonutils.setOffsetTime();
        callback && callback();
    };

    /**
     * Method to get entity field value
     * @param entities List of entity
     * @param entityId Id of entity to search for record
     * @param recordId Id of record to search for fields
     * @param fieldId Id of field to get value
     */
    getEntityFieldValue = (entities: Entity[], entityId: string, recordId: string, fieldId: string): string => {
        let value;
        for (let i = 0; i < entities.length; i++) {
            if (entities[i].uid === entityId) {
                if (entities[i].array) {
                    const records = entities[i].entityData as Record[];
                    for (let j = 0; j < records.length; j++) {
                        if (records[j].id === recordId) {
                            value = records[j].getFieldValue(fieldId);
                            break;
                        }
                        if (!value) {
                            records[j].entities &&
                                records[j].entities.length > 0 &&
                                this.getEntityFieldValue(records[j].entities, entityId, recordId, fieldId);
                        }
                    }
                } else {
                    const record = entities[i].entityData as Record;
                    value = record.getFieldValue(fieldId);
                }
                break;
            }
        }
        return value;
    };

    /**
     * Clear specific record fields
     * @param entities List of entities
     * @param entityId Entity id of the fields to be cleared
     * @param recordId Record id of the entity in which fields have to be cleared
     */
    clearRecordFieldsValues = (entities: Entity[], entityId: string, fieldsToClear: string[]) => {
        let entity: Entity;
        for (let i = 0; i < entities.length; i++) {
            if (entities[i].uid === entityId) {
                entity = entities[i];
            }
        }
        const record = entity.entityData as Record;
        fieldsToClear.forEach((fieldId) => {
            record.setFieldValue(fieldId, undefined);
        });
    };

    /**
     * Transform organizations array to create a hierarchy of organizations based on relation
     * @param organizations List of Organizations
     */
    transformOrganizations = (organizations: any[]): { [property: string]: Organization } => {
        const root = {};
        for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].type === 'envoice') {
                const currentOrg: Organization = new Organization(organizations[i]);
                this.transformLocation(currentOrg, root);
            }
        }
        return root;
    };

    /**
     * Method to transform and assign locations to root
     * @param location Organization
     * @param root Map of root organizations
     */
    private transformLocation = (location: Organization, root: { [property: string]: Organization }): void => {
        if (!location.parentId) {
            if (!root[location.id]) {
                root[location.id] = location;
            }
        } else if (location.parentId) {
            this.transformChildLocation(location, root);
        }
    };

    /**
     * Method to return transformation of child locations
     * @param location Organization object
     * @param root Map of root organizations object
     */
    private transformChildLocation = (location: Organization, root: { [property: string]: Organization }): Organization => {
        if (!location.parentId) {
            if (!root[location.id]) {
                root[location.id] = location;
            }
            return root[location.id];
        } else if (location.parentId && location.parentId.length > 0) {
            const parent = this.transformChildLocation(location.parent, root);
            parent.childOrganizations = parent.childOrganizations || {};
            const children = parent.childOrganizations;
            if (!children[location.id]) {
                children[location.id] = location;
            }
            return parent.childOrganizations[location.id];
        }
    };

    /**
     * Method to broadcast error
     * @param message Message to broadcast
     * @param timeout Optional timeout in milli seconds
     * @param title Optional title of the message
     */
    alertError = (message, timeout?, title?): void => {
        this.broadcast('message', {
            message,
            type: 'error',
            timeout,
            title,
        });
    };

    /**
     * Method to broadcast warning
     * @param message Message to broadcast
     * @param timeout Optional timeout in milli seconds
     * @param title Optional title of the message
     */
    alertWarning = (message, timeout?, title?): void => {
        this.broadcast('message', {
            message,
            type: 'warning',
            timeout,
            title,
        });
    };

    /**
     * Method to broadcast success
     * @param message Message to broadcast
     * @param timeout Optional timeout in milli seconds
     * @param title Optional title of the message
     */
    alertSuccess = (message, timeout?, title?, hasAction?, actionText?, callback?: (...args: any[]) => void): void => {
        this.broadcast('message', {
            message,
            type: 'success',
            timeout,
            title,
            hasAction,
            actionText,
            callback,
        });
    };

    alertCustomSuccess = (message, timeout?, actionText?, hasAction?: boolean, callback?: (...args: any[]) => void): void => {
        this.broadcast('message', {
            message,
            type: 'customSuccess',
            timeout,
            actionText,
            hasAction,
            callback,
        });
    };

    alertCustomWarning = (message, timeout?, actionText?, hasAction?: boolean, callback?: (...args: any[]) => void): void => {
        this.broadcast('message', {
            message,
            type: 'customWarning',
            timeout,
            actionText,
            hasAction,
            callback,
        });
    };

    alertCustomFailure = (message, timeout?, actionText?, hasAction?: boolean, callback?: (...args: any[]) => void): void => {
        this.broadcast('message', {
            message,
            type: 'customFailure',
            timeout,
            actionText,
            hasAction,
            callback,
        });
    };

    /**
     * Method to broadcast note
     * @param message Message to broadcast
     * @param timeout Optional timeout in milli seconds
     * @param title Optional title of the message
     */
    alertNotify = (message, timeout?, title?): void => {
        this.broadcast('message', {
            message,
            type: 'note',
            timeout,
            title,
        });
    };

    /**
     * Method to broadcast show loading
     */
    setLanguage = (language: string) => {
        this.broadcast('message', { type: 'setLanguage', id: language });
    };

    /**
     * Method to broadcast show loading
     */
    showLoading = (message?: string, variable?: string): string => {
        const guid = UtilsService.guid(8);
        this.broadcast('message', {
            type: 'showLoading',
            id: guid,
            message,
            variable,
        });
        return guid;
    };

    /**
     * Method to broadcast hide loading
     */
    hideLoading = (guid?: string, variable?: string) => this.broadcast('message', { type: 'hideLoading', id: guid, variable });

    /**
     * method to broadcast messages from library to application
     * @param name Name of the message
     * @param data Message data
     */
    broadcast = (name: string, data: any): void => {
        this._broadcaster.broadcast(name, data);
    };

    /**
     * Method to Get Report
     * @param data contains instance ID, base URL, file URL, filename
     */
    getReport = (data: { instanceId: string; baseUrl: string; fileUrl: string; fileName: string }): string => {
        return (
            data.baseUrl +
            '/instances/' +
            data.instanceId +
            '/files?fileUrl=' +
            encodeURI(data.fileUrl) +
            '&fileName=' +
            encodeURI(data.fileName)
        );
    };

    /**
     * Method to open a new tab
     * @param url: URL to navigate to
     */
    openUrlInNewTab = (url: string) => {
        const body = document.querySelector('body');
        this.removeElement('newTabAnchorElement');
        const node = document.createElement('a');
        node.setAttribute('href', url);
        node.setAttribute('id', 'newTabAnchorElement');
        node.setAttribute('target', '_blank');
        node.style.visibility = 'hidden';
        body.appendChild(node);
        setTimeout(() => {
            document.getElementById('newTabAnchorElement').click();
            this.removeElement('newTabAnchorElement');
        });
    };

    /**
     * Open MaCre UI links in new tab
     */
    openMaCreUIInNewTab = (route: string) => {
        const domain = environment['macre-ui'];
        this.openUrlInNewTab(`${domain}/${route}`);
    };

    /**
     * Method to remove element
     */
    removeElement = (elementId: string) => {
        const element = document.querySelector('#' + elementId);
        if (element) {
            element.remove();
        }
    };

    setReturnPage = () => {
        if (this.R.sessionExpiredModal) {
            return;
        }
        const href = window.location.href;
        const enComply = environment['encomply-ui'];
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        const origin = window.location.origin;
        const currentOrganizationId = this._commonutils.getFromStorage('currentOrganizationId');
        if (window.location.pathname.indexOf(environment.returnPage) > -1 && window.location.href.indexOf('returnUrl') > -1) {
            const searchText = window.location.search;
            const returnString = decodeURIComponent(searchText && searchText.substring(searchText.indexOf('http')));
            if (returnString && returnString.indexOf(origin) > -1) {
                let trimmedReturnString = returnString.substring(origin.length);
                if (trimmedReturnString.substring(0, 1) !== '/') {
                    trimmedReturnString = '/' + trimmedReturnString.substring(1);
                }
                this._router.navigateByUrl(trimmedReturnString);
            } else {
                window.location.href =
                    returnString.indexOf(enComply) >= 0 ||
                    returnString.indexOf(enReport) >= 0 ||
                    returnString.indexOf(enInvoice) >= 0 ||
                    returnString.indexOf(enCollab) >= 0
                        ? returnString
                        : window.location.origin;
            }
        } else if (href.indexOf(environment.returnPage) > -1 && href.indexOf(enReport) > -1) {
            this._router.navigate(['organizations', currentOrganizationId]);
        } else if (
            window.location.href.indexOf(environment.returnPage) > -1 ||
            window.location.pathname === '/' ||
            window.location.pathname === '/ui/'
        ) {
            window.location.href = window.location.origin + '/home';
        } else if (href.indexOf(enReport) > -1) {
            if (href.indexOf(enReport + '/organizations/' + currentOrganizationId) > -1) {
            } else {
                this._router.navigate(['enreport', 'organizations', currentOrganizationId]);
            }
        } else if (href.indexOf(enInvoice) > -1) {
            if (href.indexOf(enInvoice + '/organizations/' + currentOrganizationId + '/apps') > -1) {
                window.location.reload();
            } else {
                this._router.navigate(['eninvoice', 'organizations']);
            }
        } else if (href.indexOf(enCollab) > -1) {
            if (href.indexOf(enCollab + '/organizations/' + currentOrganizationId + '/home') > -1) {
                window.location.reload();
            } else {
                this._router.navigate(['encollab', 'organizations']);
            }
        } else {
            window.location.reload();
        }
    };

    postLogout = (redirect?: boolean, hideModal?: boolean) => {
        if (this.R.sessionExpiredModal) {
            return;
        }
        this._commonutils.clearStorage();
        if (window.location.pathname === '' || window.location.pathname === '/' || window.location.pathname === '/ui/') {
            this.R.sessionExpiredModal = true as any;
            this.proceedToRedirect(redirect);
        } else if (hideModal) {
            this.R.sessionExpiredModal = true as any;
            this.proceedToRedirect(redirect);
        } else {
            // const modal = this._bsModel.show(SessionExpiredModalComponent, {
            //     class: 'modal-dialog-centered sessionExpiredModal',
            //     backdrop: 'static',
            //     focus: true,
            //     ignoreBackdropClick: true,
            // });
            // modal.content.redirect = redirect;
            // modal.content.startRedirect = () => { this.proceedToRedirect(redirect); };
            // this.R.sessionExpiredModal = modal;
            const dialogRef = this.dialog.open(SessionExpiredModalComponent, {
                panelClass: ['sessionExpiredModal'],
                disableClose: true,
            });
            dialogRef.componentInstance.redirect = redirect;
            dialogRef.componentInstance.startRedirect = () => {
                this.proceedToRedirect(redirect);
            };
            dialogRef.afterClosed().subscribe((result) => {
                this.R.sessionExpiredModal = result;
            });
        }
    };

    proceedToRedirect = (redirect?: boolean) => {
        this.store$.dispatch(SessionLogoutSuccessfull());
        this.redirectToLogin(redirect);
    };

    redirectToLoginFromCRM = () => {
        let path: string = location.href;
        const stateId = this.guid(16);
        this._commonutils.redirectToPage(
            `${environment['auth-ui']}?redirectURL=${path}&stateId=${stateId}&clientId=${environment.clientId}`,
            true
        );
    };

    redirectToLogin = (redirect?: boolean) => {
        let path: string;
        if (window.location.pathname.indexOf('auth') > -1) {
            return;
        }
        if (window.location.search.indexOf('returnUrl') > -1) {
            const parsedUrl = this._router.parseUrl(window.location.href);
            path = parsedUrl && parsedUrl.queryParams && parsedUrl.queryParams.returnUrl;
        } else if (redirect) {
            const windowPath = window.location.pathname;
            if (windowPath.length > 1) {
                path = windowPath + window.location.search;
            } else {
                path = '';
            }
        } else {
            path = undefined;
        }
        const stateId = this.guid(16);
        this._commonutils.setInStorage('stateId', stateId);
        path && this._commonutils.setInStorage('returnPage', path);
        const returnURL = path ? `${window.location.origin}/redirect?returnPage=${path}` : `${window.location.origin}/redirect`;
        this._commonutils.redirectToPage(
            `${environment['auth-ui']}?redirectURL=${returnURL}&stateId=${stateId}&clientId=${environment.clientId}`
        );
        this.dialog.closeAll();
        this.R.clearRootScope();
        this._broadcaster.broadcast('logoutCompleted');
        setTimeout(() => {
            this.R.sessionExpiredModal = undefined;
        }, 100);
    };

    // method to check object empty or not
    isEmpty = (obj) => {
        switch (typeof obj) {
            case 'bigint':
            case 'boolean':
            case 'number':
            case 'string':
                return obj === undefined || obj === null || obj === '';
            case 'object':
                switch (Array.isArray(obj)) {
                    case true:
                        return obj.filter((item) => item !== undefined && item !== null).length === 0;
                    case false:
                        return obj === null || undefined
                            ? true
                            : (() => {
                                  for (const prop in obj) {
                                      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                                          return false;
                                      }
                                  }
                                  return true;
                              })();
                }
        }
    };

    getServiceId = (app: AssetService, bridge: AssetService) => {
        let serviceId: string;
        if (bridge && bridge.serviceId) {
            if (app.name) {
                serviceId = bridge.serviceId + '.' + app.name;
            } else {
                serviceId = bridge.serviceId;
            }
        } else {
            serviceId = app.serviceId;
        }
        return serviceId;
    };

    convertJSONtoBlob = (object): Blob => {
        const blob = new Blob([JSON.stringify(object)], {
            type: 'application/json',
        });
        return blob;
    };

    transformSortingOrder = (array, args) => {
        if (array && array !== undefined) {
            array.sort((a: any, b: any) => {
                if (a[args] < b[args]) {
                    return -1;
                } else if (a[args] > b[args]) {
                    return 1;
                } else {
                    if (a.flexFields && b.flexFields) {
                        let x;
                        let y;
                        for (let i = 0; i < a.flexFields.length; i++) {
                            if (a.flexFields[i].fieldDefinition.fieldName === 'gstin') {
                                x = a.flexFields[i].userValue;
                            }
                        }
                        for (let j = 0; j < b.flexFields.length; j++) {
                            if (b.flexFields[j].fieldDefinition.fieldName === 'gstin') {
                                y = b.flexFields[j].userValue;
                            }
                        }
                        if (x < y) {
                            return -1;
                        } else if (x > y) {
                            return 1;
                        }
                    } else {
                        return 0;
                    }
                }
            });
            return array;
        }
    };

    getParentRecordId = (recordId: string): string | null => {
        if (!recordId) {
            return;
        }
        const ids: any[] = recordId.split('#');
        if (ids && ids.length && ids.length > 2) {
            return ids[ids.length - 2] + '#' + ids[ids.length - 1];
        }
        return null;
    };

    getRecordId = (recordId: string): string => {
        return recordId;
    };

    findParentNode = (element: Element, parentClass: string): Element => {
        if (element.parentElement) {
            const isParent = element.parentElement.classList.value.indexOf(parentClass) > -1;
            if (isParent) {
                return element;
            } else {
                return this.findParentNode(element.parentElement, parentClass);
            }
        }
    };

    checkForSpecialChars = (str) => {
        const pattern = /[!@#$%^&*(),.?":{}|<>]/;
        if (str.match(pattern)) {
            return true;
        } else {
            return false;
        }
    };

    isIEBrowser = (): boolean => {
        const userAgent = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(userAgent);
        return isIE;
    };

    transformPermissionStructure = (permissions: any[], userPermissions: { [property: string]: any }) => {
        permissions &&
            permissions.forEach((permissionObject) => {
                this.transformPermission(permissionObject, userPermissions);
            });
    };

    private transformPermission = (
        permissionObject: {
            actionName: string;
            category: string;
            createdDate: string;
            displayName: string;
            id: number;
            objectId: string;
            permissionType: string;
        },
        allPermissions: any
    ) => {
        const objectIdArray = permissionObject?.objectId?.split('||') || [];
        allPermissions[permissionObject.category] = allPermissions[permissionObject.category] || {};
        const subSection = this.getPermissionSubSection(allPermissions[permissionObject.category], objectIdArray);
        subSection.permissions = subSection.permissions || {};
        subSection.permissions[permissionObject.actionName] = {};
        const permissionSubObject = subSection.permissions[permissionObject.actionName];
        for (const key in permissionObject) {
            if (permissionObject.hasOwnProperty(key)) {
                permissionSubObject[key] = permissionObject[key];
            }
        }
    };

    transformAppMetaDataWithPermissions = (appMetaData: AssetData, appPermissions) => {
        const guestUrl = environment['encomply-ui'] + '/collaboration';
        const currentUrl = window.location.href;
        const isInGuestMode = currentUrl.indexOf(guestUrl) > -1;
        appMetaData.entities.forEach((entity) => {
            const allPermissions = appPermissions?.entity && appPermissions?.entity[entity.uid];
            const entityPermissions = allPermissions?.permissions;
            this.transformEntityPermissions(allPermissions, entityPermissions, entity);
        });
        if (appPermissions?.permissions || isInGuestMode) {
            appMetaData['canTriggerWorkflow'] = appPermissions?.permissions?.['MANAGE_WORKFLOW'] || isInGuestMode;
            appMetaData['canCreateNewProcess'] = appPermissions?.permissions?.['NEW_PROCESS'] || isInGuestMode;
            appMetaData['canCancelInboundTransmission'] = appPermissions?.permissions?.['CANCEL_REQUEST'] || isInGuestMode;
            appMetaData['workflowPermissions'] = {};
            const workflowStages = appMetaData.workflowMetadata.stages;
            workflowStages.forEach((stage) => {
                const stagePermissions = appPermissions?.workflow?.[stage.id];
                appMetaData['workflowPermissions'][stage.originalName || stage.name] = [];
                Object.keys(stagePermissions?.permissions || {}).forEach((permissionName) => {
                    appMetaData['workflowPermissions'][stage.originalName || stage.name].push(permissionName);
                });
            });
        }
        return appMetaData;
    };

    private transformEntityPermissions = (allPermissions, entityPermissions, entity: Entity) => {
        const guestUrl = environment['encomply-ui'] + '/collaboration';
        const currentUrl = window.location.href;
        const isInGuestMode = currentUrl.indexOf(guestUrl) > -1;
        if (entityPermissions || isInGuestMode) {
            entity.hasAddRecordPermission = entityPermissions?.['ADD_RECORD'] || isInGuestMode;
            entity.hasDeleteRecordPermission = entityPermissions?.['DELETE_RECORD'] || isInGuestMode;
            entity.hasReadRecordPermission = entityPermissions?.['READ_RECORD'] || isInGuestMode;
            entity.hasUpdateRecordPermission = entityPermissions?.['UPDATE_RECORD'] || isInGuestMode;
        } else {
            entity.hasAddRecordPermission = false;
            entity.hasDeleteRecordPermission = false;
            entity.hasReadRecordPermission = false;
            entity.hasUpdateRecordPermission = false;
        }
        entity.entities.forEach((subEntity) => {
            const allSubEntityPermissions = allPermissions?.entity && allPermissions?.entity[subEntity.uid];
            const subEntityPermissions = allSubEntityPermissions?.permissions;
            this.transformEntityPermissions(allSubEntityPermissions, subEntityPermissions, subEntity);
        });
    };

    private getPermissionSubSection = (
        allPermissions: {
            [property: string]: any;
        },
        subsectionsArray: string[]
    ): {
        [property: string]: any;
    } => {
        if (subsectionsArray.length > 0) {
            allPermissions[subsectionsArray[0]] = allPermissions[subsectionsArray[0]] || {};
            const subObject = allPermissions[subsectionsArray[0]];
            if (subsectionsArray.length > 1) {
                subsectionsArray.splice(0, 1);
                return this.getPermissionSubSection(subObject, subsectionsArray);
            }
            return subObject;
        }
        return allPermissions;
    };

    transformNodePermissions = (app: BridgeNode, permissions: any) => {
        const appPermissions = permissions?.[app.name];
        this.addNodePermissions(app, appPermissions?.permissions);
    };

    addNodePermissions = (app: BridgeNode | AssetService, permissions: any) => {
        if (permissions) {
            app.canCreateNewProcess = permissions['NEW_PROCESS'] !== undefined;
            app.canViewAllProcesses = permissions['PROCESSES'] !== undefined;
            app.canViewIntergrations = permissions['INTEGRATION_METHOD'] !== undefined;
            app.canManageWorkflow = permissions['MANAGE_WORKFLOW'] !== undefined;
            (app as any).canProcessReports = permissions['PROCESS_REPORTS'] !== undefined;
            app.canViewVDM = permissions['VDM'] !== undefined;
        } else {
            app.noPermissions = true;
        }
        return app;
    };

    public changeFilesContentType = (files: FileList) => {
        const newFiles = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < files.length; i++) {
            newFiles.push(this.changeFileContentType(files[i]));
        }
        return newFiles;
    };

    public changeFileContentType = (file: File) => {
        const fileType = this.getFileType(file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length));
        const fileAsBlob = new Blob([file]);
        let blobAsFile: any;
        try {
            blobAsFile = new File([fileAsBlob], file.name, {
                type: fileType,
                lastModified: file['lastModifiedDate'],
            });
        } catch (e) {
            blobAsFile = file;
        }
        return blobAsFile;
    };

    private getFileType = (extension) => {
        const maps = FileTypes && (FileTypes as any).default;
        const extensionDetails = maps && maps['.' + extension] ? maps['.' + extension] : undefined;
        return extensionDetails?.mimeType || '';
    };

    copyObjectToObject = (target, source) => {
        if (typeof source === 'object') {
            if (Array.isArray(source)) {
                source.forEach((sourceObject) => {
                    target.push(CommonUtilsService.cloneObject(sourceObject));
                });
            } else {
                source &&
                    Object.keys(source).forEach((key) => {
                        if (!target[key]) {
                            switch (typeof source[key]) {
                                case 'object':
                                    target[key] = Array.isArray(source[key]) ? [] : {};
                                    break;
                                case 'bigint':
                                case 'boolean':
                                case 'function':
                                case 'number':
                                case 'string':
                                case 'symbol':
                                case 'undefined':
                                    // Do Nothing
                                    break;
                            }
                        }
                        if (typeof target[key] === 'object') {
                            this.copyObjectToObject(target[key], source[key]);
                        } else {
                            target[key] = source[key];
                        }
                    });
            }
        }
        return target;
    };

    generateBaseRoute = ({
        organizationId,
        bridgeApi,
        appApi,
        reportApi,
        noEnreportBase,
        noEninvoiceBase,
        noEncollabBase,
        noEnreconBase,
        noGstFilingBase,
        switchToUI: switchToUi,
    }: {
        organizationId?: string;
        bridgeApi?: string;
        appApi?: string;
        reportApi?: string;
        noEnreportBase?: boolean;
        noEninvoiceBase?: boolean;
        noEncollabBase?: boolean;
        noEnreconBase?: boolean;
        noGstFilingBase?: boolean;
        switchToUI?: 'encomply' | 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile' | 'gst-filing';
    }): string[] => {
        const routes = [];
        const isInEnReport = location.href.indexOf(environment['enreport-ui']) > -1;
        const isInEnInvoice = location.href.indexOf(environment['eninvoice-ui']) > -1;
        const isInEnCollab = location.href.indexOf(environment['encollab-ui']) > -1;
        const isInEnRecon = location.href.indexOf(environment['enreconcile-ui']) > -1;
        const isInGstFiling = location.href.indexOf(environment['gst-filing']) > -1;
        if (isInEnReport && !noEnreportBase && !switchToUi) {
            routes.push('enreport');
        } else if (isInEnInvoice && !noEninvoiceBase && !switchToUi) {
            routes.push('eninvoice');
        } else if (isInEnCollab && !noEncollabBase && !switchToUi) {
            routes.push('encollab');
        } else if (isInEnRecon && !noEnreconBase && !switchToUi) {
            routes.push('enreconcile');
        } else if (isInGstFiling && !noGstFilingBase && !switchToUi) {
            routes.push('gst-filing');
        }
        if (switchToUi && switchToUi !== 'encomply') {
            routes.push(switchToUi);
        }
        organizationId =
            organizationId ||
            this._commonutils.getFromStorage('currentOrganizationId') ||
            this._commonutils.getFromStorage('rootOrganizationId');
        if (!organizationId) {
            return [''];
        }
        routes.push('organizations', organizationId);
        const isGoingToEnRecon =
            switchToUi === 'enreconcile' || (switchToUi === undefined && isInEnRecon && (bridgeApi?.length > 0 || appApi?.length > 0));
        if (isGoingToEnRecon) {
            routes.push('home');
        }
        if (bridgeApi) {
            routes.push('packages', bridgeApi);
        }
        if (appApi) {
            routes.push('apps', appApi);
        }
        if (reportApi) {
            routes.push('reports', reportApi);
        }
        if (isGoingToEnRecon) {
            routes.push('processes');
        }
        return routes;
    };

    navigateToOrganization = (
        organizationId?: string,
        switchToUI?: 'encomply' | 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile' | 'gst-filing'
    ) => {
        const onNewUI = this._commonutils.getCookie('new-ui') === 'true';
        const routes = this.generateBaseRoute({ organizationId, switchToUI: onNewUI ? 'encomply' : switchToUI });
        if (routes?.[0] !== '' && onNewUI) {
            routes.push('category', 'transactions');
        }
        this._router.navigate(routes);
    };

    navigateToApp = ({
        bridgeApi,
        appApi,
        reportApi,
        organizationId,
        noEnreportBase,
        noEninvoiceBase,
        noEncollabBase,
        noEnreconBase,
        switchToUi,
        replaceCurrentRoute,
    }: {
        bridgeApi?: string;
        appApi: string;
        reportApi?: string;
        organizationId?: string;
        noEnreportBase?: boolean;
        noEninvoiceBase?: boolean;
        noEncollabBase?: boolean;
        noEnreconBase?: boolean;
        switchToUi?: 'encomply' | 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile';
        replaceCurrentRoute?: boolean;
    }) => {
        this._commonutils.setInStorage('app-home-redirect-url', window.location.pathname);
        const routes = this.generateBaseRoute({
            organizationId,
            bridgeApi,
            appApi,
            reportApi,
            noEnreportBase,
            noEninvoiceBase,
            noEncollabBase,
            noEnreconBase,
            switchToUI: switchToUi,
        });
        this._router.navigate(routes, {
            replaceUrl: replaceCurrentRoute || false,
        });
    };

    navigateToAppInboundConfiguration = ({
        bridgeApi,
        appApi,
        reportApi,
        organizationId,
        configName,
    }: {
        bridgeApi: string;
        appApi: string;
        reportApi: string;
        organizationId?: string;
        configName?: string;
    }) => {
        this.navigateToAppIntegrationConfiguration(bridgeApi, appApi, reportApi, organizationId, 'inbound-integrations', configName);
    };

    navigateToAppOutboundConfiguration = ({
        bridgeApi,
        appApi,
        reportApi,
        organizationId,
        configName,
    }: {
        bridgeApi: string;
        appApi: string;
        reportApi: string;
        organizationId?: string;
        configName?: string;
    }) => {
        this.navigateToAppIntegrationConfiguration(bridgeApi, appApi, reportApi, organizationId, 'outbound-integrations', configName);
    };

    navigateToAppMastersScheduler = ({
        bridgeApi,
        appApi,
        reportApi,
        organizationId,
        configName,
    }: {
        bridgeApi: string;
        appApi: string;
        reportApi: string;
        organizationId?: string;
        configName?: string;
    }) => {
        this.navigateToAppIntegrationConfiguration(bridgeApi, appApi, reportApi, organizationId, 'masters-scheduler', configName);
    };

    private navigateToAppIntegrationConfiguration = (
        bridgeApi: string,
        appApi: string,
        reportApi: string,
        organizationId?: string,
        type?: 'inbound-integrations' | 'outbound-integrations' | 'masters-scheduler',
        configName?: string
    ) => {
        const routes = this.generateBaseRoute({
            organizationId,
            bridgeApi,
            appApi,
            reportApi,
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push(type);
        configName?.length > 0 && routes.push(configName);
        this._router.navigate(routes);
    };

    navigateToCreateNew = (bridgeApi: string, appApi: string, reportApi: string, templateId?: string) => {
        const routes = this.generateBaseRoute({ bridgeApi, appApi, reportApi });
        const isInEnRecon = location.href.indexOf(environment['enreconcile-ui']) > -1;
        !isInEnRecon && routes.push('processes');
        routes.push('new');
        this._router.navigate(routes, {
            queryParams: templateId && {
                templateId: templateId,
            },
        });
    };

    navigateToTemplates = (bridgeApi: string, appApi: string, reportApi: string) => {
        const routes = this.generateBaseRoute({ bridgeApi, appApi, reportApi });
        const isInEnRecon = location.href.indexOf(environment['enreconcile-ui']) > -1;
        !isInEnRecon && routes.push('processes');
        routes.push('templates');
        this._router.navigate(routes);
    };

    navigateToInstance = ({
        bridgeApi,
        appApi,
        reportApi,
        instanceId,
        noEnreportBase,
        noEninvoiceBase,
        noEncollabBase,
        noEnreconBase,
        switchToUI,
        showIncompleteProcesses,
    }: {
        bridgeApi: string;
        appApi: string;
        reportApi: string;
        instanceId?: string;
        noEnreportBase?: boolean;
        noEninvoiceBase?: boolean;
        noEncollabBase?: boolean;
        noEnreconBase?: boolean;
        switchToUI?: 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile';
        showIncompleteProcesses?: boolean;
    }) => {
        const routes = this.generateBaseRoute({
            bridgeApi,
            appApi,
            reportApi,
            noEnreportBase,
            noEninvoiceBase,
            noEncollabBase,
            noEnreconBase,
            switchToUI,
        });
        routes.indexOf('processes') === -1 && routes.push('processes');
        if (showIncompleteProcesses) {
            routes.push('incomplete');
        }
        if (switchToUI === 'enreport' && !instanceId) {
            routes.push('state', 'all');
        }
        if (instanceId?.length > 0) {
            routes.push(instanceId);
        }
        this._router.navigate(routes);
    };

    navigateToRequest = (
        bridgeApi: string,
        appApi: string,
        reportApi: string,
        requestId: string,
        switchUI?: 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile'
    ) => {
        const routes = this.generateBaseRoute({
            bridgeApi,
            appApi,
            reportApi,
            switchToUI: switchUI || 'enreport',
        });
        const isInEnInvoice = location.href.indexOf(environment['eninvoice-ui']) > -1;
        !isInEnInvoice && routes.indexOf('processes') === -1 && routes.push('processes');
        !isInEnInvoice && routes.push('inbound-transmissions', requestId, 'instances');
        this._router.navigate(routes);
    };

    navigateToEditInstance = (
        bridgeApi: string,
        appApi: string,
        reportApi: string,
        instanceId: string,
        showIncompleteProcesses?: boolean,
        fileName?: string,
        fileUrl?: string
    ) => {
        const routes = this.generateBaseRoute({ bridgeApi, appApi, reportApi });
        routes.push('processes');
        if (showIncompleteProcesses) {
            routes.push('incomplete');
        }
        if (instanceId?.length > 0) {
            routes.push(instanceId, 'edit');
        }
        const params = {};
        if (fileName?.length > 0) {
            params['fileName'] = encodeURIComponent(fileName);
        }
        if (fileUrl?.length > 0) {
            params['fileUrl'] = encodeURIComponent(fileUrl);
        }
        this._router.navigate(routes, {
            queryParams: params,
        });
    };

    navigateToSettingsPage = () => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push('settings');
        this._router.navigate(routes);
    };

    navigateToMyApps = () => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push('manageapps');
        this._router.navigate(routes);
    };

    navigateToWorkQPage = (page?: string, id?: string) => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push('workq', 'feed');
        page && id && routes.push(page, id);
        this._router.navigate(routes);
    };

    navigateToDashboardPage = (switchToUI?: 'encomply' | 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile') => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
            switchToUI,
        });
        routes.push('dashboard');
        this._router.navigate(routes);
    };

    navigateToIntegrationConfiguration = (type: string) => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push('settings', 'integrations', type);
        this._router.navigate(routes);
    };

    navigateToAuthorizationConfig = (id?: string) => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        routes.push('settings', 'authorization-systems');
        id?.length > 0 && routes.push(id);
        this._router.navigate(routes);
    };

    navigateToCollaborationRequest = (requestId: string, switchToUI?: 'enreport' | 'eninvoice' | 'encollab' | 'enreconcile') => {
        const routes = this.generateBaseRoute({ switchToUI });
        routes.push('partner-collaborate');
        if (requestId?.length > 0) {
            routes.push(requestId);
        }
        this._router.navigate(routes);
    };

    public navigateToHomePage = () => {
        const routes = this.generateBaseRoute({
            noEncollabBase: true,
            noEninvoiceBase: true,
            noEnreconBase: true,
            noEnreportBase: true,
            noGstFilingBase: true,
        });
        if (this._commonutils.getCookie('new-ui') === 'true') {
            routes.push('category');
        } else {
            routes.push('home');
        }
        this._router.navigate(routes);
    };

    getOrganizationFromUrl = () => {
        const url = this._router.url;
        const split = url.split('/');
        const organizationIndex = split.findIndex((item) => item === 'organizations');
        const orgId = organizationIndex > -1 && split[organizationIndex + 1];
        return orgId;
    };

    setTitle = (newTitle: string) => {
        this.title.setTitle(newTitle);
    };

    isInNewProcessPage = () => {
        const url = window.location.href;
        const split = url.split('/');
        const newSegmentIndex = split.indexOf('new');
        return newSegmentIndex > -1 && newSegmentIndex === split.length - 1;
    };

    isInProcessPage = () => {
        const url = window.location.href;
        const split = url.split('/');
        const processIndex = split.indexOf('processes');
        const instanceIndex = processIndex + 1;
        return processIndex > -1 && split[instanceIndex] !== undefined && instanceIndex === split.length - 1;
    };

    pushStateIntoSearchCriteria = (state: string, criteria: any, primaryEntity: Entity) => {
        if (criteria) {
            const primaryEntityId = primaryEntity.uid;
            criteria.entityFilterCriterias && this.pushStateIntoFilters(state, criteria.entityFilterCriterias, primaryEntityId);
        }
    };

    pushStateIntoFilters = (state: string, criteria: any, primaryEntityId: string) => {
        let filterWithEntityId = criteria.find((filter) => filter.entityUid === primaryEntityId);
        if (!filterWithEntityId) {
            filterWithEntityId = {
                criteriaDefinitions: [
                    {
                        criteriaName: primaryEntityId,
                        restriction: {
                            restrictionType: 'JoinedRestrictionMetadata',
                            condition: 'AND',
                            rules: [],
                        },
                        type: 'CriteriaDefinition',
                    },
                ],
                entityUid: primaryEntityId,
            };
            criteria.rules.push(filterWithEntityId);
        }
        const definitions = filterWithEntityId.criteriaDefinitions.find(
            (definition) => definition.restriction.restrictionType === 'JoinedRestrictionMetadata'
        );
        const rules = definitions.restriction.rules;
        const field = rules.find((rule) => rule.fieldId === 'instanceState.raw');
        if (!field || typeof field.value === 'string') {
            rules.push({
                fieldId: 'instanceState.raw',
                operator: 'IN',
                restrictionType: 'SingleRestrictionMetadata',
                value: [state],
            });
        } else if (Array.isArray(field.value) && field.value.indexOf(state) === -1) {
            field.value.push(state);
        }
    };

    getStateFromCriteria = (criteria: any, primaryEntity: Entity) => {
        const primaryEntityId = primaryEntity.uid;
        return criteria?.entityFilterCriterias && this.getStateFromCriteriaFilters(criteria.entityFilterCriterias, primaryEntityId);
    };

    getStateFromCriteriaFilters = (criteria: any, primaryEntityId: string) => {
        const filterWithEntityId = criteria.find((filter) => filter.entityUid === primaryEntityId);
        const definitions = filterWithEntityId?.criteriaDefinitions.find(
            (definition) => definition.restriction.restrictionType === 'JoinedRestrictionMetadata'
        );
        const rules = definitions?.restriction.rules;
        const field = rules?.find((rule) => rule.fieldId === 'instanceState.raw');
        return typeof field?.value === 'string' ? { stateInCriteria: field.value, operator: field.operator } : {};
    };

    /**
     * Method to set record values for a field
     * @param recordToMap of entity
     * @param entities asset entities
     * @param targetFields property from source component
     * @param parentRecord is the parent record for the recordToMap record
     * Note: No support for entity that is array: true;
     */
    entityVsRecordMap = (recordToMap: Record, entities: Entity[], targetFields: TargetField[], parentRecord?: Record) => {
        if (!Array.isArray(recordToMap)) {
            recordToMap.fields.map((field) => {
                targetFields.map((targetField) => {
                    if (targetField.fieldUid === field.id) {
                        let primaryEntity: Entity = this.getHeirarchyEntity(entities, targetField.referenceMetadata.entityUid);
                        if (Array.isArray(primaryEntity.entityData)) {
                            /* this is the case when entity is an array and its subentity is depend up on parent entity with add new button case */
                            if (parentRecord) {
                                if (primaryEntity.uid === parentRecord.entityId) {
                                    const sourceRecordField = parentRecord.getFieldByFieldId(targetField.dataToGetFrom);
                                    sourceRecordField.show && recordToMap.setFieldValue(field.id, sourceRecordField.value);
                                }
                            }
                        } else {
                            const sourceField = (primaryEntity.entityData as Record).getFieldByFieldId(targetField.dataToGetFrom);
                            sourceField.show && recordToMap.setFieldValue(field.id, sourceField.value);
                        }
                        /**
                         Note: Know whether the parent entity needs to be primary entity or not when there is any subentity with references
                         and population is not happening even after setting record value with setFieldValue
                         */
                        // const primaryEntity = entities.find(entity => entity.getEntity(targetField.referenceMetadata.entityUid)); - old way before doing with getHeirarchyEntity
                        // Note: No support for cross reference entity fields(there has to be a parent and child relationship between entities)
                        // if (parentRecord) {
                        //     if (primaryEntity.uid === parentRecord.entityId) {
                        //         const sourceRecordField = parentRecord.getFieldByFieldId(targetField.dataToGetFrom);
                        //         recordToMap.setFieldValue(field.id, sourceRecordField.value);
                        //     }
                        // } else {
                        // const sourceField = (primaryEntity.entityData as Record).getFieldByFieldId(targetField.dataToGetFrom);
                        // recordToMap.setFieldValue(field.id, sourceField.value);
                        // }
                    }
                });
            });
        }
    };

    /**
     * Method to set record values for a field
     * @param currentRecord of entity
     * @param responseRecord asset entity Record with values
     * @param targetFields property from source component
     * @param entities is assetdata entities
     */
    responseRecordVsRecordMap = (currentRecord: Record, targetFields: TargetField[], responseRecords: Record[]) => {
        currentRecord.fields.forEach((field) => {
            targetFields.forEach((targetField) => {
                if (targetField.fieldUid === field.id) {
                    // ResponseRecords holds the server record info, which is why using responseRecords instead of all entities data
                    const primaryEntity: Record = responseRecords.find(
                        (record) => record.entityId === targetField.referenceMetadata.entityUid
                    );
                    // know: if the subentity has references out side parent entity will it be possible to auto populate(check no support at present)
                    // Currently support was only given to those sub entitiy fields that has reference from its parent entity not cross entity(other entity). [only parent child relationship is possible]
                    const sourceField = primaryEntity.getFieldByFieldId(targetField.dataToGetFrom);
                    if (sourceField) {
                        currentRecord.setFieldValue(field.id, sourceField.value);
                    }
                }
            });
        });
    };

    getHeirarchyEntity = (entities: Entity[], entityId: string) => {
        let primaryEntity: Entity;
        for (let i = 0; i < entities.length; i++) {
            const entity = entities[i];
            const actualEntity = entity.getEntity(entityId);
            if (actualEntity) {
                primaryEntity = actualEntity;
                break;
            }
        }
        if (primaryEntity) {
            return primaryEntity;
        }
    };

    /**
     * recursion method that checks entity inside asset entities
     * @param entities asset entities
     * @returns targetFields
     */
    constructTargetFields = (entities: Entity[]) => {
        this.targetFields = [];
        entities?.forEach((entity) => {
            this.targetFieldsHelper(entity);
        });
        return this.targetFields;
    };

    /**
     * Method to construct targetfields used for electronic form autofilling
     * @param entity entity to be checked for subentities
     */
    targetFieldsHelper = (entity: Entity) => {
        if (entity?.fields.length) {
            entity.fields.forEach((field) => {
                if (field.referredAssetFieldMetadata) {
                    const targetField: TargetField = {
                        fieldUid: field.uid,
                        dataToGetFrom: field.referredAssetFieldMetadata.fieldUid,
                        referenceMetadata: field.referredAssetFieldMetadata,
                    };
                    this.targetFields.push(targetField);
                }
            });
        }
        if (entity.entities.length) {
            entity.entities.forEach((subEntity) => {
                this.targetFieldsHelper(subEntity);
            });
        }
    };

    /**
     * Method that gets executes when there is any value change on record input
     * @param fieldData object that contains record input value, Entity Record and Entity RecordField
     * @param targetFields property from source component
     */
    recordInputModelChange = (fieldData: { input: string; record: Record; field: RecordField }, targetFields: TargetField[]) => {
        const groupedTargetFields = targetFields.filter((targetField) => targetField.dataToGetFrom === fieldData.field.id);
        if (groupedTargetFields.length > 1) {
            groupedTargetFields.forEach((groupedTargetField) => {
                this.setRecordInputChange(fieldData, groupedTargetField);
            });
        } else {
            const targetField = targetFields?.find((targetField: TargetField) => targetField.dataToGetFrom === fieldData.field.id);
            this.setRecordInputChange(fieldData, targetField);
        }
    };

    private setRecordInputChange = (fieldData: { input: string; record: Record; field: RecordField }, targetField: TargetField) => {
        if (targetField) {
            fieldData.record.setFieldValue(targetField.fieldUid, fieldData.input);
        }
    };

    /**
     * Method that autofills the record data based on the source targetFields
     * @note This is for hierarchical entity structure
     * @param currentViewData current view record
     * @param entities asset entities from source component
     * @param targetFields property from source component
     */
    populatePrimaryEntityValues = (currentViewData: Record, entities: Entity[], targetFields: TargetField[], parentRecord: Record) => {
        if (parentRecord) {
            this.entityVsRecordMap(currentViewData, entities, targetFields, parentRecord);
        } else {
            this.entityVsRecordMap(currentViewData, entities, targetFields);
        }
    };
    /**
     * Method to autofil the record data based on source Record
     * @note This is for hierarchical entity structure
     * @param currentRecord which is a Record
     * @param responseRecords is Record[]
     * @param targetFields is Target[]
     * @param currentEntities is Entity[]
     */
    populatePrimaryRecordValues = (
        currentRecordsData: ViewRecordType,
        responseRecords: Record[],
        targetFields: TargetField[],
        currentEntities: Entity[]
    ) => {
        if (currentRecordsData.parentEntity) {
            const parentEntityRecord = responseRecords.find(
                (responseRecord) => responseRecord.entityId === currentRecordsData.parentEntity.uid
            );
            if (parentEntityRecord) {
                this.responseRecordVsRecordMap(currentRecordsData.record, targetFields, responseRecords);
            }
        } else {
            const responseRecord = responseRecords.find((responseRecord) =>
                currentEntities.some((entity) => entity.uid === responseRecord.entityId)
            );
            if (responseRecord) {
                this.responseRecordVsRecordMap(currentRecordsData.record, targetFields, responseRecords);
            }
        }
    };

    encryptString = (textToEncrypt: string, dynamicPassPhrase: string): string => {
        const iv = (CryptoJS.lib.WordArray.random(128 / 8) as any).toString(CryptoJS.enc.Hex);
        const salt = (CryptoJS.lib.WordArray.random(128 / 8) as any).toString(CryptoJS.enc.Hex);
        const aesUtil = new AesUtil(128, 1000);
        const encrypText = aesUtil.encrypt(salt, iv, dynamicPassPhrase, textToEncrypt);
        const aesPassword = iv + ':' + salt + ':' + encrypText;
        const encoding = btoa(aesPassword);
        return encoding;
    };

    encryptWithSHA256 = (textToEncrypt: string, encoding?: 'HEX' | 'BASE64') => {
        const encryptedText = CryptoJS.SHA256(textToEncrypt);
        if (encoding) {
            let encodedString;
            switch (encoding) {
                case 'BASE64':
                    encodedString = encryptedText.toString(CryptoJS.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
                    break;
                case 'HEX':
                    encodedString = encryptedText.toString(CryptoJS.enc.Hex);
                    break;
            }
            return encodedString;
        } else {
            return encryptedText;
        }
    };

    encryptWithBase64 = (textToEncode: string) => {
        const encodedWord = CryptoJS.enc.Utf8.parse(textToEncode);
        const encoded = CryptoJS.enc.Base64.stringify(encodedWord);
        return encoded;
    };

    checkOrgCode = (code) => {
        const pattern = /^([a-zA-Z0-9-]+)$/;
        return pattern.test(code);
    };

    convertEnumsToNormalWords = (word) => {
        let convertStringToArray = [];
        const newArray = [];
        let ConvertedString = '';
        if (typeof word === 'string') {
            convertStringToArray = word.split(' ').join('|||').split('_').join('|||').split('|||');
            if (convertStringToArray.length > 0) {
                convertStringToArray.forEach((element) => {
                    newArray.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase());
                });
                ConvertedString = newArray.join(' ');
            } else {
                ConvertedString = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        } else {
            return word;
        }
        return ConvertedString;
    };

    convertEventTypesToNewNames = (eventType) => {
        let newStr = eventType;
        const instanceIndex = eventType.indexOf('Instance');
        const requestIndex = eventType.indexOf('Request');
        if (instanceIndex > -1) {
            newStr = eventType.replace('Instances', 'Processes').replace('Instance', 'Process');
        } else if (requestIndex === 0) {
            newStr = eventType.replace('Requests', 'Inbound Transmissions').replace('Request ', 'Inbound Transmission ');
        }
        return newStr;
    };

    submitForm = (url: string, payload: { [property: string]: string }) => {
        const existingElement = document.querySelector('body').querySelector('.externalFormSubmit');
        if (existingElement) {
            existingElement.parentNode.removeChild(existingElement);
        }
        const element: HTMLFormElement = document.createElement('form');
        element.setAttribute('class', 'externalFormSubmit');
        element.action = url;
        element.method = payload ? 'POST' : 'GET';
        Object.keys(payload || {}).forEach((key) => {
            const inputElement: HTMLInputElement = document.createElement('input');
            inputElement.name = key;
            inputElement.value = payload[key];
            element.appendChild(inputElement);
        });
        document.querySelector('body').appendChild(element);
        element.submit();
    };

    waitForCertainTime = (timeout: number): Promise<void> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, timeout);
        });
    };

    checkValueExists = (value: any): boolean => {
        if (typeof value === 'string') {
            return value !== undefined && value.trim().length > 0;
        }
        if (typeof value === 'object' && this.isEmpty(value)) {
            return false;
        }
        return value !== undefined;
    };

    checkWebUrl = (url) => {
        // const urlExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        const urlExp =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        if (url) {
            if (url.match(urlExp)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // checking length of value
    static checkMaxLength = (value, charcount) => {
        return value && charcount && value.length <= charcount;
    };

    // checking length of value
    static checkMinLength = (value, charcount) => {
        return value && charcount && value?.trim()?.length >= charcount;
    };

    // checking length of value
    static checkMinValue = (value, minValue = 0) => {
        if (value) {
            return parseInt(value, undefined) > minValue;
        }
        return false;
    };

    /**
     * Get operators per datatype for elastic search query builder
     */

    getOperatorsPerDataType = (dt?) => {
        const operatorsPerDataType = {
            date: ['=', '<=', '>', 'is null', 'is not null'],
            textarea: ['=', '!=', 'contains', 'is null', 'is not null'],
            string: ['=', '!=', 'contains', 'is null', 'is not null'],
            boolean: ['='],
        };

        if (dt) {
            return operatorsPerDataType[dt];
        }

        return operatorsPerDataType;
    };

    /**
     * Get operators per datatype for elastic search query builder
     * Remove above method (getOperatorsPerDataType)after checking the scope of it across apps
     */

    getSearchOperatorsPerDataType = (dt?) => {
        const operatorsPerDataType = {
            date: ['EQ', 'NOT_EQUALS', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            number: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            textarea: ['EQ', 'NOT_EQUALS', 'CONTAINS', 'is null', 'is not null'],
            string: ['EQ', 'NOT_EQUALS', 'IN', 'CONTAINS', 'is null', 'is not null'],
            options: ['EQ', 'NOT_EQUALS', 'IN', 'is null', 'is not null'],
            equals: ['EQ', 'NOT_EQUALS'],
            boolean: ['EQ', 'NOT_EQUALS', 'is null', 'is not null'],
        };

        if (dt) {
            return operatorsPerDataType[dt];
        }

        return operatorsPerDataType;
    };

    /**
     * Get allowed datatype
     */

    getDataType = (dataType: string) => {
        const dt = dataType && dataType.toLowerCase();
        let allowedDT = '';
        switch (dt) {
            case 'string':
                allowedDT = 'string';
                break;
            case 'textarea':
                allowedDT = 'textarea';
                break;
            case 'boolean':
                allowedDT = 'boolean';
                break;
            case 'date':
                allowedDT = 'date';
                break;
            case 'double':
            case 'long':
            case 'int':
            case 'number':
            case 'integer':
                allowedDT = 'number';
                break;
            case 'checkbox':
                allowedDT = 'category';
                break;
            default:
                allowedDT = 'term';
                break;
        }
        return allowedDT;
    };

    checkName = (name) => {
        name = (name as string).trim();
        if (!name || (name as string).trim() === '') {
            // this.errors.organizationName = ['Enter organization name'];
            return false;
        } else if (name && (name as string).length > 30) {
            // this.errors.organizationName = ['Maximum number of characters allowed is 30'];
            return false;
        } else if (this.invalidChars(name)) {
            // this.errors.organizationName = ['Enter a valid organization name'];
            return false;
        } else if (!this.acceptOrgName(name)) {
            // this.errors.organizationName = ['Organization name must be alpha numeric'];
            return false;
        }
        return true;
    };

    /**
     * Regex method to check the if the input string is a valid organization name.
     */
    acceptOrgName = (org_name) => {
        // var regex = /^[[A-Z]{1}[A-Za-z0-9+ +.+'+\-+\x26]{0,49}]*$/;
        // const regex = /^([A-Za-z0-9-\s]{0,99})*$/;
        const regex = /^([A-Za-z0-9 ]{0,99})*$/;
        return org_name && regex.test(org_name);
    };

    /**
     * Setting a page route
     */
    setRoute = (url, options?) => {
        this.setUrlRoute({ url: url }, options);
    };

    /**
     * Setting a page route with router
     */
    setUrlRoute = (data, options?) => {
        if (typeof data.url === 'string') {
            this._router.navigate([data.url], options);
        } else if (Array.isArray(data.url)) {
            this._router.navigate(data.url, options);
        }
    };
    getRestApiName = (app) => {
        const report = this._commonutils.getFromStorage('report');
        if (report) {
            return report.restApiName;
        } else if (app) {
            return app.restApiName;
        }
    };

    isInPdf = (reportName: string) => {
        const format = reportName.substring(reportName.lastIndexOf('.'));
        return format && format.toLowerCase() === '.pdf';
    };
    /**
     * Input checkSum will be of format
     * "check-sum-algo1:generate-check-sum-value1|check-sum-algo2:generate-check-sum-value2"
     * It needs to be converted into
     * {
     *      check-sum-algo1: generate-check-sum-value1,
     *      check-sum-algo2: generate-check-sum-value2
     * }
     * */

    convertCheckSumStrToKeyValue = (checkSum) => {
        if (!checkSum) {
            return;
        }
        const chksmObj = {};
        const checkSumArr = checkSum.split('|');
        checkSumArr.forEach((element) => {
            const chksm = element.split(':');
            chksmObj[chksm[0]] = chksm[1];
        });
        return chksmObj;
    };

    transformWrapperRequestsToGeneral = (response: {
        page?: number;
        size?: number;
        totalPages?: number;
        records: {
            batchId: string;
            chainName: string;
            createdBy: string;
            createdOn: string;
            id: string;
            partitioned: boolean;
            requestId: string;
            platformRequestId: string;
            requestStatus: string;
            requestType: string;
            sourceDetails: {
                createdOn: string;
                fileName: string;
                fileUrl: string;
                id: string;
                requestId: string;
                source: string;
            }[];
            unitId: string;
            updatedOn: string;
            username: string;
            createdDate?: string;
            sourceType?: string;
            fileName?: string;
            requestState?: string;
            sourceId?: string;
            transformationName?: string;
            wrapperRequestId?: string;
        }[];
    }) => {
        const returnObject = {
            requests: [],
            nextPagingState: (response.page || 0) < response.totalPages ? response.page + 1 : undefined,
        };
        response.records.forEach((record) => {
            const fileNames = [];
            record.sourceDetails?.forEach((source) => fileNames.push(source.fileName));
            record.createdDate = record.createdOn;
            record.sourceType = record.requestType;
            record.fileName = fileNames.join(', ');
            record.requestState = record.requestStatus;
            record.transformationName = record.chainName;
            record.wrapperRequestId = record.requestId;
            record.requestId = record.platformRequestId;
            returnObject.requests.push(record);
        });
        return returnObject;
    };

    sortBridgeAssets = (bridgeNodes: Map<string, BridgeNode>): BridgeNode[] => {
        const bridges = [];
        for (const key in bridgeNodes) {
            bridges.push(bridgeNodes[key]);
        }
        const sortedBridges = bridges.sort((a: BridgeNode, b: BridgeNode) => {
            const value = a.level - b.level;
            return value < 0 ? -1 : value > 0 ? 1 : 0;
        });
        return sortedBridges;
    };

    sortFields = (field1: Field, field2: Field, dontCheckPreCapture?: boolean, dontCheckMandatory?: boolean) => {
        let a = this.getFieldType(field1, dontCheckPreCapture, dontCheckMandatory);
        let b = this.getFieldType(field2, dontCheckPreCapture, dontCheckMandatory);
        return a - b;
    };

    getFieldType = (field: Field, dontCheckPreCapture?: boolean, dontCheckMandatory?: boolean) => {
        if (field.showInList) {
            return 0;
        }
        if (field.isBusinessKey) {
            return 1;
        }
        if (!dontCheckPreCapture && field.preCaptureType === 'FILING') {
            return 2;
        }
        if (!dontCheckMandatory && field.mandatory) {
            return 3;
        }
        return 4;
    };

    public fieldsToShowInList = (fields: Field[]) => {
        return fields
            .filter((field) => field.show)
            .filter((field) => field.showInList || field.isBusinessKey || field.mandatory || field.preCaptureType)
            .sort((a, b) => this.sortFields(a, b, true))
            .reduce((feildsToReturn, field) => {
                feildsToReturn.push(field.uid);
                return feildsToReturn;
            }, []);
    };

    sortWidgets = (a: SearchFilter, b: SearchFilter) => {
        if (a.x < b.x) {
            return 0;
        } else if (a.x > b.x) {
            return 1;
        } else if (a.y < b.y) {
            return 0;
        } else if (a.y > b.y) {
            return 1;
        }
        return 0;
    };

    public getTenantName = (organization?: Organization, delimiter?: string) => {
        const delimiterString = delimiter ? '  ' + delimiter + '  ' : '  |  ';
        if (organization) {
            if (organization.parent) {
                return this.getTenantName(organization.parent) + delimiterString + organization.name;
            } else {
                return organization.name;
            }
        }
    };

    destructStringedArray = (msg: string): string => (msg.includes('[') ? JSON.parse(msg).toString() : msg);

    public static getRange(startFrom: number, until: number) {
        return Array.from({ length: until + 1 - startFrom }, (_, k) => k + startFrom);
    }

    public static getGSTRFilingRequestsSearchCriteria = (
        appConfigurator: AppConfigurator,
        asset: AssetData,
        serviceId: string,
        year: string,
        month: string,
        quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'
    ) => {
        const metaData = new AssetData(CommonUtilsService.cloneObject(asset));
        const primaryEntity = metaData.getPrimaryEntity();
        const timelineFilter = appConfigurator?.wrapperUI?.timelineFilter;
        const frequency = timelineFilter?.frequency || metaData?.tagEntries?.find((tagEntry) => tagEntry?.key === 'frequency')?.value;
        const rules = UtilsService.getRules(timelineFilter, primaryEntity, frequency, month, year, quarter);
        return {
            entityId: primaryEntity.uid,
            restApiServiceName: asset.restAPIName,
            sort: {},
            filterCriteria: {
                serviceId,
                entityFilterCriterias: [
                    {
                        entityUid: primaryEntity.uid,
                        criteriaDefinitions: [
                            {
                                type: 'CriteriaDefinition',
                                criteriaName: primaryEntity.uid,
                                restriction: {
                                    condition: 'AND',
                                    rules: [
                                        ...rules,
                                        {
                                            operator: 'IN',
                                            value: [
                                                'IN_PROGRESS',
                                                'REQUEST_COMPLETED',
                                                'TERMINATED',
                                                'REQUEST_CANCELLED',
                                                'PARTITIONS_CREATED',
                                            ],
                                            fieldId: 'instanceState.raw',
                                            restrictionType: 'SingleRestrictionMetadata',
                                        },
                                    ],
                                    restrictionType: 'JoinedRestrictionMetadata',
                                },
                            },
                        ],
                    },
                    {
                        entityUid: '-',
                        criteriaDefinitions: [
                            {
                                type: 'CriteriaDefinition',
                                criteriaName: 'terminatedCriteria',
                                restriction: {
                                    condition: 'AND',
                                    rules: [
                                        ...rules,
                                        {
                                            operator: 'IN',
                                            value: ['TERMINATED'],
                                            fieldId: 'instanceState.raw',
                                            restrictionType: 'SingleRestrictionMetadata',
                                        },
                                    ],
                                    restrictionType: 'JoinedRestrictionMetadata',
                                },
                            },
                        ],
                    },
                ],
                criteriaRelations: {
                    condition: 'OR',
                    rules: [
                        {
                            operator: 'EQ',
                            value: primaryEntity.uid,
                            fieldId: 'Criteria',
                            restrictionType: 'SingleRestrictionMetadata',
                        },
                        {
                            operator: 'EQ',
                            value: 'terminatedCriteria',
                            fieldId: 'Criteria',
                            restrictionType: 'SingleRestrictionMetadata',
                        },
                    ],
                    restrictionType: 'JoinedRestrictionMetadata',
                },
            },
            size: 1000,
            loadRequests: true,
        };
    };

    public static getGSTRFilingSearchCriteria = (
        appConfigurator: AppConfigurator,
        asset: AssetData,
        serviceId: string,
        year: string,
        month: string,
        quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4',
        searchFieldsList?: {
            id: string;
            value: any;
            message: Message;
        }[],
        processStatus?: string,
        withoutAnyErrors?: boolean
    ) => {
        const metaData = new AssetData(CommonUtilsService.cloneObject(asset));
        const primaryEntity = metaData.getPrimaryEntity();
        const timelineFilter = appConfigurator?.wrapperUI?.timelineFilter;
        const frequency = timelineFilter?.frequency || metaData?.tagEntries?.find((tagEntry) => tagEntry?.key === 'frequency')?.value;
        const rules = UtilsService.getRules(
            timelineFilter,
            primaryEntity,
            frequency,
            month,
            year,
            quarter,
            searchFieldsList,
            withoutAnyErrors
        );
        const nonPrimaryEntityCriterias = [];
        const nonPrimaryEntityRelations = [];
        withoutAnyErrors &&
            metaData.entities
                .filter((entity) => !entity.primary)
                .forEach((entity) => UtilsService.createNonErrorRule(entity, nonPrimaryEntityCriterias, nonPrimaryEntityRelations));
        return {
            entityId: primaryEntity.uid,
            restApiServiceName: asset.restAPIName,
            sort: {},
            filterCriteria: {
                serviceId,
                entityFilterCriterias: [
                    {
                        entityUid: primaryEntity.uid,
                        criteriaDefinitions: [
                            {
                                type: 'CriteriaDefinition',
                                criteriaName: primaryEntity.uid,
                                restriction: {
                                    condition: 'AND',
                                    rules: UtilsService.prepareRules(rules, processStatus),
                                    restrictionType: 'JoinedRestrictionMetadata',
                                },
                            },
                        ],
                    },
                    ...nonPrimaryEntityCriterias,
                ],
                criteriaRelations: {
                    condition: 'AND',
                    restrictionType: 'JoinedRestrictionMetadata',
                    rules: [
                        {
                            fieldId: 'Criteria',
                            operator: 'EQ',
                            restrictionType: 'SingleRestrictionMetadata',
                            value: primaryEntity.uid,
                        },
                        ...nonPrimaryEntityRelations,
                    ],
                },
            },
            size: 1000,
            loadRequests: true,
        };
    };

    private static createNonErrorRule = (
        entity: Entity,
        nonPrimaryEntityCriterias: EntityFilterCriterias[],
        nonPrimaryEntityRelations: SearchRule[]
    ) => {
        nonPrimaryEntityCriterias.push({
            entityUid: entity.uid,
            criteriaDefinitions: [
                {
                    type: 'CriteriaDefinition',
                    criteriaName: entity.uid,
                    restriction: {
                        condition: 'AND',
                        rules: [
                            {
                                fieldId: 'validationStatus',
                                operator: 'NOT_EQUALS',
                                restrictionType: 'SingleRestrictionMetadata',
                                value: 'ERROR',
                            },
                        ],
                        restrictionType: 'JoinedRestrictionMetadata',
                    },
                },
            ],
        });
        nonPrimaryEntityRelations.push({
            fieldId: 'Criteria',
            operator: 'EQ',
            restrictionType: 'SingleRestrictionMetadata',
            value: entity.uid,
        });
        entity?.entities?.forEach((entity) =>
            UtilsService.createNonErrorRule(entity, nonPrimaryEntityCriterias, nonPrimaryEntityRelations)
        );
    };

    private static prepareRules = (rules: any[], processStatus?: string) => {
        let preparedRules = [
            ...rules,
            {
                operator: 'IN',
                value: ['IN_PROGRESS', 'REQUEST_COMPLETED'],
                fieldId: 'instanceState.raw',
                restrictionType: 'SingleRestrictionMetadata',
            },
        ];
        processStatus &&
            preparedRules.push({
                fieldId: 'instanceStatus.raw',
                operator: 'EQ',
                restrictionType: 'SingleRestrictionMetadata',
                value: processStatus,
            });
        return preparedRules;
    };

    public static getGSTROTPSearchCriteria = (asset: AssetData, gstin: string) => {
        const metaData = new AssetData(asset);
        const primaryEntity = metaData.getPrimaryEntity();
        const gstinField = UtilsService.getFilingGSTIN(primaryEntity);
        let rules = [];
        gstinField?.uid &&
            rules.push({
                operator: 'EQ',
                value: gstin,
                fieldId: gstinField.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        return {
            entityId: primaryEntity.uid,
            restApiServiceName: asset.restAPIName,
            sort: {},
            filterCriteria: {
                entityFilterCriterias: [
                    {
                        entityUid: primaryEntity.uid,
                        criteriaDefinitions: [
                            {
                                type: 'CriteriaDefinition',
                                criteriaName: primaryEntity.uid,
                                restriction: {
                                    condition: 'AND',
                                    rules: [
                                        ...rules,
                                        {
                                            operator: 'IN',
                                            value: ['IN_PROGRESS', 'REQUEST_COMPLETED'],
                                            fieldId: 'instanceState.raw',
                                            restrictionType: 'SingleRestrictionMetadata',
                                        },
                                    ],
                                    restrictionType: 'JoinedRestrictionMetadata',
                                },
                            },
                        ],
                    },
                ],
                criteriaRelations: { restrictionType: 'JoinedRestrictionMetadata' },
            },
            size: 1,
            loadRequests: true,
        };
    };

    static getRules = (
        timelineFilter: TimelineFilter,
        primaryEntity: Entity,
        frequency: string,
        month: string,
        year: string,
        quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4',
        searchFields?: {
            id: string;
            value: any;
            message: Message;
        }[],
        withoutAnyErrors?: boolean
    ) => {
        const monthField = UtilsService.getFilingMonth(timelineFilter, primaryEntity);
        const monthNumberField = UtilsService.getFilingMonthNumber(timelineFilter, primaryEntity);
        const yearField = UtilsService.getFilingYear(timelineFilter, primaryEntity);
        const financialYear = UtilsService.getFilingFinancialYear(timelineFilter, primaryEntity);
        const financialYearWithoutHyphen = UtilsService.getFilingFinancialYearWithoutHyphen(timelineFilter, primaryEntity);
        const monthHyphenYear = UtilsService.getFilingMonthHyphenYear(timelineFilter, primaryEntity);
        const monthYear = UtilsService.getFilingMonthYear(timelineFilter, primaryEntity);
        const quarterField = UtilsService.getFilingQuarter(timelineFilter, primaryEntity);
        month = month?.length < 2 ? '0' + month : month;
        const rules = [];
        yearField?.uid &&
            year &&
            (frequency === 'yearly' || frequency === 'quarterly' || frequency === 'monthly') &&
            rules.push({
                operator: 'EQ',
                value: UtilsService.getYearValue(year, month, quarter, 'yyyy'),
                fieldId: yearField.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        monthField?.uid &&
            month &&
            frequency === 'monthly' &&
            rules.push({
                operator: 'EQ',
                value: month,
                fieldId: monthField.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        quarterField?.uid &&
            quarter &&
            frequency === 'quarterly' &&
            rules.push({
                operator: 'EQ',
                value: quarter,
                fieldId: quarterField.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        monthNumberField?.uid &&
            month &&
            frequency === 'monthly' &&
            rules.push({
                operator: 'EQ',
                value: `${parseInt(month)}`,
                fieldId: monthNumberField.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        financialYear?.uid &&
            year &&
            (frequency === 'yearly' || frequency === 'quarterly' || frequency === 'monthly') &&
            rules.push({
                operator: 'EQ',
                value: UtilsService.getYearValue(year, month, quarter, 'yyyy-yy'),
                fieldId: financialYear.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        financialYearWithoutHyphen?.uid &&
            year &&
            (frequency === 'yearly' || frequency === 'quarterly' || frequency === 'monthly') &&
            rules.push({
                operator: 'EQ',
                value: UtilsService.getYearValue(year, month, quarter, 'yyyyyy'),
                fieldId: financialYearWithoutHyphen.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        monthHyphenYear?.uid &&
            year &&
            month &&
            frequency === 'monthly' &&
            rules.push({
                operator: 'EQ',
                value: UtilsService.getYearValue(year, month, quarter, 'mm-yyyy'),
                fieldId: monthHyphenYear.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        monthYear?.uid &&
            year &&
            month &&
            frequency === 'monthly' &&
            rules.push({
                operator: 'EQ',
                value: UtilsService.getYearValue(year, month, quarter, 'mmyyyy'),
                fieldId: monthYear.uid,
                restrictionType: 'SingleRestrictionMetadata',
            });
        searchFields?.forEach((field) => {
            switch (field.id) {
                case 'gstin':
                    const dataField = UtilsService.getFilingGSTIN(primaryEntity);
                    dataField?.uid &&
                        rules.push({
                            operator: 'EQ',
                            value: field.value,
                            fieldId: dataField.uid,
                            restrictionType: 'SingleRestrictionMetadata',
                        });
                    break;
            }
        });
        withoutAnyErrors &&
            rules.push({
                fieldId: 'validationStatus',
                operator: 'NOT_EQUALS',
                restrictionType: 'SingleRestrictionMetadata',
                value: 'ERROR',
            });
        return rules;
    };

    static getYearValue = (
        year: string,
        month: string,
        quarter: string,
        outputFormat: 'yyyy' | 'yyyy-yy' | 'yyyyyy' | 'mm-yyyy' | 'mmyyyy'
    ) => {
        if (!year) {
            return undefined;
        }
        if ((month && parseInt(month) <= 3) || quarter === 'Q4') {
            switch (outputFormat) {
                case 'yyyy':
                    return '' + (parseInt(year) + 1);
                case 'yyyy-yy':
                    return `${year}-${(parseInt(year) + 1).toString().substring(2)}`;
                case 'yyyyyy':
                    return `${year}${(parseInt(year) + 1).toString().substring(2)}`;
                case 'mm-yyyy':
                    return `${month}-${parseInt(year) + 1}`;
                case 'mmyyyy':
                    return `${month}${parseInt(year) + 1}`;
            }
        } else {
            switch (outputFormat) {
                case 'yyyy':
                    return year;
                case 'yyyy-yy':
                    return `${year}-${(parseInt(year) + 1).toString().substring(2)}`;
                case 'yyyyyy':
                    return `${year}${(parseInt(year) + 1).toString().substring(2)}`;
                case 'mm-yyyy':
                    return `${month}-${parseInt(year)}`;
                case 'mmyyyy':
                    return `${month}${parseInt(year)}`;
            }
        }
    };

    static getValueByTagAndFieldFormat = (
        tagValue: string,
        year: string,
        month: string,
        quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4',
        gstin: string
    ) => {
        month = month.length < 2 ? '0' + month : month;
        switch (tagValue) {
            case 'quarter':
                return quarter;
            case 'month':
                return month;
            case 'monthnumber':
                return parseInt(month);
            case 'year':
                return parseInt(month) <= 3 ? '' + (parseInt(year) + 1) : year;
            case 'period':
                return `${year}-${(parseInt(year) + 1).toString().substring(2)}`;
            case 'period-without-hyphen':
                return `${year}${(parseInt(year) + 1).toString().substring(2)}`;
            case 'month-year':
                return `${month}-${parseInt(month) <= 3 ? '' + (parseInt(year) + 1) : year}`;
            case 'monthyear':
                return `${month}${parseInt(month) <= 3 ? '' + (parseInt(year) + 1) : year}`;
            case 'gstin':
                return gstin;
        }
    };

    static getFilingQuarter = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find(
                (field) =>
                    field.uid === timeLineFilter?.quarterFieldId ||
                    field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'quarter')
            );
    };

    static getFilingMonth = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find(
                (field) =>
                    field.uid === timeLineFilter?.monthFieldId ||
                    field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'month')
            );
    };

    static getFilingMonthNumber = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.monthFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'monthnumber')
        );
    };

    static getFilingYear = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.yearFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'year')
        );
    };

    static getFilingFinancialYear = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.yearFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'period')
        );
    };

    static getFilingFinancialYearWithoutHyphen = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.yearFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'period-without-hyphen')
        );
    };

    static getFilingMonthHyphenYear = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.monthFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'month-year')
        );
    };

    static getFilingMonthYear = (timeLineFilter: TimelineFilter, entity: Entity) => {
        return entity?.fields.find(
            (field) =>
                field.uid === timeLineFilter?.monthFieldId ||
                field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'monthyear')
        );
    };

    static getFilingGSTIN = (entity: Entity) => {
        return entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find((field) => field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'gstin'));
    };

    public buildFinancialYearsAndMonths = () => {
        let financialYears: {
            displayName: string;
            value: string;
            months: {
                year: string;
                displayName: string;
                monthNumber: number;
                month: string;
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
            }[];
            quarters: {
                year: string;
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
            }[];
        }[] = [];
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        for (let i = currentYear; i >= 2016; i--) {
            if (!(i === currentYear && currentMonth <= 4)) {
                financialYears.push({
                    displayName: `FY ${i}-${('' + (+i + 1)).substring(2)}`,
                    value: '' + i,
                    ...this.buildFinancialYearMonths(i),
                });
            }
        }
        return financialYears;
    };

    public buildFinancialYearMonths = (year: number) => {
        let financialYear: {
            months: {
                year: string;
                displayName: string;
                monthNumber: number;
                month: string;
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
            }[];
            quarters: {
                year: string;
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
            }[];
        } = {
            months: [],
            quarters: [],
        };
        const monthRange = UtilsService.getRange(0, 11).reverse();
        const actualDate = new Date().getTime();
        [year + 1, year].forEach((yearValue, yearIndex) => {
            let quarters = [];
            monthRange.forEach((month) => {
                const date = new Date(`${month + 1}/01/${yearValue}`);
                if (date.getTime() < actualDate && ((yearIndex === 1 && month > 2) || (yearIndex === 0 && month <= 2))) {
                    const quarter = yearIndex === 0 ? 4 : Math.floor(month / 3);
                    financialYear.months.push({
                        displayName: this.getMonth(month),
                        year: '' + yearValue,
                        monthNumber: month + 1,
                        month: '' + (month + 1),
                        quarter: ('Q' + quarter) as any,
                    });
                    if (!quarters.includes(quarter)) {
                        quarters.push(quarter);
                        financialYear.quarters.push({
                            year: '' + yearValue,
                            quarter: ('Q' + quarter) as any,
                        });
                    }
                }
            });
        });

        return financialYear;
    };

    public getMonth = (number: number) => {
        return {
            0: 'January',
            1: 'February',
            2: 'March',
            3: 'April',
            4: 'May',
            5: 'Jun',
            6: 'July',
            7: 'August',
            8: 'September',
            9: 'October',
            10: 'November',
            11: 'December',
        }[number];
    };

    getProviderIds = (providers: { [property: string]: any }, orgIds: string[]) => {
        const organization = this._commonutils.getFromStorage('currentOrganization');
        const providerIds = this.idsList?.[organization?.id] || [];
        return providerIds?.length > 0 ? providerIds : this.getProviders(providers, orgIds, organization);
    };

    private getProviders = (providers: { [property: string]: any }, orgIds: string[], organization: Organization) => {
        if (providers) {
            if (providers[organization.id] && orgIds.indexOf(organization.id) === -1) {
                orgIds.push(organization.id);
            }
            if (organization.parent) {
                this.getProviders(providers, orgIds, organization.parent);
            }
            if (providers[organization.providerOrganizationId] && orgIds.indexOf(organization.providerOrganizationId) === -1) {
                orgIds.push(organization.providerOrganizationId);
            }
        } else {
            if (organization.id && orgIds.indexOf(organization.id) === -1) {
                orgIds.push(organization.id);
            }
            if (organization.parent) {
                this.getProviders(providers, orgIds, organization.parent);
            }
            if (orgIds.indexOf(organization.providerOrganizationId) === -1) {
                orgIds.push(organization.providerOrganizationId);
            }
        }
        return orgIds;
    };
}
