import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryBuilderConfig } from 'ng-query-builder';
import { filter, Subject, takeUntil } from 'rxjs';

import { Entity } from '../../models/entity.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { DashBoradSettingsServiceService } from '../../services/dashboard-settings/dash-borad-settings-service.service';
import { ElasticsearchService } from '../../services/elasticsearch/elasticsearch.service';
import { RootScopeService } from '../../services/rootscope/rootscope.service';
import { UtilsService } from '../../services/utils/utils.service';
import {
    DeleteSearchConfiguration,
    GetSearchedMasterRecords,
    GetSearchedMasterRecordsCount,
    SavedSearchFilter,
    SaveSearchConfiguration,
    SetMasterRecordPagination,
    SetMasterRecords,
} from '../../store/actions';
import { getCurrentOrganizationId$, getSearchFilters$, getSelectedRestApiName$ } from '../../store/selectors';
import { SavecustomfilterComponent } from '../savecustomfilter/savecustomfilter.component';

@Component({
    selector: 'app-mastersearch',
    templateUrl: './mastersearch.component.html',
    styleUrls: ['./mastersearch.component.scss'],
})
export class MastersearchComponent implements OnInit, OnDestroy {
    @ViewChild('passwordCredential') passwordCredential: TemplateRef<any>;
    @ViewChild('authorizationCode') authorizationCode: TemplateRef<any>;
    @ViewChild('implicit') implicit: TemplateRef<any>;
    @ViewChild('clientCredential') clientCredential: TemplateRef<any>;
    @ViewChild('matFilterSelect') _matFilterSelect: MatSelect;

    public selectedNewTokenTokenRef: TemplateRef<any>;
    public title: any;
    public allowRuleset = true;
    public allowCollapse = true;
    public showSortCriteria = true;
    private copySearchQuery: any;
    public allowDisableForFieldAnfQuery = false;
    public sourceArrayOptions: any;
    public sourceVsSetting: any;
    public entityArrayOptions: any;
    public selectedSource: any;
    public widgetSettingsModal = false;
    public showSourceSetting = false;
    public searchConfig: QueryBuilderConfig;
    public sortQuery: any;
    private copySortQuery: any;
    public sortConfig: QueryBuilderConfig;
    public isFromMaster = false;
    public searchFilters = [];
    public defaultFilter: any;
    public isFieldsAvailable = false;
    private saveDialogRef: any;
    public onDataFetched: (...args: any[]) => void;
    public searchQueryChangeListener: (...args: any[]) => void;
    public sortQueryChangeListener: (...args: any[]) => void;
    public updateDefaultFilter: (...args: any[]) => void;
    public getCustomFilter: (...args: any[]) => void;
    public updatePaginationCount: (...args: any[]) => void;
    public webServiceChangeListener: (...args: any[]) => void;
    public updateSelectedMasterFilter: (...args: any[]) => void;
    public updateValidationFilter: (...args: any[]) => void;
    public resetPagination: (...args: any[]) => void;
    public clearSearch: () => void;
    public searchQueryFound = true;
    public loadingSearchCriteria = false;
    public showModalBody = true;
    public initiateElasticSearch = true;
    public webServiceSettings: any;
    public webServicesSourceSettings: any;
    public selectedSourceHeaders = [];
    public selectedSourceParams = [];
    public dataType = 'password';
    private currentOrganizationId: string;
    private masterRestApiName: string;
    private unSubscribe = new Subject<void>();
    public authTypes = [
        {
            name: 'No Auth',
            value: 'NoAuthSettings',
        },
        {
            name: 'Basic Auth',
            value: 'BasicAuthSettings',
        },
        {
            name: 'OAuth 2.0',
            value: 'OAuthSettings',
        },
    ];
    public selectedAuth = 'NoAuth';
    public login = {
        userId: '',
        password: '',
    };
    public newTokens = [
        {
            name: 'Authorization Code',
            value: 'Authorization Code',
        },
        {
            name: 'Implicit',
            value: 'Implicit',
        },
        {
            name: 'Password Credentials',
            value: 'Password Credentials',
        },
        {
            name: 'Client Credentials',
            value: 'Client Credentials',
        },
    ];
    public availableTypes = [
        {
            name: 'Text',
            id: 'text',
            value: 'Text',
        },
        {
            name: 'XML',
            id: 'xml',
            value: 'Xml',
        },
        {
            id: 'json',
            name: 'JSON',
            value: 'JSON',
        },
    ];
    private clientAuthentication = [
        {
            name: 'Send as Basic Auth Header',
            value: 'Send as Basic Auth Header',
        },
        {
            name: 'Send client credentials in body',
            value: 'Send client credentials in body',
        },
    ];
    private selectedPasswordClientAuth = '';
    public selectedAuthClientAuth = '';
    public selectedImplicitClientAuth = '';
    public selectedClientCredentialClientAuth = '';
    public selected = new UntypedFormControl(0);
    public authorizationCodeData = [
        {
            name: 'Callback URL',
            id: 'callbackURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Auth URL',
            id: 'authURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Access Token URL',
            id: 'accessTokenURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client ID',
            id: 'clientId',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Secret',
            id: 'clientSecret',
            value: '',
            selectBox: false,
        },
        {
            name: 'Scope',
            id: 'scope',
            value: '',
            selectBox: false,
        },
        {
            name: 'State',
            id: 'state',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Authentication',
            id: 'clientAuthentication',
            selectBox: true,
            values: CommonUtilsService.cloneObject(this.clientAuthentication),
            value: this.selectedAuthClientAuth,
        },
    ];
    public implicitData = [
        {
            name: 'Callback URL',
            id: 'callbackURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Auth URL',
            id: 'authURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client ID',
            id: 'clientId',
            value: '',
            selectBox: false,
        },
        {
            name: 'Scope',
            id: 'scope',
            value: '',
            selectBox: false,
        },
        {
            name: 'State',
            id: 'state',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Authentication',
            id: 'clientAuthentication',
            selectBox: true,
            values: CommonUtilsService.cloneObject(this.clientAuthentication),
            value: this.selectedImplicitClientAuth,
        },
    ];
    public passwordCredentialData = [
        {
            name: 'Access Token URL',
            id: 'accessTokenURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Username',
            id: 'userName',
            value: '',
            selectBox: false,
        },
        {
            name: 'Password',
            id: 'password',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client ID',
            id: 'clientId',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Secret',
            id: 'clientSecret',
            value: '',
            selectBox: false,
        },
        {
            name: 'Scope',
            id: 'scope',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Authentication',
            id: 'clientAuthentication',
            selectBox: true,
            values: CommonUtilsService.cloneObject(this.clientAuthentication),
            value: this.selectedPasswordClientAuth,
        },
    ];
    public clientCredentialData = [
        {
            name: 'Access Token URL',
            id: 'accessTokenURL',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client ID',
            id: 'clientId',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Secret',
            id: 'clientSecret',
            value: '',
            selectBox: false,
        },
        {
            name: 'Scope',
            id: 'scope',
            value: '',
            selectBox: false,
        },
        {
            name: 'Client Authentication',
            id: 'clientAuthentication',
            selectBox: true,
            values: CommonUtilsService.cloneObject(this.clientAuthentication),
            value: this.selectedClientCredentialClientAuth,
        },
    ];
    public selectedNewToken = '';
    public isReferedCriteria = false;
    public selectedBodyType = '';
    public bodyContent = '';
    public ReferredCriteriaEntity: any;
    public size: number;
    public searchQuery: any;
    public masterFilters = [
        {
            name: 'validationStatus',
            displayName: translate('Validation Status'),
            checked: true,
        },
        {
            name: 'searchFilters',
            displayName: translate('Search Filters'),
            checked: false,
        },
        {
            name: 'customSearch',
            displayName: translate('Custom Search'),
            checked: false,
        },
    ];
    public selectedOutlookFilter: any;
    public validationFilters = [
        {
            name: 'SUCCESS',
            displayName: translate('Success'),
        },
        {
            name: 'WARNING',
            displayName: translate('Warning'),
        },
        {
            name: 'ERROR',
            displayName: translate('Error'),
        },
    ];
    public validationFilter: any;
    public canShowSortQuery: boolean;
    private selectedFilterName: string;

    constructor(
        private dialog: MatDialog,
        private _taxilla: ApiService,
        private _esService: ElasticsearchService,
        private dashboardSetting: DashBoradSettingsServiceService,
        private _libUtils: UtilsService,
        private confirmationService: ConfirmationDialogService,
        private R: RootScopeService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MastersearchComponent>,
        private store$: Store,
        private _commonUtils: CommonUtilsService,
        private actions$: Actions
    ) {}

    public closeModal = () => {
        this.dialogRef.close();
    };

    public onSearchQueryChanged = (data) => {
        this.copySearchQuery = data;
    };

    public onSortQueryChanged = (data) => {
        this.copySortQuery = data;
    };

    public renderSaveFilterPopup = () => {
        if (this.hasEmptyValues(this.copySearchQuery) || this.copySearchQuery?.rules?.length === 0) {
            this._libUtils.alertError(translate('Please create valid rule'));
        } else {
            if (this.defaultFilter?.filterName) {
                this.selectedFilterName = this.defaultFilter.filterName;
            }
            if (this.selectedFilterName) {
                this.saveFilter(this.selectedFilterName);
                return;
            }
            this.saveDialogRef = this.dialog.open(SavecustomfilterComponent, {
                panelClass: ['saveCustomFilterDialog', 'matDialogContainer'],
                disableClose: true,
                data: {
                    title: this.translateMsg('Save Filter'),
                    onSaveFilter: this.saveFilter,
                },
            });
        }
    };

    private hasEmptyValues = (searchData: { rules: any[] }) => {
        return searchData?.rules.some((rule) => {
            switch (typeof rule.value) {
                case 'string':
                    return rule.value.trim() === '' || rule.value.trim() === undefined;
                case 'object':
                    return Array.isArray(rule.valu)
                        ? rule.value.some((value) => value === '' || value === undefined)
                        : Object.values(rule.value).some((value) => value === '' || value === undefined);
                default:
                    return false;
            }
        });
    };

    public saveFilter = (filterName: any): void => {
        if (!filterName) {
            return;
        } else {
            const hasSearchFilter = this.searchFilters.find(
                (searchFilter) => searchFilter.filterName.toLowerCase() === filterName.toLowerCase()
            );
            if (hasSearchFilter && !this.selectedFilterName) {
                this._libUtils.alertError(`${this.translateMsg('Filter')} ${filterName} ${this.translateMsg('Already Exists')}`);
                return;
            }
            const searchQuery2 = CommonUtilsService.cloneObject(this.copySearchQuery);
            const searchData = {
                query: searchQuery2,
                searchConfig: this.searchConfig,
                isFirstCall: true,
            };
            const searchQueryString = this._esService.buildSearchQueryString(searchData);
            const sortQuery2 = CommonUtilsService.cloneObject(this.copySortQuery);
            const sortQueryString = this._esService.buildSortQueryString(sortQuery2);
            const criteria = {
                searchQueryString,
                sortQueryString,
            };
            const data = {
                filterName,
                objectId: this.R.selectedMaster.itemId,
                criteria: JSON.stringify(criteria),
                id: this.defaultFilter?.id,
            };
            this.store$.dispatch(SaveSearchConfiguration(data));
        }
    };

    /**
     * Method to edit search filters
     * @param filter saved filter criteria object
     */
    public editFilter = (filter) => {
        let criteriaObject = JSON.parse(filter.criteria);
        let searchQuery = this._esService.buildQueryObj(criteriaObject.searchQueryString?.bool?.must?.[0], this.searchConfig);
        let sortQuery = this._esService.buildSortQueryObject(criteriaObject.sortQueryString);
        this.selectedSource.searchQuery = searchQuery;
        this.sortQuery = sortQuery;
        this.selectedOutlookFilter = 'customSearch';
        this.selectedFilterName = filter?.filterName;
        this.defaultFilter = filter;
        this.updateDefaultFilter(filter);
    };

    public searchMasterEntities = (criteria?: any) => {
        const data: {
            searchCriteria?: any;
            searchQuery?: any;
            sortQuery?: any;
            searchConfig?: any;
            pageIndex?: any;
            size?: any;
            dontGetCount?: boolean;
        } = {
            searchCriteria: criteria && JSON.stringify(criteria),
            searchQuery: this.copySearchQuery,
            sortQuery: this.copySortQuery,
            searchConfig: this.searchConfig,
            size: this.size,
        };
        let preparedCriteria = this._esService.searchMasterEntities(data);
        if (preparedCriteria) {
            this.getSearchedMasterRecords(preparedCriteria, data);
        }
    };

    private checkSourceSettingOptions = (index: number, source) => {
        if (this.sourceArrayOptions?.length > 0 && this.initiateElasticSearch) {
            this.selectedSource = source || this.sourceArrayOptions[index || 0];
            this.formEntityOptions(this.selectedSource, true);
        } else {
            this.selectedSource = this.selectedSource || {
                searchQuery: this.searchQuery,
            };
            if (this.webServiceSettings && this.webServiceSettings.sourcesSettings && this.webServiceSettings.sourcesSettings.length > 0) {
                this.webServicesSourceSettings = CommonUtilsService.cloneObject(this.webServiceSettings.sourcesSettings);
                this.selectedSource = this.webServicesSourceSettings[0];
                this.formAuthenticationDetails();
                setTimeout(() => {
                    this.showModalBody = true;
                });
                this.searchQueryFound = true;
            }
        }
    };

    private formAuthenticationDetails = () => {
        if (this.selectedSource && Object.keys(this.selectedSource).length > 0) {
            if (this.selectedSource.integrationSettings && this.selectedSource.integrationSettings.authentication) {
                this.selectedAuth = this.selectedSource.integrationSettings.authentication.type || 'NoAuth';
                this.selectedNewToken = this.selectedSource.integrationSettings.authentication.grantType || '';
                const accessTokenData = this.getTheCurrentTokenStructure(this.selectedNewToken);
                if (accessTokenData && accessTokenData.length > 0) {
                    accessTokenData.filter((token) => {
                        token.value = this.selectedSource.integrationSettings.authentication[token.name] || '';
                    });
                }
            }
        }
    };

    private formEntityOptions = (source, selectEntity?) => {
        let entityData: any;
        entityData = this.dashboardSetting.formEntityOptions(source, selectEntity);
        const value =
            source?.selectedEntity ||
            (typeof entityData?.selectedEntity === 'string' ? entityData?.selectedEntity : entityData?.selectedEntity?.key);
        if (entityData && Object.keys(entityData).length > 0) {
            this.entityArrayOptions = entityData.entityArrayOptions;
        } else {
            this.searchQueryFound = false;
        }
        if (value === 'defaultEntity') {
            this.searchQueryFound = false;
        }
        this.entityChanged({ value }, selectEntity);
    };

    public webServiceSourceChanged = (event) => {
        const index = this.webServicesSourceSettings.findIndex((item) => item.sourceName === event.value.sourceName);
        this.sourceChanged(this.webServicesSourceSettings[index], index);
    };

    public sourceChanged = (event, index: number) => {
        this.checkSourceSettingOptions(index, event);
        this.formEntityOptions(event, event.selectedEntity ? (event.searchQuery ? true : event.selectedEntity) : false);
    };

    public entityChanged = (event, selectedEntity?: Entity) => {
        const value = event?.value;
        this.selectedSource.selectedEntity = value;
        if (value !== 'defaultEntity') {
            this.getAssetsettingData(value, selectedEntity);
        } else {
            this.searchQueryFound = false;
            this.isReferedCriteria = false;
        }
    };

    private getAssetsettingData = (value, selectedEntity) => {
        this.searchQueryFound = false;
        this.loadingSearchCriteria = true;
        let searchQueryData: any;
        const queryData = {
            key: value,
            selectedSource: this.selectedSource,
            sourceVsSetting: this.sourceVsSetting,
            sourceArrayOptions: this.sourceArrayOptions,
        };
        searchQueryData = this.dashboardSetting.getAssetsettingData(queryData, selectedEntity);
        if (searchQueryData && Object.keys(searchQueryData).length > 0 && searchQueryData.type !== 'ReferredCriteriaDefinition') {
            this.searchConfig['fields'] = searchQueryData.searchConfig;
            this.selectedSource.searchQuery = searchQueryData.searchQuery;
            this.selectedSource.fields = searchQueryData.searchConfig;
            setTimeout(() => {
                this.loadingSearchCriteria = false;
                this.searchQueryFound = true;
                this.isReferedCriteria = false;
            }, 1000);
        } else if (searchQueryData.type && searchQueryData.type === 'ReferredCriteriaDefinition') {
            this.ReferredCriteriaEntity = searchQueryData;
            setTimeout(() => {
                this.loadingSearchCriteria = false;
                this.isReferedCriteria = true;
            }, 1000);
        } else {
            this.loadingSearchCriteria = false;
            this.searchQueryFound = false;
            this.isReferedCriteria = false;
        }
    };

    public saveWidgetSettings = () => {
        if (this.initiateElasticSearch) {
            const queryData = {
                searchQuery: this.selectedSource.searchQuery,
                selectedSource: this.selectedSource,
            };
            this.searchQueryChangeListener(queryData, this.sourceArrayOptions);
        } else {
            const webServiceData = {
                source: this.formSourceData(),
            };
            this.webServiceChangeListener(webServiceData, true);
        }
    };

    private formSourceData = () => {
        const authentication: any = {};
        const bodyData: any = {};
        if (this.selectedAuth && this.selectedAuth !== 'NoAuth') {
            authentication.type = this.selectedAuth;
            if (this.selectedNewToken !== '') {
                authentication.grantType = this.selectedNewToken;
                const accessTokenData = this.getTheCurrentTokenStructure(this.selectedNewToken);
                if (accessTokenData && accessTokenData.length > 0) {
                    accessTokenData.filter((token) => {
                        authentication[token.id] = token.value;
                    });
                }
            }
        }
        if (this.selectedBodyType && this.selectedBodyType !== '') {
            bodyData.bodyType = this.selectedBodyType;
            bodyData.body = this.bodyContent;
        }
        if (authentication && Object.keys(authentication).length > 0) {
            this.selectedSource.integrationSettings['authentication'] = authentication;
        }
        if (bodyData && Object.keys(bodyData).length > 0) {
            this.selectedSource.integrationSettings['body'] = bodyData;
        }
        return this.selectedSource;
    };

    private getTheCurrentTokenStructure = (token) => {
        let accessTokenData = [];
        switch (token) {
            case 'Authorization Code':
                accessTokenData = this.authorizationCodeData;
                break;
            case 'Implicit':
                accessTokenData = this.implicitData;
                break;
            case 'Password Credentials':
                accessTokenData = this.passwordCredentialData;
                break;
            case 'Client Credentials':
                accessTokenData = this.clientCredentialData;
                break;
        }
        return accessTokenData;
    };

    public isHeadersPresent = () => {
        if (
            this.selectedSource &&
            this.selectedSource.integrationSettings &&
            this.selectedSource.integrationSettings.headers &&
            this.selectedSource.integrationSettings.headers.length > 0
        ) {
            this.selectedSourceHeaders = this.selectedSource.integrationSettings.headers;
            return true;
        }
        return false;
    };

    public isQueryPresent = () => {
        if (
            this.selectedSource &&
            this.selectedSource.integrationSettings &&
            this.selectedSource.integrationSettings.queryParams &&
            this.selectedSource.integrationSettings.queryParams.length > 0
        ) {
            this.selectedSourceParams = this.selectedSource.integrationSettings.queryParams;
            return true;
        }
        return false;
    };

    public updateValue = (obj) => {
        if (obj && obj.values) {
            obj.values = [obj.values];
        }
    };

    public updateNewTokens = () => {
        if (this.selectedNewToken && this.selectedNewToken !== '') {
            switch (this.selectedNewToken) {
                case 'Password Credentials':
                    this.selectedNewTokenTokenRef = this.passwordCredential;
                    break;
                case 'Authorization Code':
                    this.selectedNewTokenTokenRef = this.authorizationCode;
                    break;
                case 'Implicit':
                    this.selectedNewTokenTokenRef = this.implicit;
                    break;
                case 'Client Credentials':
                    this.selectedNewTokenTokenRef = this.clientCredential;
                    break;
            }
        }
        if (this.selectedNewTokenTokenRef) {
            return true;
        }
        return false;
    };

    public applySelectedFilter = () => {
        if (this.selectedOutlookFilter === 'validationStatus') {
            if (!this.validationFilter) {
                this._libUtils.alertError(this.translateMsg('Please select status'));
                return;
            }
            this.onValidationFilterChange();
        } else if (this.selectedOutlookFilter === 'searchFilters') {
            if (!this.defaultFilter) {
                this._libUtils.alertError(this.translateMsg('Please select filter'));
                return;
            }
            this.applySearchFilter(this.defaultFilter);
        }
    };

    private onValidationFilterChange = () => {
        const query = {
            condition: 'and',
            rules: [
                {
                    entity: undefined,
                    field: 'validationStatus',
                    operator: 'default',
                    value: this.validationFilter,
                },
            ],
        };
        const data: {
            searchCriteria?: any;
            searchQuery?: any;
            sortQuery?: any;
            searchConfig?: any;
            pageIndex?: any;
            size?: any;
            dontGetCount?: boolean;
        } = {
            searchCriteria: undefined,
            searchQuery: query,
            sortQuery: undefined,
            searchConfig: this.searchConfig,
            size: this.size,
        };
        let preparedCriteria = this._esService.searchMasterEntities(data);
        this.getSearchedMasterRecords(preparedCriteria, data);
    };

    private applySearchFilter = (filterData) => {
        this.searchQuery = undefined;
        this.sortQuery = undefined;
        this.selectedFilterName = undefined;
        const data: {
            searchCriteria?: any;
            searchQuery?: any;
            sortQuery?: any;
            searchConfig?: any;
            pageIndex?: any;
            size?: any;
            dontGetCount?: boolean;
        } = {
            searchCriteria: filterData.criteria,
            searchConfig: this.searchConfig,
        };
        let preparedCriteria = this._esService.searchMasterEntities(data);
        this.getSearchedMasterRecords(preparedCriteria, data);
    };

    private getSearchedMasterRecords = (criteria: any, data: any) => {
        const rootOrganizationId = this._commonUtils.getFromStorage('rootOrganizationId');
        this.store$.dispatch(
            SetMasterRecordPagination({
                pageSize: data?.size || 10,
                pageIndex: data?.pageIndex || 0,
                pageState: undefined,
                organizationId: this.currentOrganizationId,
                restApiName: this.masterRestApiName,
                providerOrgId: rootOrganizationId,
                search: {
                    queryString: criteria?.queryString,
                    sortQuery: criteria?.sortQueryString,
                },
            })
        );
        this.resetPagination();
        this.store$.dispatch(
            SetMasterRecords({
                records: [],
                organizationId: this.currentOrganizationId,
                restApiName: this.masterRestApiName,
                isClearSearch: true,
            })
        );
        this.searchQueryChangeListener(this.copySearchQuery);
        this.sortQueryChangeListener(this.copySortQuery);
        this.updateSelectedMasterFilter(this.selectedOutlookFilter);
        this.updateValidationFilter(this.validationFilter);
        this.updateDefaultFilter(this.defaultFilter);
        this.store$.dispatch(GetSearchedMasterRecords());
        this.store$.dispatch(GetSearchedMasterRecordsCount());
        this.closeModal();
    };

    public deleteSearchFilter = (filter) => {
        const confirmConfig = {
            title: this.translateMsg('Delete Confirmation'),
            message: this.translateMsg('Are you sure you want to delete') + ': ' + filter.filterName,
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    this.store$.dispatch(DeleteSearchConfiguration({ id: filter.id }));
                }
                this.closeSearchFilterDropdown();
            });
    };

    private closeSearchFilterDropdown = () => {
        this._matFilterSelect && this._matFilterSelect.close();
    };

    public clearMasterSearch = () => {
        this.closeModal();
        this.clearSearch();
    };

    public valueChanged = (
        event: any,
        auth: {
            name: string;
            id: string;
            value: string;
            selectBox: boolean;
        }
    ) => {
        auth.value = event;
    };

    private initiateSubscriptions = () => {
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((orgId) => {
                this.currentOrganizationId = orgId;
            });
        this.store$
            .select(getSelectedRestApiName$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((masterName) => {
                this.masterRestApiName = masterName;
            });
        this.actions$
            .pipe(
                ofType(SavedSearchFilter),
                takeUntil(this.unSubscribe),
                filter((action) => action !== undefined)
            )
            .subscribe((res) => {
                const filter = res.filterObject;
                this.defaultFilter = filter;
                this.saveDialogRef?.close();
                this.closeModal();
                this._libUtils.alertSuccess(this.translateMsg(`Filter ${this.selectedFilterName ? 'Updated' : 'Saved'} Successfully`));
                this.searchMasterEntities(filter?.critera);
                if (!this.R.masterSearchValidRules) {
                    this._taxilla.instances.deleteSearchConfiguration({ id: filter.id }).subscribe({
                        next: () => {
                            this.getCustomFilter &&
                                this.getCustomFilter((allFilters) => {
                                    allFilters?.forEach((filter) => {
                                        if (filter.filterName === filter.name) {
                                            this.updateDefaultFilter(filter);
                                        }
                                    });
                                });
                        },
                    });
                } else {
                    this.getCustomFilter &&
                        this.getCustomFilter((allFilters) => {
                            allFilters?.forEach((filter) => {
                                if (filter.filterName === filter.name) {
                                    this.updateDefaultFilter(filter);
                                }
                            });
                        });
                }
            });
        this.store$
            .select(getSearchFilters$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.searchFilters = res;
            });
    };

    ngOnInit() {
        this.initiateSubscriptions();
        this.checkSourceSettingOptions(0, undefined);
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    translateMsg = (msg: string): string => translate(msg);
}
