<span [ngSwitch]="field.uiType">
    <span *ngSwitchCase="'TEXTFIELD'">
        <ng-container [ngSwitch]="field.dataType">
            <ng-container *ngSwitchCase="'DATE'">
                <material-input-date [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" [debounceAfter]="1000" [required]="field.mandatory" [dateFormat]="field.format" [inputOnlyDisabled]="true" [errors]="field.message.errors" (modelChange)="field.value = $event">
                </material-input-date>
            </ng-container>
            <ng-container *ngSwitchCase="'LONG'">
                <material-input [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" [debounceAfter]="1000" [required]="field.mandatory" [errors]="field.message.errors" (modelChange)="field.value = $event">
                </material-input>
            </ng-container>
            <ng-container *ngSwitchCase="'INT'">
                <material-input [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" [debounceAfter]="1000" [required]="field.mandatory" [errors]="field.message.errors" (modelChange)="field.value = $event">
                </material-input>
            </ng-container>
            <ng-container *ngSwitchCase="'BOOLEAN'">
                <div class="row booleanFieldContainer">
                    <div class="col-6 booleanLabel">
                        <span>{{field.displayName || field.name}} : </span>
                    </div>
                    <div class="col-6 booleanValues">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="field.value" [name]="field.displayName || field.name">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <material-input [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" [debounceAfter]="1000" (modelChange)="field.value = $event" [errors]="field.message.errors" [required]="field.mandatory">
                </material-input>
            </ng-container>
        </ng-container>
    </span>
    <span *ngSwitchCase="'DROPDOWN'">
        <material-select [options]="field.options" [placeholder]="field.displayName || field.name" [name]="field.displayName || field.name" [title]="field.displayName || field.name" [required]="field.mandatory" [model]="field.value" [errors]="field.message.errors" (modelChange)="field.value = $event"></material-select>
    </span>
    <span *ngSwitchCase="'DATE'">
        <material-input-date [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" [debounceAfter]="1000" [required]="field.mandatory" [dateFormat]="field.format" [errors]="field.message.errors" [inputOnlyDisabled]="true" (modelChange)="field.value = $event"> </material-input-date>
    </span>
    <span *ngSwitchCase="'TEXTAREA'">
        <material-textarea [placeholder]="field.displayName || field.name" [errors]="field.message.errors" [title]="field.displayName || field.name" [model]="field.value" (modelChange)="field.value = $event" [required]="field.mandatory">
        </material-textarea>
    </span>
    <span *ngSwitchCase="'RADIOBUTTON'">
        <ng-container [ngSwitch]="field.options.length > 2">
            <ng-container *ngSwitchCase="false">
                <div class="row booleanFieldContainer">
                    <div class="col-xs-4 booleanLabel">
                        <span>{{field.displayName || field.name}} : </span>
                    </div>
                    <div class="col-xs-8 booleanValues">
                        <mat-radio-button class="inputRadioButton margin5px" *ngFor="let option of field.options; trackBy: trackByOptionsMethod" [value]="option" (change)="radioValueChanged(field, $event);">
                            {{option}}
                        </mat-radio-button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <material-select [options]="field.options" [placeholder]="field.displayName || field.name" [errors]="field.message.errors" [warnings]="field.message.warnings" [name]="field.displayName || field.name" [title]="field.displayName || field.name" [required]="field.mandatory" [model]="field.value" (modelChange)="field.value = $event"></material-select>
            </ng-container>
        </ng-container>
    </span>
    <span *ngSwitchCase="'CHECKBOX'">
        <material-group-checkbox [menuName]="field.displayName || field.name" [options]="field.options" [value]="field.value" [errors]="field.message.errors" [required]="field.mandatory" [warnings]="field.message.warnings" (onOptionsChanged)="field.value = $event" [currentApp]="app" [currentBridge]="bridge"></material-group-checkbox>
    </span>
    <span *ngSwitchCase="'PASSWORD'">
        <material-input-password [errors]="field.message.errors" [placeholder]="field.displayName || field.name" [title]="field.displayName || field.name" [model]="field.value" (modelChange)="field.value = $event" [required]="field.mandatory">
        </material-input-password>
    </span>
    <span *ngSwitchDefault>
        {{field.uiType}}
        <!-- {{field | json}} -->
    </span>
</span>
