import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { delay, filter, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import {
    ApiService,
    CommonUtilsService,
    ConfirmationDialogService,
    Get4ECPermission,
    getAnalyticsDashboards$,
    GetApplicationProperties,
    getApplicationProperties$,
    getCurrentOrganization$,
    getCurrentOrganizationId$,
    getDashboardLoader$,
    GetDashboards,
    GetMasters,
    getMasters$,
    GetWorkQCount,
    getWorkQCount$,
    isProviderOrganizationAndAdmin$,
    Organization,
    SetDashboards,
    SettingsMenuInterface,
    UiSchemaService,
    UtilsService,
} from 'taxilla-library';

import { LocationService } from './../../services/location.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('matChartsMenuTrigger') matChartsMenuTrigger: MatMenuTrigger;

    @Input() loading: { [property: string]: boolean } = {};

    public onNewUI = this._commonUtils.getCookie('new-ui') === 'true';
    public currentOrganization: Organization;
    public isInGuestMode: boolean;
    public showWorkQ: boolean;
    public canShowWorkQ = !environment.restrictedFeatures?.workQ;
    public totalWorkQCount: number;
    public loggedInOrgName: any;
    public loggedInOrgId: any;
    public lastLogin: any;
    public canShowAnalytics = environment.canShowAnalytics;
    public insights: any[] = [];
    public unSubscribe = new Subject<void>();
    public currentOrg$: Observable<Organization>;
    public isSettingsSelected: boolean;
    public settings: SettingsMenuInterface = {
        name: 'settingsMenu',
        children: [],
        hasChildren: true,
    };
    private has4ECAccess = false;
    public currentOrganizationId: string;
    private mastersSubscription: Subscription;
    public isDashboardsLoading: boolean = false;
    private isProviderAdminAndOrganization: boolean;

    constructor(
        private store$: Store,
        public _utils: UtilsService,
        private _confirmation: ConfirmationDialogService,
        public _commonUtils: CommonUtilsService,
        public _schema: UiSchemaService,
        private _api: ApiService,
        private locationService: LocationService,
        private _broadcaster: BroadcasterService,
        private _router: Router,
        private actions$: Actions
    ) {
        if (environment.hideNewUISkin) {
            this.switchToNewUI();
        }
    }

    public getDashboardCharts = () => {
        if (this._commonUtils.analyticsServiceTypes?.split(',').includes('DASHBOARD')) {
            (!this.insights || this.insights.length === 0) && this.store$.dispatch(GetDashboards({ noAlerts: true }));
        } else if (this._commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) {
            this.matChartsMenuTrigger?.closeMenu();
            this._router.navigate(['organizations', this.currentOrganizationId, 'dashboard']);
        }
    };

    public initiateSubscriptions = () => {
        this.store$
            .select(getApplicationProperties$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe((data) => {
                if (data?.['analytics.service.types']?.length > 0) {
                    this._commonUtils.analyticsServiceTypes = data['analytics.service.types'];
                }
            });
        this.store$
            .select(isProviderOrganizationAndAdmin$)
            ?.pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.isProviderAdminAndOrganization = event;
            });
        this.store$
            .select(getCurrentOrganization$)
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((org) => {
                this.currentOrganization = org;
                this.getCountOfActiveMessages();
            });
        this.store$
            .select(getWorkQCount$)
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((count: any) => {
                this.totalWorkQCount = count;
            });
        this.store$
            .select(getAnalyticsDashboards$)
            ?.pipe(takeUntil(this.unSubscribe))
            .subscribe((data) => {
                this.insights = data || [];
            });
        this.actions$
            .pipe(
                ofType(SetDashboards),
                filter((data) => data !== undefined),
                takeUntil(this.unSubscribe)
            )
            ?.subscribe((res) => {
                const insights = res.analyticDashboards;
                if (insights.length === 0 && this._commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) {
                    this.matChartsMenuTrigger?.closeMenu();
                    this._router.navigate(['organizations', this.currentOrganizationId, 'dashboard']);
                }
            });
        this.store$
            .select(getDashboardLoader$)
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe((data) => {
                this.isDashboardsLoading = data;
            });
        this.currentOrg$ = this.store$.select(getCurrentOrganization$)?.pipe(takeUntil(this.unSubscribe));
        this.store$
            .select(getCurrentOrganizationId$)
            ?.pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.currentOrganizationId = event;
                this.generateSettingsMenu();
            });
        this._router.events
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                const origin = window.location.origin;
                this.isSettingsSelected = window.location.href.indexOf(origin + '/settings') > -1;
                this.markSelectedSetting(this.settings.children);
            });

        this._broadcaster
            .on('switchedOrganization')
            ?.pipe(takeUntil(this.unSubscribe), delay(10))
            .subscribe(() => {
                this.generateSettingsMenu();
                this.startComponent();
                this.store$.dispatch(Get4ECPermission());
            });
    };

    public openInsights = () => {
        this._schema.setSchemaProperty('enComply', 'showAnalytics', true);
    };

    public startComponent = async () => {
        this.getApplicationProperties();
        const loggedInOrganization = this._commonUtils.getFromStorage('loggedinorganization');
        const lastLoggedInOrgTime = this._commonUtils.getFromStorage('lastlogintime');
        this.loggedInOrgName = loggedInOrganization && loggedInOrganization.name;
        this.loggedInOrgId = loggedInOrganization && loggedInOrganization.orgLoginId;
        this.lastLogin = lastLoggedInOrgTime;
    };

    /**
     * Method to get Application properties
     */
    private getApplicationProperties = () => {
        this.store$.dispatch(GetApplicationProperties());
    };

    showOrganizations = () => {
        this._router.navigate(['/organizations/list']);
    };

    getCountOfActiveMessages = () => {
        this.canShowWorkQ && this.store$.dispatch(GetWorkQCount());
    };

    navigateWorkQ = () => {
        this.confirmNavigation(() => {
            this._utils.navigateToWorkQPage();
        });
    };

    confirmNavigation = (callback: () => void) => {
        const isInNewPage = this._utils.isInNewProcessPage();
        const isInProcessPage = this._utils.isInProcessPage();
        if (isInNewPage) {
            this.openConfirmationToNavigate('Switch Application', 'Do you wish to navigate away from new process page?', callback);
        } else if (isInProcessPage) {
            this.openConfirmationToNavigate('Switch Application', 'Do you wish to navigate away from current process page?', callback);
        } else {
            callback();
        }
    };

    openConfirmationToNavigate = (title: string, message: string, callback: () => void) => {
        this._confirmation.confirm(title, message, 'Switch', 'Cancel').subscribe((action) => {
            if (action) {
                callback();
            }
        });
    };

    getGuestUrl = () => environment['encomply-ui'] + '/collaboration';

    private generateSettingsMenu = async () => {
        const userPermissions: any = await this._api.permissions.getOrganizationRelatedPermissions();
        const permissions = userPermissions?.CUSTOMER?.CUSTOMER?.permissions;
        const subscriptionPermissions = userPermissions?.PROVIDER?.PROVIDER?.permissions?.SUBSCRIPTION_APPROVAL;
        const isAdmin = this._commonUtils.getFromStorage('isAdmin');
        const baseRoute = `organizations/${this.currentOrganizationId}`;
        const settingsBaseRoute = `${baseRoute}/settings`;
        this.settings.children = [];
        const integrationRestriction = environment?.restrictedFeatures?.integrations;
        if (userPermissions && permissions) {
            if (permissions.ORG_DETAILS) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Organization Management',
                    icon: 'account_balance',
                    route: `${settingsBaseRoute}/manageOrganization`,
                    hasChildren: false,
                });
            }
            if (permissions.CREATE_USER) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'User Management',
                    icon: 'person',
                    route: `${settingsBaseRoute}/users`,
                    hasChildren: false,
                });
            }
            if (permissions.CREATE_ROLE) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Roles & Permissions',
                    icon: 'assignment',
                    route: `${settingsBaseRoute}/roles`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_LOCATION) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Locations',
                    icon: 'location_on',
                    route: `${settingsBaseRoute}/locations`,
                    hasChildren: false,
                });
            }
            if (permissions.PROCESS_MASTERS) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Master Data Management',
                    icon: 'blur_linear',
                    route: `${settingsBaseRoute}/masterManagement`,
                    hasChildren: true,
                    loadChildren: this.loadMasters,
                });
            }
            if (this.isProviderAdminAndOrganization) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Authorization Systems',
                    icon: 'security_key',
                    route: `${settingsBaseRoute}/authorization-systems`,
                    hasChildren: false,
                });
            }
            if (permissions.INTEGRATION_SETTINGS && (!integrationRestriction || integrationRestriction.length > 0)) {
                const parent = {
                    id: this.settings.children.length,
                    name: 'Integrations',
                    icon: 'settings_input_antenna',
                    route: `${settingsBaseRoute}/integrations`,
                    hasChildren: true,
                    children: [],
                };
                ['FTP', 'WEBSERVICE', 'SAMBASHARE', 'S3', 'RESTAPI', 'EMAIL']
                    .filter((item) => !integrationRestriction || integrationRestriction.indexOf(item.toLowerCase()) === -1)
                    .forEach((child) => {
                        parent.children.push({
                            id: parent.children.length,
                            name: child,
                            route: `${parent.route}/${child.toLowerCase()}`,
                            hasChildren: false,
                            children: [],
                        });
                    });
                this.settings.children.push(parent);
            }
            if (permissions.MANAGE_CLIENT) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Access API',
                    icon: 'language',
                    route: `${settingsBaseRoute}/apiaccess`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_CLIENT) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Active Tokens',
                    icon: 'vpn_key',
                    route: `${settingsBaseRoute}/active-tokens`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_PARTNERS && this.currentOrganization?.organizationTypes !== 'PROVIDER') {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Partner Management',
                    icon: 'thumb_up',
                    route: `${settingsBaseRoute}/partners`,
                    hasChildren: false,
                });
            }
            if (permissions.RESET_PASS_APPROVAL) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Reset User Password',
                    icon: 'lock_open',
                    route: `${settingsBaseRoute}/resetpasswordapproval`,
                    hasChildren: false,
                });
            }
            if (subscriptionPermissions) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Subscription Approval',
                    icon: 'assignment_turned_in',
                    route: `${settingsBaseRoute}/subscriptionapproval`,
                    hasChildren: false,
                });
            }
            this.settings.children.push({
                id: this.settings.children.length,
                name: 'Notifications',
                icon: 'notifications',
                route: `${settingsBaseRoute}/notification`,
                hasChildren: false,
            });
            if (isAdmin) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Index Data',
                    icon: 'view_list',
                    route: `${settingsBaseRoute}/indexdata`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_SUBSCRIPTION) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Manage Apps',
                    icon: 'apps',
                    route: `${baseRoute}/manageapps`,
                    hasChildren: true,
                    children: [
                        {
                            id: 0,
                            icon: 'widgets',
                            name: 'My Apps',
                            route: `${baseRoute}/manageapps/approved`,
                            hasChildren: false,
                        },
                        {
                            id: 1,
                            icon: 'timer',
                            name: 'Apps Pending Approval',
                            route: `${baseRoute}/manageapps/pending`,
                            hasChildren: false,
                        },
                        {
                            id: 2,
                            icon: 'block',
                            name: 'Deactivated Apps',
                            route: `${baseRoute}/manageapps/deactivated`,
                            hasChildren: false,
                        },
                        {
                            id: 3,
                            icon: 'apps',
                            name: 'All Apps',
                            route: `${baseRoute}/manageapps/all`,
                            hasChildren: false,
                        },
                        {
                            id: 4,
                            icon: 'layers',
                            name: 'Bundles',
                            route: `${baseRoute}/manageapps/bundles`,
                            hasChildren: false,
                        },
                    ],
                });
            }
            if (isAdmin) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Event Subscriptions',
                    icon: 'language',
                    route: `${settingsBaseRoute}/eventsubscription`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_IDENTITY_PROVIDER) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Identity Providers',
                    icon: 'assignment_ind',
                    route: `${settingsBaseRoute}/identityproviders`,
                    hasChildren: false,
                });
            }
            this.initiate4EC();
            this.markSelectedSetting(this.settings.children);
        }
    };

    private loadMasters = async (menu: SettingsMenuInterface) => {
        this.store$.dispatch(GetMasters());
        return new Promise<void>((resolve) => {
            this.mastersSubscription?.unsubscribe();
            this.mastersSubscription = this.store$
                .select(getMasters$)
                .pipe(
                    takeUntil(this.unSubscribe),
                    filter((data) => data !== undefined)
                )
                .subscribe((response) => {
                    menu.children = [];
                    response?.forEach((key) => {
                        menu.children.push({
                            children: [],
                            hasChildren: false,
                            icon: undefined,
                            id: menu.children.length,
                            loadChildren: undefined,
                            name: key.displayName || key.itemName,
                            route: `${menu.route}/${key}/${key.restApiServiceName}`,
                            selected: false,
                            noTranslation: true,
                        });
                    });
                    this.markSelectedSetting(menu.children);
                    resolve();
                });
        });
    };

    private initiate4EC = () => {
        if (!this.has4ECAccess) {
            return;
        }
        const baseRoute = `organizations/${this.currentOrganizationId}`;
        const settingsBaseRoute = `${baseRoute}/settings`;
        this.settings.children.push({
            id: this.settings.children.length,
            name: '4EC Approval',
            icon: 'view_list',
            route: `${settingsBaseRoute}/four-eye-check`,
            hasChildren: false,
        });
    };

    private markSelectedSetting = (list: SettingsMenuInterface[]) => {
        const href = location.href;
        list.forEach((item) => {
            item.selected = href.indexOf(item.route) > -1;
            item.children?.length > 0 && this.markSelectedSetting(item.children);
        });
    };

    public switchToNewUI = () => {
        this._commonUtils.setCookie('new-ui', '' + !this.onNewUI);
        location.reload();
    };

    ngOnInit(): void {
        const guestUrl = this.getGuestUrl();
        const currentUrl = this.locationService.href;
        this.isInGuestMode = currentUrl.indexOf(guestUrl) > -1;
        this.initiateSubscriptions();
        this.startComponent();
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
