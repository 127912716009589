<div *transloco="let translate;">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{asset?.displayName || asset?.itemName}}</h4>
        <span class="material-icons mb-1" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody subscribeAssetModal">
        <div class="fetchAssetModal" *ngIf='allSubAppsArr.length > 0'>
            <perfect-scrollbar style="height: 40vh;">
                <div class="appCardsContainer">
                    <div class="cardsContainer">
                        <ng-container *ngFor="let subApp of allSubAppsArr | AlphabeticalOrder: ('displayName' || 'serviceName' || 'name'); let i=index; trackBy: trackById">
                            <div class="cardsWrapper">
                                <material-card [fromComponent]="'assetModelApps_app'" [showAppCheckBox]="false" [asset]="subApp" [bridge]="asset" (getAssetDescription)="getAssetDescription($event) " (getHelpDocument)="getHelpDocument($event)" (downloadHelpDocument)="downloadHelpDocument($event)" (viewHelpDocument)="viewHelpDocument($event)" (closeModal)="closeModal()"></material-card>
                            </div>
                            <ng-container *ngIf="subApp.reports?.length > 0">
                                <div class="cardsWrapper" *ngFor="let report of subApp.reports | AlphabeticalOrder: ('displayName' || 'serviceName' || 'name'); let i=index; trackBy: trackById">
                                    <material-card [fromComponent]="'assetModelApps__report'" [showAppCheckBox]="false" [asset]="subApp" [bridge]="asset" [report]="report" (getAssetDescription)="getAssetDescription($event) " [appServiceId]="appServiceId" (getHelpDocument)="getHelpDocument($event)" (downloadHelpDocument)="downloadHelpDocument($event)" (viewHelpDocument)="viewHelpDocument($event)" (closeModal)="closeModal()"></material-card>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </perfect-scrollbar>
            <div *ngIf='allSubAppsArr.length === 0' class="textAlignCenter">
                {{translate('No Apps Found')}}.
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter">
        <material-button class='noOutline' [label]="translate('Cancel')" color="default" (onTrigger)="closeModal()"></material-button>
    </mat-dialog-actions>
</div>
