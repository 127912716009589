import { Direction } from '@angular/cdk/bidi';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { filter, Subscription } from 'rxjs';

import { UtilsService } from '../../services/utils/utils.service';

import type { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
    @Input() enableEncryptDecrypt: boolean;
    @Input() placeholder: string;
    @Input() title: string;
    @Input() model: any;
    @Input() id: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() editable: boolean;
    @Input() errors: any[];
    @Input() maxLength: number;
    @Input() hint: string;
    @Input() textDirection?: Direction;

    @Output() modelChange = new EventEmitter();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;
    showToolbar: boolean = false;
    hideAllActions: any = [
        [
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'insertUnorderedList',
            'insertOrderedList',
            'heading',
            'fontName',
        ],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode',
        ],
    ];
    hideMinimumActions: any = [['fontName'], ['insertImage', 'insertVideo']];
    editorConfig: AngularEditorConfig = {
        editable: false,
        sanitize: false,
        toolbarHiddenButtons: [['fontName'], ['insertImage', 'insertVideo']],
    };
    dir: Direction = 'ltr';

    constructor(private _utils: UtilsService) {}

    modelChanged = (content: any) => {
        this.checkArabic();
        if (this.validateContent(content)) {
            this._utils.alertError('The content is malformed. <script> or <py-script> tags are not allowed.');
            return;
        }
        if (this.enableEncryptDecrypt) {
            this.modelChange.emit(this.enableEncryptDecrypt ? encodeURIComponent(content) : content);
        } else {
            this.modelChange.emit(content);
        }
    };

    validateContent = (content: any) => {
        return (
            content.includes('<script') ||
            content.includes('<py-script') ||
            content.includes('&lt;script') ||
            content.includes('&lt;py-script')
        );
    };

    showFormatToolbar = () => {
        this.showToolbar = !this.showToolbar;
        this.editorConfig.toolbarHiddenButtons = [];
        if (this.showToolbar) {
            this.editorConfig.toolbarHiddenButtons = this.hideMinimumActions;
        } else {
            this.editorConfig.toolbarHiddenButtons = this.hideAllActions;
        }
        let element = document.getElementsByClassName(this.id);
        if (element?.[0]) {
            let headers: HTMLCollectionOf<Element> = element[0].getElementsByClassName('select-heading');
            (headers[0] as any).style.display = this.showToolbar ? 'inline-block' : 'none';
            let fontSizes: HTMLCollectionOf<Element> = element[0].getElementsByClassName('select-font-size');
            (fontSizes[0] as any).style.display = this.showToolbar ? 'inline-block' : 'none';
        }
    };

    checkArabic = () => (this.dir = this.textDirection || (/[\u0600-\u06FF]/.test(this.model) ? 'rtl' : 'ltr'));

    ngOnInit() {
        this.editorConfig.editable = this.editable;
        this.editorConfig.showToolbar = this.editable;
        this.enableEncryptDecrypt && (this.editorConfig.toolbarHiddenButtons = this.hideAllActions);
        this.model?.length > 0 && this.control.setValue(this.model);
        this.valueSubscription = this.control.valueChanges
            .pipe(filter((data) => data !== undefined))
            .subscribe((_event) => this.modelChanged(_event));
    }

    ngAfterViewInit(): void {
        let buttons: any = document.getElementsByClassName('angular-editor-button');
        for (let button of buttons) {
            button?.title?.length > 0 && (button.title = translate(button.title));
        }
        this.checkArabic();
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
