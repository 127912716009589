import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { AnimationsModule } from '@animations/app.module';
import { CronEditorModule } from '@cron-builder';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { NgQueryBuilderModule } from 'ng-query-builder';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AddConfigurationModalComponent } from './components/addconfigurationmodal/addconfigurationmodal.component';
import { AddRecordsComponent } from './components/addrecords/addrecords.component';
import { AppCollaborationComponent } from './components/app-collaboration/app-collab.component';
import { AppDisplayNameComponent } from './components/app-display-name/app-display-name.component';
import { AssetlookupComponent } from './components/assetlookup/assetlookup.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ChangePasswordComponent } from './components/changepassword/changepassword.component';
import { ChartDataComponent } from './components/chart-data/chart-data.component';
import { EntitySearchFilter } from './components/common-entity-search-filter/entity-search-filter.component';
import { CommonMastersComponent } from './components/common-masters/common-masters.component';
import { CommonNotificationsEventlogsComponent } from './components/common-notifications-eventlogs/common-notifications-eventlogs.component';
import { CommonNotificationsComponent } from './components/common-notifications/common-notifications.component';
import { CommonTemplatesComponent } from './components/common-templates/common-templates.component';
import { CommonchangelogrecordComponent } from './components/commonchangelogrecord/commonchangelogrecord.component';
import { CommoncollaborationlistComponent } from './components/commoncollaborationlist/commoncollaborationlist.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConsolidateComponent } from './components/consolidate/consolidate.component';
import { CustomSearchComponent } from './components/custom-search/custom-search.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DigitalSignatureComponent } from './components/digital-signature/digital-signature.component';
import { DocPreviewComponent } from './components/doc-preview/doc-preview.component';
import { DotsLoaderComponent } from './components/dotsloader/dotsloader.component';
import { DowntimeActiveMessageComponent } from './components/downtime-active-message/downtime-active-message';
import { DurationComponent } from './components/duration/duration.component';
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { ElasticQueryBuilderComponent } from './components/elasticquerybuilder/elasticquerybuilder.component';
import { EntitiesTemplateComponent } from './components/entities-template/entities-template.component';
import { EntityRecordFieldAttachmentComponent } from './components/entity-record-field-attachment/entity-record-field-attachment.component';
import { EntityRecordFieldGridTableComponent } from './components/entity-record-field-grid-table/entity-record-field-grid-table.component';
import { EntityRecordFieldGridComponent } from './components/entity-record-field-grid/entity-record-field-grid.component';
import { EntityRecordFieldTemplateComponent } from './components/entity-record-field-template/entity-record-field-template.component';
import { EntityRecordTemplateComponent } from './components/entity-record-template/entity-record-template.component';
import { EntitySearchComponent } from './components/entity-search/entity-search.component';
import { EntityTemplateComponent } from './components/entity-template/entity-template.component';
import { EventsSubscriptionAdvancedEditComponent } from './components/events-subscription-advanced-edit/events-subscription-advanced-edit.component';
import { FetchAssetsModalComponent } from './components/fetch-assets-modal/fetch-assets-modal.component';
import { FieldInputTemplateComponent } from './components/field-input-template/field-input-template.component';
import { FieldLevelFileAttachmentComponent } from './components/field-level-file-attachment/field-level-file-attachment.component';
import { FilingAttributeFieldComponent } from './components/filingattributefield/filingattributefield.component';
import { GridFieldModalComponent } from './components/grid-field-modal/grid-field-modal.component';
import { GridFieldComponent } from './components/grid-field/grid-field.component';
import { HeaderShortcutsComponent } from './components/header-shortcuts/header-shortcuts.component';
import { HelpDocPreviewComponent } from './components/help-doc-preview/help-doc-preview.component';
import { HelpDocumentViewComponent } from './components/help-document-view/help-document-view.component';
import { HomeAppMatContainerComponent } from './components/home-app-mat-container/home-app-mat-container.component';
import { HomeAppProcessComponent } from './components/home-app-process/home-app-process.component';
import { HomePackageProcessComponent } from './components/home-package-process/home-package-process.component';
import { HomeProcessesComponent } from './components/home-processes/home-processes.component';
import { IconsComponent } from './components/icons/icons.component';
import { MaterialInputAutoCompleteComponent } from './components/inputautocomplete/inputautocomplete.component';
import { IntegrationFieldsComponent } from './components/integrationfields/integrationfields.component';
import { IntegrationsFieldTemplateComponent } from './components/integrations-field-template/integrations-field-template.component';
import { IntegrationsPostmanStructureComponent } from './components/integrations-postman-structure/integrations-postman-structure.component';
import { IntegrationsTemplateComponent } from './components/integrations-template/integrations-template.component';
import { LeftNavAppsTemplateComponent } from './components/left-nav-apps-template/left-nav-apps-template.component';
import { MasterChangeLogComponent } from './components/masterchangelogs/masterchangelog.component';
import { MasterlookupComponent } from './components/masterlookup/masterlookup.component';
import { MasterlookupinputComponent } from './components/masterlookupinput/masterlookupinput.component';
import { MasterRequestsNotificationsComponent } from './components/masterrequests/masterrequests.component';
import { MastersearchComponent } from './components/mastersearch/mastersearch.component';
import { MaterialAccordionComponent } from './components/material/accordion/accordion.component';
import { MaterialButtonComponent } from './components/material/button/button.component';
import { MaterialCardComponent } from './components/material/card/card.component';
import { MaterialCheckboxComponent } from './components/material/checkbox/checkbox.component';
import { MaterialFileUploadComponent } from './components/material/file-upload/file-upload.component';
import { MaterialInputComponent } from './components/material/input/input.component';
import {
    CustomDateFormat10Directive,
    CustomDateFormat11Directive,
    CustomDateFormat12Directive,
    CustomDateFormat13Directive,
    CustomDateFormat14Directive,
    CustomDateFormat15Directive,
    CustomDateFormat2Directive,
    CustomDateFormat3Directive,
    CustomDateFormat4Directive,
    CustomDateFormat5Directive,
    CustomDateFormat6Directive,
    CustomDateFormat7Directive,
    CustomDateFormat8Directive,
    CustomDateFormat9Directive,
    DefaultDateFormatDirective,
} from './components/material/inputdate/directives/customFormats/custom-date-formats.directive';
import { MaterialInputDateComponent } from './components/material/inputdate/inputdate.component';
import { MaterialInputPasswordComponent } from './components/material/inputpassword/inputpassword.component';
import { MaterialGroupCheckboxComponent } from './components/material/material-group-checkbox/material-group-checkbox.component';
import { MaterialPaginatorComponent } from './components/material/paginator/paginator.component';
import { MaterialSelectComponent } from './components/material/select/select.component';
import { MaterialSelectGroupComponent } from './components/material/selectgroup/selectgroup.component';
import { MaterialSlidetoggleComponent } from './components/material/slidetoggle/slidetoggle.component';
import { MaterialTableComponent } from './components/material/table/table.component';
import { MaterialTextAreaComponent } from './components/material/textarea/textarea.component';
import { TableVirtualScrollViewportComponent } from './components/material/virtual-scroll-wrapper/table-virtual-scroll-viewport.component';
import { TableVirtualScrollStrategy } from './components/material/virtual-table/table-vs-strategy.service';
import { VirtualTableComponent } from './components/material/virtual-table/virtual-table.component';
import { NewProcessComponent } from './components/new-process/new-process.component';
import { NewReconciliationProcessComponent } from './components/new-reconciliation-process/new-reconciliation-process.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ProcessLogComponent } from './components/process-log/process-log.component';
import { QueryBuilderNewComponent } from './components/query-builder-new/query-builder-new.component';
import { QuerybuilderComponent } from './components/query-builder/querybuilder.component';
import { RecordFieldsComponent } from './components/recordfields/recordfields.component';
import { ReportIconComponent } from './components/report-icon/report-icon.component';
import { ReportsViewComponent } from './components/reports-view/reports-view.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { SavecustomfilterComponent } from './components/savecustomfilter/savecustomfilter.component';
import { SaveReconTemplateComponent } from './components/saverecontemplate/save-recon-template.component';
import { SearchBuilderComponent } from './components/search-builder/search-builder.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { SessionCheckComponent } from './components/session-check/session-check.component';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';
import { SettingsNestedMenuComponent } from './components/settings-nested-menu/settings-nested-menu.component';
import { SourceTableComponent } from './components/sourcetable/sourcetable.component';
import { TableInputComponent } from './components/table-input/table-input.component';
import { TaxillaHeaderComponent } from './components/taxilla-header/taxilla-header.component';
import { TemplateListTableComponent } from './components/template-list-table/template-list-table.component';
import { TimeFiltersComponent } from './components/time-filters/time-filters.component';
import { UpdateRequestComponent } from './components/updateRequestModal/updaterequest.component';
import { UploadComponent } from './components/upload/upload.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { WorkflowFieldsInputComponent } from './components/workflow-fields-input/workflow-fields-input.component';
import { AppDataDirective } from './directives/app-data/app-data.directive';
import { AppDetectOutsideClickDirective } from './directives/app-detect-outside/app-detect-outside-click.directive';
import { AppNewProcessDirective } from './directives/app-new-process/app-new-process.directive';
import { AppProcessRequestDirective } from './directives/app-process-request/app-process-request.directive';
import { AppProcessesSelectionDirective } from './directives/app-processes/app-processes.directive';
import { AppSelectionDirective } from './directives/app-selection/app-selection.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { BacktopreviousDirective } from './directives/backtoprevious.directive';
import { DebounceDirective } from './directives/debounce';
import { DisableClipboardDirective } from './directives/disable-clipboard/disable-clipboard.directive';
import { EventPropagationDirective } from './directives/eventpropagation/eventpropagation.directive';
import { FileDragDetectorDirective } from './directives/file-drag-detector.directive';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { HubStorageDirective } from './directives/hub-storage/hubstorage.directive';
import { ResponseInterceptor } from './interceptors/responseinterceptor';
import { TokenInterceptor } from './interceptors/tokeninterceptor';
import { AlphabeticalOrderPipe } from './pipes/alphabetical-order/alphabetical-order.pipe';
import { FieldsGroupPipe } from './pipes/fields-group.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { MultiFilterPipe } from './pipes/multi-filter.pipe';
import { ObjNgFor } from './pipes/objectTransform.pipe';
import { OrderByPipe } from './pipes/order-by/order-by.pipe';
import { PascalToCamelPipe } from './pipes/pascal-to-camel.pipe';
import { PrimaryHeaderRecordFieldsPipe } from './pipes/primary-header-record-fields.pipe';
import { PrimarySubHeaderRecordFieldsPipe } from './pipes/primary-subheader-record-fields.pipe';
import { RemoveAriaOwnsDirective } from './pipes/remove-aria-owns.pipe';
import { ReportsFilterPipe } from './pipes/reports-filter.pipe';
import { SortassetsPipe } from './pipes/sortassets.pipe';
import { TruncateHtmlTagsPipe } from './pipes/truncate-html-tags.pipe';
import { FileUpload } from './services/file-upload/fileuploadservice';
import { NewProcessService } from './services/new-process/new-process.service';
import { UtilsService } from './services/utils/utils.service';
import { TranslateRootModule } from './translate-root.module';

import type { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const materialModules = [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatLegacyDialogModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatStepperModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatListModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatTreeModule,
    MatFormFieldModule,
    A11yModule,
    DragDropModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    ScrollingModule,
    DragDropModule,
];

const angularModules = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, AnimationsModule];

const necessaryModules = [
    AngularEditorModule,
    PerfectScrollbarModule,
    NgxMaterialTimepickerModule,
    NgQueryBuilderModule,
    TranslateRootModule,
    CronEditorModule,
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [HttpClientModule, CommonModule, RouterModule, ...angularModules, ...materialModules, ...necessaryModules],
    declarations: [
        TaxillaHeaderComponent,
        EntitySearchFilter,
        SessionCheckComponent,
        AlphabeticalOrderPipe,
        FieldsGroupPipe,
        FilterPipe,
        RemoveAriaOwnsDirective,
        ReportsFilterPipe,
        PrimaryHeaderRecordFieldsPipe,
        PrimarySubHeaderRecordFieldsPipe,
        HubStorageDirective,
        AppSelectionDirective,
        AppProcessesSelectionDirective,
        AppNewProcessDirective,
        AutofocusDirective,
        AppProcessRequestDirective,
        AppDataDirective,
        AppDetectOutsideClickDirective,
        OrderByPipe,
        SessionExpiredModalComponent,
        MaterialButtonComponent,
        MaterialCardComponent,
        ObjNgFor,
        HomeProcessesComponent,
        HomePackageProcessComponent,
        HomeAppProcessComponent,
        HomeAppMatContainerComponent,
        MaterialAccordionComponent,
        MaterialInputAutoCompleteComponent,
        NewProcessComponent,
        MaterialTextAreaComponent,
        MaterialTableComponent,
        MaterialSelectGroupComponent,
        MaterialSelectComponent,
        MaterialPaginatorComponent,
        MaterialGroupCheckboxComponent,
        MaterialInputPasswordComponent,
        MaterialInputDateComponent,
        MaterialInputComponent,
        MaterialFileUploadComponent,
        MaterialSlidetoggleComponent,
        MaterialCheckboxComponent,
        DebounceDirective,
        TableInputComponent,
        UploadComponent,
        FilingAttributeFieldComponent,
        AssetlookupComponent,
        SourceTableComponent,
        UpdateRequestComponent,
        FieldInputTemplateComponent,
        GridFieldComponent,
        FieldLevelFileAttachmentComponent,
        MasterlookupComponent,
        MasterlookupinputComponent,
        DefaultDateFormatDirective,
        CustomDateFormat2Directive,
        CustomDateFormat3Directive,
        CustomDateFormat4Directive,
        CustomDateFormat5Directive,
        CustomDateFormat6Directive,
        CustomDateFormat7Directive,
        CustomDateFormat8Directive,
        CustomDateFormat9Directive,
        CustomDateFormat10Directive,
        CustomDateFormat11Directive,
        CustomDateFormat12Directive,
        CustomDateFormat13Directive,
        CustomDateFormat14Directive,
        CustomDateFormat15Directive,
        FileDragDetectorDirective,
        FileDragDropDirective,
        EventPropagationDirective,
        EditprofileComponent,
        ChangePasswordComponent,
        ConfirmationComponent,
        ReportsViewComponent,
        ReportIconComponent,
        IconsComponent,
        CommonNotificationsComponent,
        CommonTemplatesComponent,
        GridFieldModalComponent,
        RichTextEditorComponent,
        DateFilterComponent,
        DowntimeActiveMessageComponent,
        DurationComponent,
        CommoncollaborationlistComponent,
        SettingsNestedMenuComponent,
        RecordFieldsComponent,
        HeaderShortcutsComponent,
        CommonMastersComponent,
        IntegrationsFieldTemplateComponent,
        IntegrationsTemplateComponent,
        AddConfigurationModalComponent,
        IntegrationsPostmanStructureComponent,
        MastersearchComponent,
        SavecustomfilterComponent,
        SaveReconTemplateComponent,
        TemplateListTableComponent,
        ElasticQueryBuilderComponent,
        MasterRequestsNotificationsComponent,
        CommonchangelogrecordComponent,
        CommonNotificationsEventlogsComponent,
        IntegrationFieldsComponent,
        MasterChangeLogComponent,
        AppCollaborationComponent,
        AddRecordsComponent,
        EntitiesTemplateComponent,
        EntityTemplateComponent,
        EntityRecordTemplateComponent,
        ChartDataComponent,
        EntitySearchComponent,
        SearchBuilderComponent,
        QuerybuilderComponent,
        QueryBuilderNewComponent,
        DotsLoaderComponent,
        SortassetsPipe,
        MultiFilterPipe,
        ConsolidateComponent,
        VirtualTableComponent,
        TableVirtualScrollViewportComponent,
        NewReconciliationProcessComponent,
        CustomSearchComponent,
        NotificationsComponent,
        TimeFiltersComponent,
        BacktopreviousDirective,
        PascalToCamelPipe,
        TruncateHtmlTagsPipe,
        WorkflowFieldsInputComponent,
        DigitalSignatureComponent,
        DocPreviewComponent,
        UserDetailsComponent,
        AppDisplayNameComponent,
        ProcessLogComponent,
        EntityRecordFieldTemplateComponent,
        EntityRecordFieldAttachmentComponent,
        EntityRecordFieldGridComponent,
        EntityRecordFieldGridTableComponent,
        SearchModalComponent,
        CategoriesComponent,
        LeftNavAppsTemplateComponent,
        FetchAssetsModalComponent,
        HelpDocumentViewComponent,
        EventsSubscriptionAdvancedEditComponent,
        HelpDocPreviewComponent,
        DisableClipboardDirective,
    ],
    exports: [
        TaxillaHeaderComponent,
        SessionCheckComponent,
        EntitySearchFilter,
        HubStorageDirective,
        AppSelectionDirective,
        AppProcessesSelectionDirective,
        AppNewProcessDirective,
        AutofocusDirective,
        DocPreviewComponent,
        AppProcessRequestDirective,
        AppDetectOutsideClickDirective,
        HomeProcessesComponent,
        HomeAppProcessComponent,
        NewProcessComponent,
        ReportsViewComponent,
        ReportIconComponent,
        IconsComponent,
        DateFilterComponent,
        MaterialInputComponent,
        MaterialFileUploadComponent,
        MaterialTextAreaComponent,
        MaterialSelectGroupComponent,
        MaterialSelectComponent,
        MaterialGroupCheckboxComponent,
        MaterialInputPasswordComponent,
        MaterialInputDateComponent,
        MaterialSlidetoggleComponent,
        MaterialCheckboxComponent,
        MaterialButtonComponent,
        MaterialCardComponent,
        MaterialAccordionComponent,
        EventPropagationDirective,
        FieldsGroupPipe,
        CommonNotificationsComponent,
        CommonTemplatesComponent,
        FileDragDetectorDirective,
        FileDragDropDirective,
        GridFieldComponent,
        TableInputComponent,
        GridFieldModalComponent,
        RichTextEditorComponent,
        ChangePasswordComponent,
        PerfectScrollbarModule,
        DowntimeActiveMessageComponent,
        DurationComponent,
        ...angularModules,
        ...materialModules,
        ...necessaryModules,
        DebounceDirective,
        AnimationsModule,
        CommoncollaborationlistComponent,
        SettingsNestedMenuComponent,
        RecordFieldsComponent,
        HeaderShortcutsComponent,
        SourceTableComponent,
        MaterialTableComponent,
        MaterialPaginatorComponent,
        UploadComponent,
        IntegrationsFieldTemplateComponent,
        IntegrationsTemplateComponent,
        AddConfigurationModalComponent,
        IntegrationsPostmanStructureComponent,
        CommonMastersComponent,
        MastersearchComponent,
        SavecustomfilterComponent,
        SaveReconTemplateComponent,
        TemplateListTableComponent,
        ElasticQueryBuilderComponent,
        MasterRequestsNotificationsComponent,
        CommonchangelogrecordComponent,
        CommonNotificationsEventlogsComponent,
        IntegrationFieldsComponent,
        MasterChangeLogComponent,
        AppCollaborationComponent,
        AddRecordsComponent,
        ChartDataComponent,
        EntitySearchComponent,
        SearchBuilderComponent,
        QuerybuilderComponent,
        QueryBuilderNewComponent,
        DotsLoaderComponent,
        FilingAttributeFieldComponent,
        FieldLevelFileAttachmentComponent,
        SortassetsPipe,
        MultiFilterPipe,
        ConsolidateComponent,
        VirtualTableComponent,
        NewReconciliationProcessComponent,
        CustomSearchComponent,
        NotificationsComponent,
        TimeFiltersComponent,
        BacktopreviousDirective,
        PascalToCamelPipe,
        ReportsFilterPipe,
        FilterPipe,
        AlphabeticalOrderPipe,
        RemoveAriaOwnsDirective,
        OrderByPipe,
        TruncateHtmlTagsPipe,
        PrimaryHeaderRecordFieldsPipe,
        PrimarySubHeaderRecordFieldsPipe,
        ObjNgFor,
        WorkflowFieldsInputComponent,
        UserDetailsComponent,
        ProcessLogComponent,
        SearchModalComponent,
        CategoriesComponent,
        LeftNavAppsTemplateComponent,
        FetchAssetsModalComponent,
        HelpDocumentViewComponent,
        EventsSubscriptionAdvancedEditComponent,
        FieldInputTemplateComponent,
        DisableClipboardDirective,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
        FileUpload,
        TableVirtualScrollStrategy,
        NewProcessService,
        UtilsService,
    ],
})
export class TaxillaLibraryModule {}
