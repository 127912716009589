import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationTabStructure,
} from '../../interface/integrationmodal.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { IntegrationRecord } from './integrationrecord.class';

export class RecordData {
    fields?: IntegrationModal['fields'];

    subSections?: IntegrationModal['subSections'];

    constructor(record: IntegrationRecord, metaData: IntegrationModal) {
        const configuredData: any = record?.configuredData || {};
        const masterInUse = configuredData.useMaster;

        const checkAndAssignValue = (
            objectToAssign: any,
            property: string,
            value: any,
            decodingType: IntegrationConfigField['decodingType'],
            isArray?: boolean
        ) => {
            if (value !== undefined) {
                if (isArray) {
                    value = Array.isArray(value) ? value : [value];
                } else {
                    value = Array.isArray(value) ? value[0] : value;
                }
            }
            if (decodingType && (!masterInUse || decodingType === 'DATE')) {
                switch (decodingType) {
                    case 'atob':
                        try {
                            objectToAssign[property] = window.atob(value);
                        } catch (error) {
                            objectToAssign[property] = value;
                        }
                        break;
                    case 'DATE':
                        let inputFormat;
                        if (CommonUtilsService.isIsoFormat(value)) {
                            inputFormat = 'YYYY-MM-DDTHH:MM:SSZ';
                        } else {
                            inputFormat = 'DD-MM-YYYY HH:MM:SS AM';
                        }
                        objectToAssign[property] = CommonUtilsService.transformDateToLocale(
                            value,
                            inputFormat,
                            'DD-MM-YYYY HH:MM:SS AM',
                            false
                        );
                        break;
                    default:
                        objectToAssign[property] = value;
                        break;
                }
            } else {
                objectToAssign[property] = value;
            }
        };

        const isConditionalArray = (field: IntegrationConfigField): string | boolean => {
            let satisfied = false;
            let satisfiedConditions: { fieldId: string; requiredValue: boolean; array: boolean }[];
            const masterValue = (record?.configuredData && record.configuredData['useMaster']) || false;
            field.conditionalArray &&
                field.conditionalArray.forEach((conditions) => {
                    if (satisfied) {
                        return;
                    }
                    switch (conditions.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditions.conditions.filter((condition) => {
                                return condition.requiredValue !== undefined ? condition.requiredValue === masterValue : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditions.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditions.conditions.filter((condition) => {
                                return condition.requiredValue !== undefined ? condition.requiredValue === masterValue : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
            return satisfied && satisfiedConditions && satisfiedConditions[0] && satisfiedConditions[0].array;
        };

        const loopThroughObject = (key: string, data: object, isFieldRequired?: boolean, parentKey?: string) => {
            let field: IntegrationConfigField;
            if (!isFieldRequired) {
                field = this.fields.find((metaDataField) => metaDataField.id === key || metaDataField.fieldIdInSubObject === key);
            }
            if (field) {
                checkAndAssignValue(
                    field,
                    'value',
                    data[key] || field.defaultValue,
                    field.decodingType,
                    field.conditionalArray && (isConditionalArray(field) as boolean)
                );
                if (
                    (field.array && Array.isArray(field.value)) ||
                    field.valueAsArrayInPayload ||
                    (field.conditionalArray && isConditionalArray(field))
                ) {
                    let values = [];
                    if (Array.isArray(field.value)) {
                        values = ((field.value as string[]) && field.value[0] && field.value[0].length > 0 && field.value) || [''];
                    } else {
                        values = [field.value];
                    }
                    field.values = [];
                    if (!Array.isArray(values)) {
                        values = field.value.split(',');
                    }
                    values &&
                        values.forEach((value) => {
                            const valueObj: IntegrationConfigField['values'][0] = {};
                            checkAndAssignValue(valueObj, 'value', value, field.decodingType);
                            field.values.push(valueObj);
                        });
                }
                if (typeof field.value === 'object' && field.type === 'TRANSFORMATION_PANEL') {
                    setPanaelValue(field);
                }
            } else if (!field && typeof data[key] === 'object' && !Array.isArray(data[key])) {
                // tslint:disable-next-line:forin
                for (const subKey in data[key]) {
                    let noField = false;
                    if (key === 'authentication') {
                        noField = true;
                    }
                    loopThroughObject(subKey, data[key], noField, key);
                }
            } else if (!field && this.subSections) {
                this.subSections.forEach((subFieldObject) => {
                    let subField: IntegrationConfigField;
                    if (subFieldObject.haveTabs) {
                        for (let i = 0; i < subFieldObject.tabs.length; i++) {
                            const tab = subFieldObject.tabs[i];
                            if (!tab.array && parentKey !== undefined && tab.id !== undefined && parentKey !== tab.id) {
                                // Do Nothing
                            } else if (tab.array && tab.id !== key) {
                                // Do Nothing
                            } else {
                                if (data[key] instanceof Array) {
                                    if (tab.id === key && parentKey === 'configuredData') {
                                        tab.records = [];
                                        data[key].forEach((subData) => {
                                            const newRecord: IntegrationTabStructure['records'][0] = {
                                                fields: [],
                                                recordActions: [],
                                            };
                                            // tslint:disable-next-line:forin
                                            for (const dataKey in subData) {
                                                let metaDataField = tab.fields.find((tabField) => tabField.id === dataKey);
                                                if (metaDataField) {
                                                    metaDataField = CommonUtilsService.cloneObject(metaDataField);
                                                    if (
                                                        metaDataField.valueAsArrayInPayload &&
                                                        Array.isArray(subData[dataKey]) &&
                                                        (!metaDataField.array || isConditionalArray(metaDataField))
                                                    ) {
                                                        subData[dataKey] = subData[dataKey][0];
                                                    } else if (subData[dataKey] && Array.isArray(subData[dataKey])) {
                                                        for (let index = 0; index < subData[dataKey].length; index++) {
                                                            if (subData[dataKey][index] === null || subData[dataKey][index] === undefined) {
                                                                subData[dataKey][index] =
                                                                    metaDataField.defaultValue !== undefined
                                                                        ? metaDataField.defaultValue
                                                                        : '';
                                                            }
                                                        }
                                                    }
                                                    checkAndAssignValue(
                                                        metaDataField,
                                                        'value',
                                                        subData[dataKey] !== undefined ? subData[dataKey] : metaDataField.defaultValue,
                                                        metaDataField.decodingType
                                                    );
                                                    newRecord['fields'].push(metaDataField);
                                                } else {
                                                    let metaDataSubField = tab.recordActions.find(
                                                        (tabSubField) => tabSubField.id === dataKey
                                                    );
                                                    if (metaDataSubField) {
                                                        metaDataSubField = CommonUtilsService.cloneObject(metaDataSubField);
                                                        checkAndAssignValue(
                                                            metaDataSubField,
                                                            'value',
                                                            subData[dataKey] !== undefined
                                                                ? subData[dataKey]
                                                                : metaDataSubField.defaultValue,
                                                            metaDataSubField.decodingType
                                                        );
                                                        newRecord['recordActions'].push(metaDataSubField);
                                                    }
                                                }
                                            }
                                            tab.records.push(newRecord);
                                        });
                                    }
                                } else {
                                    subField = tab.fields.find((metaDataField) => metaDataField.id === key);
                                    if (subField) {
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        if (subFieldObject.structure === 'FORM') {
                            if (parentKey !== undefined && subFieldObject.id !== undefined && parentKey !== subFieldObject.id) {
                                return;
                            }
                            const formSubField = subFieldObject.fields.find((formSubFieldObj) => formSubFieldObj.id === key);
                            if (formSubField) {
                                checkAndAssignValue(formSubField, 'value', data[formSubField.id], formSubField.decodingType);
                            }
                        } else if (subFieldObject.structure === 'FLEX_FORM') {
                            if (subFieldObject.id === 'cronExpression' && key === 'cronExpression') {
                                const formSubField = subFieldObject.fields.find(
                                    (formSubFieldObj) => formSubFieldObj.id === 'schedulerExpression'
                                );
                                const value = data[formSubField.id];
                                const valueToPass = value
                                    ?.split(' ')
                                    ?.filter((_item, index) => index > 0 && index < 6)
                                    .join(' ');
                                formSubField && checkAndAssignValue(formSubField, 'value', valueToPass, formSubField.decodingType);
                            }
                        }
                    }
                    if (subField) {
                        checkAndAssignValue(
                            subField,
                            'value',
                            data[key] || (subField && subField.defaultValue),
                            (subField && subField.decodingType) || undefined
                        );
                    }
                });
            }
        };

        const assignFieldValue = (field: IntegrationConfigField) => {
            field.value = field.value !== undefined ? field.value : field.defaultValue;
            if (
                field.array ||
                (field.conditionalArray && ((record.isConditionalArray && record.isConditionalArray(field)) || isConditionalArray(field)))
            ) {
                let values = field.value !== undefined && (field.value as string[]).length > 0 ? (field.value as string[]) : [''];
                field.values = [];
                if (!Array.isArray(values)) {
                    values = field.value.split(',');
                }
                if (Array.isArray(values)) {
                    values &&
                        values.forEach((value) => {
                            field.values.push({ value });
                        });
                }
            }
        };

        const setPanaelValue = (field) => {
            const keys = Object.keys(field.value);
            keys.forEach((key) => {
                field.options.forEach((each) => {
                    if (each.name === key) {
                        each.value = field.value[key];
                    }
                });
            });
        };

        const assignSubSectionTabRecord = (tab: IntegrationTabStructure) => {
            if (tab.array) {
                tab.records = tab.records || [];
                if (tab.records.length === 0) {
                    tab.records.push({
                        fields: [],
                        recordActions: [],
                    });
                }
                tab.records.forEach((tabRecord) => {
                    if (!tabRecord.fields || tabRecord.fields.length === 0) {
                        tab.fields.forEach((tabField) => {
                            const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                            newTabField.value = newTabField.defaultValue;
                            tabRecord.fields.push(newTabField);
                        });
                    } else if (tabRecord.fields.length !== tab.fields.length) {
                        tab.fields
                            .filter((action) => tabRecord.fields.findIndex((recordAction) => recordAction.id === action.id) === -1)
                            .forEach((action) => {
                                const cloneField = CommonUtilsService.cloneObject(action);
                                cloneField.value = action.defaultValue;
                                tabRecord.fields.push(cloneField);
                            });
                    }
                    if (!tabRecord.recordActions || tabRecord.recordActions.length === 0) {
                        tab.recordActions.forEach((tabField) => {
                            const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                            newTabField.value = newTabField.defaultValue;
                            tabRecord.recordActions.push(newTabField);
                        });
                    } else if (tabRecord.recordActions.length !== tab.recordActions.length) {
                        tab.recordActions
                            .filter((action) => tabRecord.recordActions.findIndex((recordAction) => recordAction.id === action.id) === -1)
                            .forEach((action) => {
                                const cloneAction = CommonUtilsService.cloneObject(action);
                                cloneAction.value = action.defaultValue;
                                tabRecord.recordActions.push(cloneAction);
                            });
                    }
                });
            }
        };

        const assignSubSections = (subSections: IntegrationsSubSection[]) => {
            subSections &&
                subSections.forEach((subFieldObject) => {
                    if (subFieldObject.haveTabs && subFieldObject.tabs.length > 0) {
                        subFieldObject.tabs.forEach(assignSubSectionTabRecord);
                    }
                });
        };

        for (const key in metaData) {
            if (metaData[key] instanceof Array) {
                // this[key] = metaData[key].slice(0);
                this[key] = CommonUtilsService.cloneObject(metaData[key]);
            } else if (metaData[key] instanceof Object) {
                this[key] = CommonUtilsService.cloneObject(metaData[key]);
            } else {
                this[key] = metaData[key];
            }
        }

        if (Object.keys(configuredData).length > 0) {
            for (const key in record) {
                if (key !== 'type') {
                    loopThroughObject(key, record);
                }
            }
        }
        this.fields?.forEach(assignFieldValue);
        this.subSections && assignSubSections(this.subSections);
    }
}
