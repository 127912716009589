import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';
import { ApiService, AssetService } from 'taxilla-library';

import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

@Component({
    selector: '[EntityRecordFieldTemplate]',
    templateUrl: './entity-record-field-template.component.html',
    styleUrls: ['./entity-record-field-template.component.scss'],
})
export class EntityRecordFieldTemplateComponent implements OnChanges, OnInit {
    @Input() fieldId: string;
    @Input() public record: Record;
    @Input() masterTablesData: string;
    @Input() public masterRecordsResponse: {
        [tableId: string]: {
            [pipedColumnKeys: string]: MastersResponseRecord[];
        };
    };
    @Input() recordId: string;
    @Input() eventType: string;
    @Input() public parentLookupsData: string;
    @Input() public service: AssetService;
    @Input() public lookupsResponse: {
        [condition: string]: RecordField['lookupValues'];
    };

    @Output() selectedMasterRecord = new EventEmitter();
    @Output() selectedLookupValue = new EventEmitter();
    @Output() openAttachment = new EventEmitter();
    @Output() openGrid = new EventEmitter();
    @Output() editTextArea = new EventEmitter();
    @Output() onSearch = new EventEmitter();

    public field: RecordField;
    private organizationId: string;
    public masterOptions: string[] = [];
    private masterRecords: MastersResponseRecord[] = [];
    private stringMap: string;
    public disabled: boolean;
    public showPassword: boolean;
    private searchInitialised: boolean;
    public attachmentfileNames: string[] = [];
    public attachmentUrl = environment.taxilla_api + '/asset/attachments?fileUri=';
    public lookupOptions: Array<{
        key: string;
        value: string;
        selected: boolean;
    }>;
    public selectedOptions: string[] = [];
    public autoCompleteField = new FormControl('');

    constructor(private _commonUtils: CommonUtilsService, private _api: ApiService, public sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.masterTablesData && this.field?.hasMasterLookup) {
            const { valuesMap } = this.getDependentMastersMap();
            if (this.stringMap !== JSON.stringify(valuesMap)) {
                this.masterOptions = [];
                this.stringMap = JSON.stringify(valuesMap);
                this.field.value = '';
            }
        }
        if (changes.parentLookupsData && this.field?.hasLookup) {
            const { conditionalValue } = this.getConditionValue();
            if (this.stringMap !== conditionalValue) {
                this.field.value = undefined;
                this.field.lookupValues.displayVsValue = [];
                this.stringMap = conditionalValue;
            }
        }
        if ((changes?.recordId || changes?.eventType) && this.field?.id) {
            this.applyFieldConfigs(this.eventType);
        }
    }

    public trackOption(_index: number, option: any): any {
        return option.id;
    }

    private startComponent = () => {
        this.field = this.record?.fields?.find((field) => field.id === this.fieldId);
        this.applyFieldConfigs(this.eventType);
        if ((this.field?.value as string)?.length > 0) {
            this.masterOptions = [];
            this.masterOptions.push(this.field.value as string);
        }
        if (this.field?.hasLookup) {
            switch (this.field.uiTypeMetadata) {
                case 'DROPDOWN':
                    if (this.field.value === undefined) {
                        this.field.value = undefined;
                    } else if ((this.field.value as string)?.length > 0) {
                        this.getLookupData();
                    }
                    break;
                case 'CHECKBOX':
                    this.lookupOptions = CommonUtilsService.cloneObject(this.field?.lookupValues?.displayVsValue);
                    this.selectedOptions = [];
                    (this.field.value as string[])?.length > 0 &&
                        this.lookupOptions?.forEach((value) => {
                            value.selected = (this.field.value as string[]).includes(value.value);
                            value.selected && this.selectedOptions.push(value.key);
                        });

                    break;
            }
        } else if (this.field?.hasMasterLookup) {
            if ((this.field.value as string)?.length > 0) {
                this.getMasterData();
            }
        }
        if (this.field?.datatype === 'ATTACHMENT') {
            if (Array.isArray(this.field.value) && this.field.value.length > 0) {
                const filenames = this.field.value.map((url) => {
                    const parts = url.split('/');
                    return parts[parts.length - 1];
                });
                this.attachmentfileNames = filenames;
            }
        }
    };

    public updateSelection = () => {
        this.selectedOptions = [];
        this.field.value = this.lookupOptions?.reduce((values, value) => {
            value.selected && values.push(value.value);
            value.selected && this.selectedOptions.push(value.key);
            return values;
        }, [] as string[]);
    };

    private applyFieldConfigs = (event) => {
        this.disabled =
            event === 'view' ||
            this.field?.autoCalculate ||
            this.field?.disabled ||
            (this.recordId && this.field?.isBusinessKey) ||
            this.field?.idGenerator !== undefined ||
            (this.recordId &&
                this.field?.hasMasterLookup &&
                this.record.fields.find(
                    (field) =>
                        field.isBusinessKey &&
                        field.masterData.columnRefMetadatas?.find(
                            (ref) => ref.fieldUid === this.fieldId && ref.entityUid === this.field.entityUid
                        )
                ) !== undefined);
    };

    public toggleKeyboardEvent = (event: KeyboardEvent) => {
        if (event.code === 'ArrowDown') {
            this.field.hasMasterLookup && this.getMasterData();
            this.field.hasLookup && this.getLookupData();
        }
    };

    public getMasterData = () => {
        if (!this.field?.hasMasterLookup || (this.masterOptions.length > 0 && this.searchInitialised)) {
            return;
        }
        const { valuesMap, keyValue } = this.getDependentMastersMap();
        this.stringMap = JSON.stringify(valuesMap);
        if (this.masterRecordsResponse?.[this.field.masterData.tableUid]?.[keyValue]?.length > 0) {
            this.setInitialValue(
                keyValue,
                CommonUtilsService.cloneObject(this.masterRecordsResponse?.[this.field.masterData.tableUid]?.[keyValue])
            );
            this.masterValueChanged();
            return;
        }
        this.searchInitialised = true;
        this._api.masters.getPrimaryValuesInMasterTable(
            {
                masterDataRow: {
                    tableId: this.field.masterData.tableUid,
                    unitId: this.organizationId,
                    columnValues: valuesMap,
                },
                masterSearchDetail: {
                    pageNo: 0,
                    searchString: '',
                },
            },
            {
                successCallback: (response) => {
                    this.searchInitialised = false;
                    response.forEach((record) => {
                        Object.entries(valuesMap).forEach(([id, valueObject]) => {
                            record.fields.push({
                                errors: [],
                                id: id,
                                value: valueObject.newValue,
                                warnings: [],
                            });
                        });
                    });
                    this.setInitialValue(keyValue, response);
                    if ((this.field.value as string)?.length > 0) {
                        return;
                    }
                    this.masterValueChanged();
                },
                failureCallback: () => {
                    this.searchInitialised = false;
                },
            }
        );
    };

    private setInitialValue = (keyValue: string, response: MastersResponseRecord[]) => {
        this.masterRecords = response;
        this.masterRecordsResponse[this.field.masterData.tableUid] = this.masterRecordsResponse?.[this.field.masterData.tableUid] || {};
        this.masterRecordsResponse[this.field.masterData.tableUid][keyValue] = response;
        response?.forEach((record) => {
            const value = record.fields.find((field) => field.id === this.field.masterField.id)?.value;
            value !== undefined && !this.masterOptions.includes(value) && this.masterOptions.push(value);
        });
        if (this.eventType !== 'search') {
            this.field.value = this.field.value || this.masterOptions?.[0];
        } else {
            this.field.value = undefined;
        }
    };

    private getDependentMastersMap = () => {
        const valuesMap: {
            [columnId: string]: { newValue: string };
        } = {};
        let keyValuePairs = [];
        const refString = this.field.masterData.columnRefMetadatas
            .reduce((ids, ref) => {
                ids.push(ref.fieldUid);
                return ids;
            }, [])
            .join('|');
        const map = this.getRefMap(refString);
        this.field.masterData.columnRefMetadatas.forEach((ref) => {
            const columnId = map?.fieldIdVsColumnId[ref.fieldUid];
            columnId &&
                (valuesMap[columnId] = {
                    newValue: map?.masterRecord[columnId],
                });
            columnId && keyValuePairs.push(`${columnId}:${map?.masterRecord[columnId]}`);
        });
        return { valuesMap, keyValue: keyValuePairs.join('|') };
    };

    private getRefMap = (refId: string) => {
        let refMap: any;
        Object.entries(JSON.parse(this.masterTablesData || '{}')).forEach(([_tableId, tableMap]) => {
            Object.entries(tableMap).forEach(([fieldsString, fieldsMap]) => fieldsString === refId && (refMap = fieldsMap));
        });
        return refMap;
    };

    public masterValueChanged = () => {
        const record = this.masterRecords.find((record) =>
            record.fields.find((field) => field.id === this.field.masterField.id && field.value === this.field.value)
        );
        if (this.searchInitialised) {
            return;
        }
        this.selectedMasterRecord.emit({
            field: this.field,
            masterRecord: record,
        });
        if (this.eventType === 'search' && this.field.value !== undefined) {
            this.onSearch.emit(this.field);
        }
    };

    public clearSearch = () => {
        this.field.value = '';
        this.onSearch.emit();
    };

    public getLookupData = (manualTrigger?: boolean) => {
        if (this.eventType === 'search' || !this.field.hasLookup || (this.searchInitialised && this.field.lookupValues?.length > 0)) {
            return;
        }
        const { proceed, conditionalValue, conditionString } = this.getConditionValue();
        if (!proceed) {
            return;
        }
        if (this.lookupsResponse[conditionString]) {
            if (!this.field.lookupValues?.displayVsValue || this.field.lookupValues?.displayVsValue?.length === 0) {
                this.field.setLookupValues(CommonUtilsService.cloneObject(this.lookupsResponse[conditionString]));
            }
            return;
        }
        this.stringMap = conditionalValue;
        this.searchInitialised = true;
        this._api.lookups.fetchLookupValuesByRecordField(
            {
                conditionalValue: conditionalValue,
                field: this.field,
                service: this.service,
                gridCellIndex: undefined,
            },
            {
                successCallback: (lookupValues) => {
                    this.field.lookupValues.displayVsValue = [];
                    this.field.setLookupValues(lookupValues);
                    this.lookupsResponse[conditionString] = CommonUtilsService.cloneObject(this.field.lookupValues);
                    if (!manualTrigger) {
                        return;
                    }
                },
            }
        );
    };

    public getConditionValue = () => {
        const conditionalString = this.field.lookupInformation.lookupCondition;
        if (!conditionalString) {
            return { proceed: true, conditionalValue: undefined, conditionString: '' };
        }
        const conditionalProperty = conditionalString.substring(0, conditionalString.indexOf('='));
        const splitString = conditionalString.substring(conditionalString.indexOf('=') + 1).split(':');
        const entityId = splitString?.[0]?.substring(1);
        const fieldId = splitString?.[1];
        const parsedData = JSON.parse(this.parentLookupsData);
        const conditionalValue = parsedData?.[entityId]?.[fieldId];
        return {
            conditionalValue,
            proceed: conditionalValue?.length > 0,
            conditionString: `${conditionalProperty}=${conditionalValue}`,
        };
    };

    public lookupValueChanged = () => {
        this.eventType === 'search'
            ? this.onSearch.emit(this.field)
            : this.selectedLookupValue.emit({ fieldId: this.field.id, fieldValue: this.field.value });
    };

    decodeTextFieldValue = (value: any) => {
        let html = value ? decodeURIComponent(value) : '';
        return html ? this.sanitizer.bypassSecurityTrustHtml(html) : '';
    };

    ngOnInit(): void {
        this.organizationId = this._commonUtils.getFromStorage('currentorganizationid');
        this.startComponent();
    }
}
