<mat-toolbar class="header-container" *transloco="let translate">
    <mat-toolbar-row class=" tool-bar matHeader">
        <ul>
            <li>
                <span class="text-font-style">{{currentOrganization ? currentOrganization.displayTenantName :  translate('Select Tenant')}}</span>
                <button mat-button class="header-button green-text" disableRipple (click)="showOrganizations()">{{translate('Change')}}</button>
            </li>
            <li *ngIf="(_commonUtils.analyticsServiceTypes?.split(',').includes('DASHBOARD') || _commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) && canShowAnalytics">
                <button (click)="getDashboardCharts()" mat-button class="fulwidthmenu fulwidthmenuButton appsCurtain dashboardMenu" [class.appCurtainOpened]="matChartsMenuTrigger.menuOpen" [matMenuTriggerFor]="chartsMenu" #matChartsMenuTrigger="matMenuTrigger">
                    <img src="assets/images/new-ui/dashboard.png" alt="dashboard-icon">
                    <span class="ml-2 text-font-style">{{translate('Dashboard')}}</span>
                    <span *ngIf="insights.length > 0" class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
                    <span *ngIf="insights.length > 0" class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
                </button>
                <mat-menu #chartsMenu="matMenu" [overlapTrigger]="false" class="fulWidthMatMenu chartsMenu" backdropClass="header-sub-menu" xPosition="after">
                    <mat-progress-bar *ngIf="isDashboardsLoading" mode="indeterminate"></mat-progress-bar>
                    <div class="tenantsMenuContainer">
                        <mat-card>
                            <mat-card-content>
                                <a *ngFor="let insight of insights" class="mat-menu-item appName text-left appReportContainer" mat-menu-item (click)="openInsights()" routerLink="organizations/{{currentOrganization?.id}}/dashboard" [queryParams]="{insight: insight.serviceName}" routerLinkActive="activeOrganization">
                                    <div title="{{insight.displayName || insight.serviceName}}" class="appDisplayName">
                                        {{insight.displayName}}
                                    </div>
                                </a>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-menu>
            </li>
        </ul>
        <span class="spacer"></span>
        <ul>
            <li>
                <button *ngIf="!isInGuestMode && (currentOrg$ | async)?.workQueueEnabled && this.canShowWorkQ" mat-button class="header-button hover:text-taxillaGreen focus:text-taxillaGreen" [class.isLoading]="loading.activeMessagesCount" title="WorkQ" (click)="navigateWorkQ()" disableRipple>
                    <span class="btnDisplayText">{{translate('WorkQ')}} <span *ngIf="totalWorkQCount!==undefined">({{totalWorkQCount}})</span></span>
                </button>
            </li>
            <li *ngIf="!isInGuestMode">
                <app-settings-nested-menu [menu]="settings" [main]="true" [isSettingsSelected]="isSettingsSelected" [isNewUI]="true"></app-settings-nested-menu>
            </li>
            <li *ngIf="!isInGuestMode">
                <div class="block relative mx-[5px]">
                    <span (click)="switchToNewUI()" class="cursor-pointer border-none py-2 hover:text-taxillaGreen focus:text-taxillaGreen px-2 text-xs font-bold text-center no-underline">{{onNewUI ? translate('Switch to Old UI') : translate('Switch to New UI')}}</span>
                </div>
            </li>
            <li *ngIf="!isInGuestMode">
                <app-user-details [isNewUI]="true"></app-user-details>
            </li>
        </ul>
    </mat-toolbar-row>
</mat-toolbar>
