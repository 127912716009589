import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';
import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';

@Component({
    selector: 'material-input-date',
    templateUrl: './inputdate.component.html',
    styleUrls: ['./inputdate.component.scss'],
})
export class MaterialInputDateComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() placeholder;
    @Input() name;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() skipStartDate;
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() description: string;
    @Input() dateFormat: string = 'dd/mm/yyyy';
    @Input() inputOnlyDisabled: boolean;
    @Input() appearance: MatFormFieldAppearance;
    @Input() formFieldClass: string;
    @Input() isSearchField: boolean;
    @Input() label: string;
    @Input() minDate: string;
    @Input() minDateFormat: string;
    @Input() maxDate: string;
    @Input() maxDateFormat: string;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl();
    currentErrors: any[];
    valueSubscription: Subscription;
    minDateValue: Date;
    maxDateValue: Date;

    constructor(private _commonUtils: CommonUtilsService) {}

    ngDoCheck() {
        let errors: string[];
        let warnings: string[];
        const stringifiedErrors = JSON.stringify(this.control.getError('errors'));
        const stringifiedWarnings = JSON.stringify(this.control.getError('warnings'));
        if (this.errors !== undefined && this.errors.length > 0 && JSON.stringify(this.errors) !== stringifiedErrors) {
            // show errors
            errors = this.errors.slice(0);
        }
        if (this.warnings !== undefined && this.warnings.length > 0 && JSON.stringify(this.warnings) !== stringifiedWarnings) {
            // show warnings
            warnings = this.warnings.slice(0);
        }
        if (errors || warnings) {
            this.control.setErrors({ errors: errors, warnings: warnings });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.model) {
            this.isSearchField
                ? this.setInputAsValue(changes.model.currentValue, true)
                : this.applyChangesToDate(changes.model.currentValue, true);
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
        if (changes?.minDate || changes?.maxDate) {
            this.applyDateRestriction();
        }
    }

    modelChanged = () => {
        this.emitChanges(this.control.value);
    };

    emitChanges = (dateTime) => {
        if (dateTime && dateTime.getDate) {
            this.model = this._commonUtils.transformDateToLocale(dateTime, 'DATE', this.dateFormat as any, true);
        } else {
            this.model = dateTime;
        }
        this.modelChange.emit(this.model);
    };

    applyChangesToDate = (date: string, dontEmitChanges?: boolean) => {
        let dateString: string;
        if ((date as any) instanceof Date) {
            dateString = date;
        } else {
            dateString = this._commonUtils.transformDateToLocale(date, this.dateFormat as any, 'DATE', true);
        }
        if (dateString) {
            this.control && this.control.setValue(dateString, { emitEvent: !dontEmitChanges });
        } else {
            this.control.setValue(undefined, { emitEvent: !dontEmitChanges });
        }
    };

    private setInputAsValue = (date: string, dontEmitChanges?: boolean) => {
        let dateString: string;
        if ((date as any) instanceof Date) {
            dateString = date;
        } else {
            dateString = this._commonUtils.transformDateToLocale(date, this.dateFormat as any, 'DATE', true);
        }
        if (dateString) {
            this.control && this.control.setValue(dateString, { emitEvent: !dontEmitChanges });
        } else {
            this.control.setValue(undefined, { emitEvent: !dontEmitChanges });
        }
    };

    applyDateRestriction = () => {
        if (this.minDate && this.minDateFormat) {
            this.minDateValue = CommonUtilsService.transformDate(this.minDate, this.minDateFormat as any, 'DATE') as any;
        }
        if (this.maxDate && this.maxDateFormat) {
            this.maxDateValue = CommonUtilsService.transformDate(this.maxDate, this.maxDateFormat as any, 'DATE') as any;
        }
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe(() => this.modelChanged());
        if (this?.minDate || this?.maxDate) {
            this.applyDateRestriction();
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
