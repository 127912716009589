import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';

import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { UtilsService } from '../services/utils/utils.service';

import type { QueryBuilderConfig, RuleSet } from 'ng-query-builder';
import type { Entity } from './entity.class';
import type { Field } from './field.class';
import type { EntityFilterCriterias, FilterCriteria, Restriction, SearchRule } from './searchcriteria.global';
export class Search {
    public isSubEntityVisible = [];

    private searchFields: SearchField[] = [];

    public searchConfig: QueryBuilderConfig = {
        fields: {},
    };

    public searchQuery: {
        condition?: string;
        rules?: any[];
    } = {};

    public sortConfig: QueryBuilderConfig = {
        fields: {},
    };

    public sortQuery: {
        condition: string;
        rules: any[];
    };

    public selectedSource: SearchSource;

    public relationQuery: RuleSet = {
        condition: 'and',
        rules: [],
    };

    relationConfig: QueryBuilderConfig = {
        fields: {
            Criteria: {
                name: 'Criteria',
                nullable: false,
                type: 'string',
            },
        },
    };

    private relation: any[];

    public readonly filterCriteria: FilterCriteria = {
        serviceId: undefined,
        entityFilterCriterias: [],
        criteriaRelations: {},
    };

    public criteriaUpdated?: boolean;

    public relationsUpdated?: boolean;

    public searchQueryConfigChange?: Subject<{
        searchConfig?: QueryBuilderConfig;
        sortConfig?: QueryBuilderConfig;
    }> = new Subject();

    public relationQueryConfigChange?: Subject<{
        searchConfig?: QueryBuilderConfig;
        sortConfig?: QueryBuilderConfig;
    }> = new Subject();

    private radioLevelRetainedRules: any[];

    public entityClicked: boolean;

    public needSourceStructure?: boolean;

    public queryBuilderShouldVisible = false;

    public sourceClicked?: boolean;

    public origin?: 'PROCESS' | 'PROCESSES' | 'ENINVOICE' | 'ENCOLLAB';

    public isSortConfigEmpty?: boolean;

    public sources?: SearchSource[];

    public allowRuleset?: boolean;

    public isAddRuleVisible?: boolean;

    public allowCollapse?: boolean;

    public disabled?: boolean;

    public showSearchCriteriaTitle = true;

    public showSortCriteriaTitle = true;

    public sortCriteria: { [property: string]: any } = {};

    public inEditFilterMode: boolean;

    static searchFields: any;

    public getOutputSources = (entities: Entity[], criteria?: FilterCriteria) =>
        Search.getOutputSources(entities, criteria || this.filterCriteria);

    private fieldOptions: {
        [property: string]: {
            name: string;
            value: string;
        }[];
    } = {};

    constructor(private _utils: UtilsService, public serviceId?: string) {
        this.setCriteria();
    }

    public setCriterias = (
        filterCriteria?: FilterCriteria,
        sortCriteria?: { [property: string]: any },
        skipBuildAutoRelations?: boolean
    ) => {
        this.setCriteria(filterCriteria);
        const deOptimizedCriteria = this.deOptimizeCriteria();
        deOptimizedCriteria?.entityFilterCriterias?.[0]?.entityUid &&
            this.setSource(deOptimizedCriteria.entityFilterCriterias[0].entityUid);
        this.clearSearchQueries(deOptimizedCriteria, sortCriteria, true, skipBuildAutoRelations);
    };

    private setCriteria = (criteria?: FilterCriteria) => {
        criteria = criteria || {
            serviceId: this.serviceId,
            entityFilterCriterias: [],
            criteriaRelations: {},
        };
        this.removeCustomRules(criteria);
        this.setFilterCriteria(criteria);
    };

    private setSortCriteria = (query?: { [property: string]: any }) => {
        this.sortCriteria = CommonUtilsService.cloneObject(query || {});
    };

    private deOptimizeCriteria = () => {
        const criteria: FilterCriteria = CommonUtilsService.cloneObject(this.filterCriteria);
        if (!this.inEditFilterMode) {
            criteria?.criteriaRelations?.condition &&
                (criteria.criteriaRelations.condition = criteria?.criteriaRelations?.condition.toLowerCase());
        }
        criteria?.criteriaRelations?.rules?.forEach(this.deOptimizeRule);
        criteria?.entityFilterCriterias?.forEach((criteria) => {
            criteria.criteriaDefinitions?.forEach((definition) => {
                if (!this.inEditFilterMode) {
                    definition?.restriction?.condition &&
                        (definition.restriction.condition = definition?.restriction?.condition.toLowerCase());
                }
                definition.restriction?.rules?.forEach(this.deOptimizeRule);
            });
        });
        return criteria;
    };

    private deOptimizeRule = (rule: SearchRule) => {
        if (rule.fieldId) {
            rule['field'] = rule.fieldId;
        } else if (rule['field']) {
            rule.fieldId = rule['field'];
        }
        if (rule.operator) {
            delete rule.restrictionType;
        }
        if (rule.operator === 'CONTAINS') {
            if (rule.value?.indexOf('*') > -1) {
                rule.value = (rule.value as string).replace(/\*/gm, '');
            }
        } else if (rule.operator === 'NOT_EXISTS') {
            rule.operator = 'is null';
            delete rule.value;
        } else if (rule.operator === 'EXISTS') {
            rule.operator = 'is not null';
            delete rule.value;
        } else if (rule.operator === 'NOT_EQUALS') {
            rule.operator = '!=';
        } else if (rule.operator === 'IN' && Array.isArray(rule.value)) {
            const value: string[] = rule.value as any;
            rule.value = value.join(',');
        }
        if (this.searchConfig?.fields?.[rule['field']]?.type === 'date' && rule.value?.includes('-')) {
            rule.value = CommonUtilsService.transformDate(
                rule.value as any,
                'dd-mm-yyyy',
                this.searchConfig?.fields?.[rule['field']]?.['format']
            );
        }
        (rule as any).rules?.forEach?.(this.deOptimizeRule);
    };

    private onCriteriaChange = (criteria: FilterCriteria, dontAddEmptyRule?: boolean) => {
        this.setFilterCriteria(criteria);
        if (this.filterCriteria?.entityFilterCriterias?.length > 0) {
            this.createRelationModel(dontAddEmptyRule);
            this.appendToRelationCriteria();
        } else {
            this.relationQuery = {
                condition: 'and',
                rules: [],
            };
            this.relationConfig = {
                fields: {
                    Criteria: {
                        name: 'Criteria',
                        nullable: false,
                        type: 'string',
                    },
                },
            };
        }
    };

    private setFilterCriteria = (criteria: FilterCriteria) => {
        Object.assign(this.filterCriteria, {
            ...this.filterCriteria,
            ...criteria,
        });
    };

    public optimizeCriteria = () => {
        const criteria: FilterCriteria = CommonUtilsService.cloneObject(this.filterCriteria);
        criteria.criteriaRelations?.rules?.forEach(this.optimizeRule);
        if (!this.inEditFilterMode) {
            criteria?.criteriaRelations?.condition &&
                (criteria.criteriaRelations.condition = criteria?.criteriaRelations?.condition.toUpperCase());
        }
        criteria.entityFilterCriterias?.forEach((criteria) => {
            criteria.criteriaDefinitions?.forEach((definition) => {
                if (!this.inEditFilterMode) {
                    definition?.restriction?.condition &&
                        (definition.restriction.condition = definition?.restriction?.condition.toUpperCase());
                }
                definition.restriction?.rules?.forEach(this.optimizeRule);
            });
        });
        return criteria;
    };

    private optimizeRule = (rule: SearchRule) => {
        delete rule['field'];
        delete (rule as any).entity;
        const sourceField = this.selectedSource?.fields?.find((field) => field.id === rule.fieldId);
        if (rule.operator) {
            rule.restrictionType = 'SingleRestrictionMetadata';
        }
        if (rule.operator === 'EQ') {
            switch (sourceField?.datatype) {
                case 'DATE':
                    break;
            }
        } else if (rule.operator === 'CONTAINS') {
            if (rule.value?.indexOf('*') === -1) {
                rule.value = `*${rule.value}*`;
            }
        } else if (rule.operator === 'is null') {
            rule.operator = 'NOT_EXISTS';
            delete rule.value;
        } else if (rule.operator === 'is not null') {
            rule.operator = 'EXISTS';
            delete rule.value;
        } else if (rule.operator === '!=') {
            rule.operator = 'NOT_EQUALS';
        } else if (rule.operator === 'IN') {
            if (!Array.isArray(rule.value)) {
                rule.value = rule.value.split(',') as any;
            }
        }
        (rule as any).rules?.forEach?.(this.optimizeRule);
    };

    public clearSearchQueries = (
        criteria?: FilterCriteria,
        sort?: Search['sortCriteria'],
        dontAddEmptyRule?: boolean,
        skipBuildAutoRelations?: boolean
    ) => {
        criteria = criteria || {
            serviceId: this.serviceId,
            entityFilterCriterias: [],
            criteriaRelations: {},
        };
        sort = sort || {};
        this.onCriteriaChange(criteria, dontAddEmptyRule);
        this.setSortCriteria(sort);
        this.setQueriesToDefault();
        this.buildQuerries();
        this.criteriaUpdated = this.buildCriterias(true, true, skipBuildAutoRelations) !== false;
    };

    private getSource = (sources: SearchSource[], sourceId: string) => {
        let source: SearchSource;
        sources?.forEach((item) => {
            if (!source) {
                if (item.id === sourceId) {
                    source = item;
                } else if (item.sources?.length > 0) {
                    source = this.getSource(item.sources, sourceId);
                }
            }
        });
        return source;
    };

    public setSource = (sourceId?: string) => {
        sourceId = sourceId || this.selectedSource?.id;
        if (!sourceId) {
            return;
        }
        this.setQueriesToDefault();
        this.selectedSource = this.getSource(this.sources, sourceId);
        this.searchFields = [];
        this.searchConfig = {
            fields: {},
        };
        this.sortConfig = {
            fields: {},
        };
        this.createSearchModel();
        this.appendToRuleCriteria();
        this.buildSortQuery();
        if (this.searchQuery.rules.length === 0) {
            this.appendEmptyRule();
        }
    };

    public filterSources = (sources: SearchSource[]) => {
        const splitCriteria = JSON.stringify(this.filterCriteria).split('"');
        this.checkSources(sources, splitCriteria, []);
    };

    private checkSources = (sources: SearchSource[], splitCriteria: string[], entitiesList: string[]) => {
        let result: boolean;
        let i = sources.length - 1;
        while (i >= 0) {
            const entity = sources[i];
            const foundInSubEntities = this.checkSources(entity.sources, splitCriteria, entitiesList);
            if (!entitiesList.includes(sources[i].id)) {
                if (foundInSubEntities === false || foundInSubEntities === undefined) {
                    result = false;
                    entity.sources = [];
                } else {
                    entitiesList.push(sources[i].id);
                    result = true;
                }
            }
            result = result || this.checkEntity(entity, splitCriteria);
            if (!result) {
                sources.splice(i, 1);
            } else {
                entitiesList.push(sources[i].id);
            }
            i--;
        }
        return result;
    };

    private checkEntity = (source: SearchSource, splitCriteria: string[]) => {
        return splitCriteria.indexOf(source.id) > -1;
    };

    private createSearchModel = () => {
        this.searchFields = [];
        const source = this.selectedSource;
        source?.fields
            .filter(
                (field) =>
                    this.origin !== 'ENINVOICE' ||
                    (this.origin === 'ENINVOICE' &&
                        ['api_action', 'conslidate_ewaybill_generation', 'confirmed_by', 'receiver_name', 'attach_gnr'].indexOf(
                            field.id
                        ) === -1)
            )
            .forEach((field) => {
                if (field.searchable) {
                    const newField: SearchField = {
                        id: field.id,
                        value: undefined,
                        searchable: field.searchable,
                        name: field.name,
                        sortable: field.sortable,
                        datatype: field.datatype,
                        format: field.format,
                    };
                    if (newField.id === 'e_waybill_status') {
                        newField.value = undefined;
                    }
                    this.searchFields.push(newField);
                }
            });
        if (source?.primary) {
            [
                {
                    displayName: translate('Transmission Id'),
                    id: 'requestId',
                    datatype: 'string',
                },
                {
                    displayName: translate('Instance State'),
                    id: 'instanceState.raw',
                    dataType: 'options',
                },
                {
                    displayName: translate('Workflow Status'),
                    id: 'instanceStatus.raw',
                    datatype:
                        this.fieldOptions['instanceStatus.raw'] && this.fieldOptions['instanceStatus.raw'].length > 0
                            ? 'options'
                            : 'string',
                },
                {
                    displayName: translate('Validation Status'),
                    id: 'validationStatus',
                    datatype: 'options',
                },
            ].forEach((field) => {
                field['name'] = field.displayName;
                field['searchable'] = true;
                const recordField: SearchField = {
                    id: field?.id,
                    name: field['name'],
                    value: undefined,
                    searchable: field['searchable'],
                    sortable: false,
                    datatype: field.dataType,
                    format: undefined,
                };
                this.searchFields.push(recordField);
            });
        }
        this.fetchSearchFeilds();
        this.searchQueryConfigChange.next({ searchConfig: this.searchConfig, sortConfig: this.sortConfig });
    };

    private fetchSearchFeilds = () => {
        const source = this.selectedSource;
        const searchObj = {};
        const sortObj = {};
        this.searchFields.forEach((field) => {
            if (field.searchable) {
                CommonUtilsService.getElasticSearchObject(field, searchObj, this.fieldOptions?.[field.id]);
            }
            if (field.sortable) {
                CommonUtilsService.getElasticSortObject(field, sortObj);
            }
        });
        this.searchConfig['fields'] = Object.assign({}, searchObj);
        this.sortConfig['fields'] = Object.assign({}, sortObj);
        Object.keys(this.sortConfig.fields).length === 0 && (this.isSortConfigEmpty = true);
        if (source?.primary) {
            this.searchConfig.fields['requestId'].type = 'string';
            this.searchConfig.fields['instanceState.raw'].type = 'category';
            this.searchConfig.fields['instanceState.raw'].options = CommonUtilsService.INSTANCE_STATES;
            this.searchConfig.fields['instanceState.raw'].nullable = false;
            this.searchConfig.fields['instanceStatus.raw'].type =
                this.fieldOptions['instanceStatus.raw'] && this.fieldOptions['instanceStatus.raw'].length > 0 ? 'category' : 'string';
            this.searchConfig.fields['instanceStatus.raw'].operators = ['EQ', 'IN'];
            this.searchConfig.fields['instanceStatus.raw'].options = this.fieldOptions['instanceStatus.raw'];
        }
        this.searchConfig.fields['validationStatus'] = {
            name: translate('Validation Status'),
            nullable: false,
            type: 'category',
            options: CommonUtilsService.VALIDATION_STATUS,
        };
        this.searchConfig.fields['createdBy.raw'] = {
            name: translate('Created By / Updated By'),
            type: 'string',
        };
        if (Object.keys(this.searchConfig.fields).length) {
            this.queryBuilderShouldVisible = true;
        } else {
            this.queryBuilderShouldVisible = false;
        }
    };

    private setQueriesToDefault = () => {
        this.searchQuery = {
            condition: 'and',
            rules: [],
        };
        this.sortQuery = {
            condition: 'and',
            rules: [],
        };
    };

    public buildQuerries = () => {
        this.buildSearchQuery();
        this.buildSortQuery();
    };

    public buildSearchQuery = () => {
        const entity = this.selectedSource || this.sources?.[0];
        const filterCriteria = this.deOptimizeCriteria();
        const criteria = entity?.id && filterCriteria?.entityFilterCriterias?.find((criteriaItem) => criteriaItem.entityUid === entity.id);
        const definitions = criteria?.criteriaDefinitions;
        definitions?.[0]?.restriction?.rules?.forEach((rule) => {
            rule['field'] = rule.fieldId || rule['id'];
        });
        this.searchQuery = (definitions && CommonUtilsService.cloneObject(definitions?.[0]?.restriction)) || {
            condition: 'and',
            rules: [
                {
                    operator: 'EQ',
                    value: undefined,
                    fieldId: this.searchFields?.[0]?.id,
                    field: this.searchFields?.[0]?.id,
                    restrictionType: 'SingleRestrictionMetadata',
                },
            ],
        };
    };

    public buildSortQuery = (query?: { [property: string]: any }) => {
        query = query || this.sortCriteria;
        !this.sortQuery && (this.sortQuery = { condition: 'and', rules: [] });
        this.sortQuery.rules.splice(0);
        Object.keys(query || {})
            .filter((key) => this.sortConfig.fields?.[key])
            .forEach((key) => {
                this.sortQuery.rules.push({
                    field: key,
                    fieldId: key,
                    operator: '=',
                    value: query[key],
                    restrictionType: 'SingleRestrictionMetadata',
                });
            });
    };

    private appendToRuleCriteria = () => {
        const rulesClone = this.optimizeCriteria()?.entityFilterCriterias;
        const rulesToAppend = [];
        this.radioLevelRetainedRules = [];
        if (rulesClone.length) {
            rulesClone.forEach((entityFilter) => {
                entityFilter.criteriaDefinitions.forEach((criteriaDefinition) => {
                    rulesToAppend.push(criteriaDefinition);
                });
            });
            this.radioLevelRetainedRules = rulesToAppend;
            const findSelectedRadioEntity = this.radioLevelRetainedRules.find((rule) => rule.criteriaName === this.selectedSource?.id);
            let selectedRuleEntityRestriction = findSelectedRadioEntity?.restriction;
            if (selectedRuleEntityRestriction?.rules.length) {
                selectedRuleEntityRestriction = this.transformRestrictionForRendering(selectedRuleEntityRestriction, this.searchConfig);
                this.checkForLegacyFields(selectedRuleEntityRestriction);
                const hasContains: boolean = selectedRuleEntityRestriction.rules.map((rule) => rule.operator).includes('CONTAINS');
                if (hasContains) {
                    this.detatchServerExtras(selectedRuleEntityRestriction, { operator: 'CONTAINS' });
                }
                this.searchQuery = this.transformBooleanToLowerCase(selectedRuleEntityRestriction);
            }
        }
    };

    private checkForLegacyFields = (restriction) => {
        const checkForExistance = restriction.rules.find((rule) => rule.id === 'instanceStatus');
        if (checkForExistance) {
            this.pushWorkflowField('instanceStatus');
        }
    };

    private pushWorkflowField = (fieldId: string) => {
        this.searchConfig.fields[fieldId] = {
            name: 'Workflow Status',
            type: 'string',
            operators: ['EQ', 'IN'],
        };
    };

    private transformRestrictionForRendering = (restriction: any, searchConfig) => {
        if (!restriction || !restriction.rules || !restriction.rules.length) {
            return;
        }
        restriction.rules.forEach((rule) => {
            if (rule.operator) {
                rule['field'] = rule.fieldId || rule.id;
                if (rule.operator === 'EXISTS') {
                    // It is a plain rule
                    rule.operator = 'is not null';
                } else if (rule.operator === 'NOT_EXISTS') {
                    // It is a plain rule
                    rule.operator = 'is null';
                }
                if (this.getDataTypeBySearchConfig(rule.field, searchConfig) === 'date') {
                    rule.value = CommonUtilsService.transformDate(
                        rule.value,
                        'dd-mm-yyyy',
                        this.searchConfig?.fields?.[rule['field']]?.['format']
                    );
                }
            } else if (rule.condition) {
                rule = this.transformRestrictionForRendering(rule, searchConfig);
            }
        });
        delete restriction.restrictionType;
        return restriction;
    };

    private getDataTypeBySearchConfig = (fieldName, searchConfig) => {
        return (searchConfig && searchConfig.fields[fieldName] && searchConfig.fields[fieldName].type) || 'string';
    };

    private detatchServerExtras = (restriction, extras: { [property: string]: string }) => {
        switch (extras.operator) {
            case 'CONTAINS':
                if (restriction.rules?.length) {
                    restriction.rules.forEach((rule) => {
                        const isTruthy = rule.value?.includes('*');
                        if (isTruthy) {
                            rule.value = rule.value?.slice(1, -1);
                        }
                    });
                }
                break;
            default:
                break;
        }
    };

    private appendEmptyRule = () => {
        const fieldId = this.searchFields?.[0]?.id;
        const rule = fieldId && {
            field: fieldId,
            operator: 'EQ',
            value: undefined,
        };
        const emptyRuleObject = {
            condition: 'and',
            rules: rule ? [rule] : [],
        };
        this.searchQuery = emptyRuleObject as {
            condition: string;
            rules: {
                field: any;
                operator: string;
                value: any;
            }[];
        };
    };

    public createRelationModel = (dontAddEmptyRule: boolean) => {
        this.relation = [];
        const field = {
            displayName: translate('Criteria'),
            name: 'Criteria',
            value: '',
            datatype: 'category',
            id: 'Criteria',
        };
        this.relation.push(field);
        this.fetchRelationFields(field);
        if (this.relationQuery.rules.length === 0 && !dontAddEmptyRule) {
            this.appendEmptyRelation();
        }
        this.relationQueryConfigChange.next({ searchConfig: this.relationConfig });
    };

    private fetchRelationFields = (field) => {
        const relationObj = {};
        const id = field.id;
        relationObj[id] = {
            name: field.displayName,
            type: 'category',
            operators: ['EQ'],
            nullable: false,
            options: [],
            value: '',
        };
        const criteria = this.deOptimizeCriteria();
        criteria.entityFilterCriterias.forEach((criteria) => {
            relationObj[id]['options'].push({
                name: criteria.entityUid,
                value: criteria.entityUid,
            });
        });
        this.relationConfig['fields'] = Object.assign({}, relationObj);
    };

    private appendEmptyRelation = () => {
        const emptyRuleObject = {
            condition: 'and',
            rules: [
                {
                    field: this.relation[0].id,
                    operator: 'EQ',
                    value: undefined,
                },
            ],
        };
        this.relationQuery = emptyRuleObject as any;
    };

    public buildCriterias = (validation?: boolean, silent?: boolean, skipBuildAutoRelations?: boolean) => {
        let hasErrors = false;
        hasErrors = !this.buildSortCriteria(silent) || hasErrors;
        hasErrors = !this.buildSearchCriteria(validation, hasErrors, silent, skipBuildAutoRelations) || hasErrors;
        return !hasErrors;
    };

    public buildSortCriteria = (silent?: boolean) => {
        const sort: {
            [property: string]: string;
        } = {} as const;
        this.correctQuery(this.sortQuery);
        if (!this.isValidCriteria(this.sortQuery, 'SORT')) {
            !silent && this._utils.alertError(translate('Sort criteria is not valid'));
            return false;
        }
        this.sortQuery?.rules?.forEach((rule) => {
            sort[rule.field] = rule.value;
        });
        this.setSortCriteria(sort);
        return sort;
    };

    private getRulesLength = (criteria: FilterCriteria) => {
        let length = 0;
        criteria.entityFilterCriterias
            ?.filter((entity) => entity.entityUid !== this.selectedSource?.id)
            ?.forEach((entity) => {
                entity.criteriaDefinitions?.forEach((definition) => {
                    length += definition.restriction.rules.length;
                });
            });
        return length;
    };

    private removeEmptyRules = (rules: Search['searchQuery']['rules']) => {
        rules.forEach((rule, index) => {
            if (rule.rules?.length > 0) {
                this.removeEmptyRules(rule.rules);
                rule.rules.length === 0 && rules.splice(index, 1);
            } else if (rule.operator !== 'is not null' && rule.operator !== 'is null' && this._utils.isEmpty(rule.value)) {
                rules.splice(index, 1);
            }
        });
    };

    public buildSearchCriteria = (validation?: boolean, hasErrors?: boolean, silent?: boolean, skipBuildAutoRelations?: boolean) => {
        const updated = this.criteriaUpdated;
        this.correctQuery(this.searchQuery);
        const restriction: Search['searchQuery'] = CommonUtilsService.cloneObject(this.searchQuery);
        let dontCheckOnRestriction = false;
        const otherEntitiesRulesLength = this.getRulesLength(this.filterCriteria);
        if (validation !== undefined && otherEntitiesRulesLength > 0) {
            this.removeEmptyRules(restriction.rules);
            restriction.rules.length === 0 && (dontCheckOnRestriction = true);
        } else if ((!restriction.rules || restriction.rules.length === 0) && otherEntitiesRulesLength > 0) {
            this.removeEmptyRules(restriction.rules);
            dontCheckOnRestriction = true;
        } else if (!restriction.rules || restriction.rules.length === 0) {
            !silent && this._utils.alertError(translate('Search criteria is not valid'));
            this.updateSearchCriteria(this.filterCriteria, this.selectedSource, dontCheckOnRestriction);
            return false;
        }
        const isINArray = restriction.rules.length > 0 && Array.isArray(restriction.rules[0].value);
        const isValidINRule = this.isValidINRule(restriction);
        if (
            (!dontCheckOnRestriction && !restriction.rules.length) ||
            (!dontCheckOnRestriction && !restriction.rules.length && !this.filterCriteria.entityFilterCriterias.length) ||
            (isINArray && isValidINRule === 0)
        ) {
            !silent && this._utils.alertError(translate('Search criteria cannot be empty'));
            return false;
        } else if (restriction.rules.length === 0 && this.filterCriteria.entityFilterCriterias.length > 0) {
            const value = this.updateSearchCriteria(this.filterCriteria, this.selectedSource, dontCheckOnRestriction);
            if (value) {
                this.criteriaUpdated = true;
                this.selectedSource?.name &&
                    this._utils.alertCustomSuccess(`${this.selectedSource.name} ${translate('Criteria has been updated')}`);
                const criteriaRelations: Restriction =
                    this.filterCriteria.criteriaRelations?.rules?.length > 0
                        ? {
                              ...this.filterCriteria.criteriaRelations,
                              rules: [],
                          }
                        : {
                              restrictionType: 'JoinedRestrictionMetadata',
                          };
                this.onCriteriaChange(
                    {
                        ...this.filterCriteria,
                        criteriaRelations: criteriaRelations,
                    },
                    true
                );
                !skipBuildAutoRelations && this.autoTriggerRelations();
                return true;
            }
        } else if (!dontCheckOnRestriction && !this.isValidCriteria(restriction)) {
            !silent && this._utils.alertError(translate('Search criteria is not valid'));
            return false;
        } else if (!dontCheckOnRestriction) {
            this.applyRecurssionToRules(restriction, this.searchConfig);
            const criteriaDefinition = {
                type: 'CriteriaDefinition',
                criteriaName: this.selectedSource.id,
                restriction,
            };
            const entityFilterCriteria = {
                entityUid: this.selectedSource.id,
                criteriaDefinitions: [],
            };
            entityFilterCriteria.criteriaDefinitions.push(criteriaDefinition);
            const callBackEntityFilterCriteria = this.checkForExistance(entityFilterCriteria, this.filterCriteria);
            if (typeof callBackEntityFilterCriteria === 'object') {
                this.onCriteriaChange({
                    entityFilterCriterias: [...this.filterCriteria.entityFilterCriterias, callBackEntityFilterCriteria],
                });
            } else {
                const criteria = CommonUtilsService.cloneObject(this.filterCriteria);
                criteria.entityFilterCriterias[callBackEntityFilterCriteria] = entityFilterCriteria;
                this.onCriteriaChange(criteria);
            }
            !validation &&
                !hasErrors &&
                !silent &&
                this._utils.alertCustomSuccess(
                    `${this.selectedSource.name} ${translate('Criteria has been')} ${updated ? translate('updated') : translate('added')}`
                );
            const criteriaRelations =
                this.filterCriteria.criteriaRelations?.rules?.length > 0
                    ? this.filterCriteria.criteriaRelations
                    : {
                          restrictionType: 'JoinedRestrictionMetadata',
                      };
            this.onCriteriaChange({
                ...this.filterCriteria,
                criteriaRelations: criteriaRelations,
            });
            !skipBuildAutoRelations && this.autoTriggerRelations();
            this.criteriaUpdated = true;
            return true;
        }
    };

    private correctQuery = (query: { condition?: string; rules?: SearchRule[] }) => {
        query?.rules?.forEach((rule) => {
            if (Array.isArray(rule.value)) {
                rule.value
                    .filter((value) => typeof value === 'string')
                    .forEach((value) => {
                        value = value.trim();
                    });
            } else if (typeof rule.value === 'string') {
                rule.value = rule.value.trim();
            }
        });
    };

    private isValidINRule = (restriction): number => {
        const isArray = Array.isArray(restriction.rules.length && restriction.rules[0].value);
        if (isArray) {
            return restriction.rules[0].value.length;
        }
    };

    private updateSearchCriteria = (filterCriteria, selectedSearchEntity: SearchSource, dontCheckOnRestriction: boolean) => {
        const checkForExistance = filterCriteria.entityFilterCriterias?.find((criteria) => criteria.entityUid === selectedSearchEntity?.id);
        const checkForRelationExistance = filterCriteria.criteriaRelations?.rules?.find((rule) => rule.value === selectedSearchEntity?.id);
        if (checkForRelationExistance) {
            const index = filterCriteria.criteriaRelations.rules.findIndex((rule) => rule.value === checkForRelationExistance.value);
            filterCriteria.criteriaRelations.rules.splice(index, 1);
        }
        if (checkForExistance) {
            const index = filterCriteria.entityFilterCriterias.findIndex((criteria) => criteria.entityUid === checkForExistance.entityUid);
            filterCriteria.entityFilterCriterias.splice(index, 1);
            return true;
        }
        if ((!checkForExistance && !checkForRelationExistance) || dontCheckOnRestriction) {
            return true;
        }
        this._utils.alertError(translate('Search criteria cannot be empty'));
    };

    private isValidCriteria = (restriction, type?: 'SORT') => {
        let isValid = true;
        if ((!restriction || !restriction.rules || !restriction.rules.length) && type !== 'SORT') {
            isValid = false;
        }
        restriction.rules.forEach((rule) => {
            if (rule.operator === 'is not null' || rule.operator === 'is null') {
                return isValid;
            } else {
                if (isValid && rule.rules) {
                    isValid = this.isValidCriteria(rule);
                } else {
                    const ruleValue = typeof rule.value === 'number' ? rule.value.toString() : rule.value;
                    isValid = isValid && ruleValue !== undefined && ruleValue ? true : false;
                }
            }
        });
        return isValid;
    };

    private applyRecurssionToRules = (rule, searchConfig) => {
        if ('rules' in rule) {
            if (rule.collapsed !== 'null' && rule.collapsed !== 'undefined') {
                delete rule.collapsed;
            }
            rule.condition = rule.condition.toUpperCase();
            rule.rules.forEach((innerRule) => {
                const field = this.getField(innerRule.field, searchConfig);
                if (innerRule.field && this.getDataTypeOfField(innerRule.field, searchConfig) === 'date') {
                    if (innerRule.value instanceof Date) {
                        innerRule.value = this._utils.transformDate(innerRule.value, 'DATE', 'dd-mm-yyyy');
                    } else {
                        innerRule.value = this._utils.transformDate(innerRule.value, field.format as any, 'dd-mm-yyyy');
                    }
                }
                if (innerRule.operator === 'CONTAINS') {
                    if (innerRule.value.toString().indexOf('*') !== 0) {
                        if (typeof innerRule.value === 'string') {
                            innerRule.value = innerRule.value.trim();
                        }
                        innerRule.value = '*' + innerRule.value + '*';
                    }
                } else if (innerRule.operator === 'is null') {
                    innerRule.operator = 'NOT_EXISTS';
                    delete innerRule.value;
                } else if (innerRule.operator === 'is not null') {
                    innerRule.operator = 'EXISTS';
                    delete innerRule.value;
                } else if (innerRule.operator === '!=') {
                    innerRule.operator = 'NOT_EQUALS';
                }
                this.applyRecurssionToRules(innerRule, searchConfig);
            });
            rule['restrictionType'] = 'JoinedRestrictionMetadata';
        } else {
            rule['fieldId'] = rule['field'];
            rule['restrictionType'] = 'SingleRestrictionMetadata';
            if (rule.collapsed !== 'null' && rule.collapsed !== 'undefined') {
                delete rule.collapsed;
            }
            if (rule?.id) {
                delete rule.id;
            }
            if (rule?.type) {
                delete rule.type;
            }
        }
    };

    private checkForExistance = (
        entityFilterCriteria: {
            entityUid: string;
            criteriaDefinitions: any[];
        },
        filterCriteria: FilterCriteria
    ) => {
        if (!filterCriteria.entityFilterCriterias.find((entityFilter) => entityFilter.entityUid === entityFilterCriteria.entityUid)) {
            return entityFilterCriteria;
        } else {
            const entityIndex = filterCriteria.entityFilterCriterias.findIndex(
                (entityFilter) => entityFilter.entityUid === entityFilterCriteria.entityUid
            );
            return entityIndex;
        }
    };

    private getField = (fieldName: string, searchConfig: { [fieldName: string]: SearchField }): SearchField => {
        return searchConfig?.fields[fieldName];
    };

    private getDataTypeOfField = (fieldName, searchConfig) => {
        return searchConfig?.fields[fieldName]?.type || 'string';
    };

    private autoTriggerRelations = () => {
        const relationRule = this.buildAutoRelationsQuery(this.filterCriteria);
        relationRule && this.onRelationQueryChanged(relationRule);
    };

    private buildAutoRelationsQuery = (filterCriteria: FilterCriteria) => {
        const relations: FilterCriteria['entityFilterCriterias'] = CommonUtilsService.cloneObject(filterCriteria.entityFilterCriterias);
        let relationRule: Restriction;
        if (this.relationQuery.rules?.length > 0) {
            relationRule = CommonUtilsService.cloneObject(this.relationQuery);
        } else if (filterCriteria.criteriaRelations) {
            relationRule = CommonUtilsService.cloneObject(filterCriteria.criteriaRelations);
        } else {
            relationRule = {
                condition: 'and',
                rules: [],
            };
        }
        const noRuleEntityIds: string[] = [];
        relations.forEach((relation) => {
            noRuleEntityIds.push(relation.entityUid);
        });
        this.filterRelationRules(relationRule, noRuleEntityIds);
        const entityIds: string[] = [];
        let found: SearchRule;
        relations.forEach((relation) => {
            relationRule.rules = relationRule.rules || [];
            relationRule.condition = relationRule.condition || 'and';
            found = this.checkForRule(relationRule.rules, relation.entityUid);
            !found && entityIds.push(relation.entityUid);
        });
        entityIds.forEach((id) => {
            const emptyRule = relationRule.rules.find((rule) => rule.value === undefined);
            if (emptyRule) {
                emptyRule.value = id;
                return;
            }
            relationRule.rules.push({
                field: 'Criteria',
                operator: 'EQ',
                value: id,
            } as any as SearchRule);
        });
        return relationRule as any as RuleSet;
    };

    private filterRelationRules(relationRule, noRuleEntityIds) {
        relationRule.rules = relationRule.rules.filter((rule) => {
            if (typeof rule.value === 'string') {
                return noRuleEntityIds.includes(rule.value);
            } else if (rule.rules && rule.rules.length > 0) {
                this.filterRelationRules(rule, noRuleEntityIds);
                return rule.rules.length > 0;
            }
            return false;
        });
    }

    private checkForRule = (rule: SearchRule | SearchRule[], entityId: string) => {
        let foundRule: SearchRule;
        if (Array.isArray(rule)) {
            rule.forEach((rule) => (foundRule = foundRule || this.checkForRule(rule, entityId)));
        } else {
            if (rule.value === entityId) {
                foundRule = rule;
            } else if (rule.hasOwnProperty('rules')) {
                foundRule = foundRule || this.checkForRule(rule['rules'], entityId);
            }
        }
        return foundRule;
    };

    public applyRelations = () => {
        this.onRelationQueryChanged(this.relationQuery, true);
    };

    private onRelationQueryChanged = (data: RuleSet, trigger?: boolean) => {
        const relation = CommonUtilsService.cloneObject(data);
        if (relation.rules.length === 0) {
            this._utils.alertError(translate('Relation criteria cannot be empty'));
            return;
        } else if (!this.isValidCriteria(data)) {
            this._utils.alertError(translate('Relation criteria is not valid'));
            return;
        } else {
            this.relationQuery = data;
            this.applyRecurssionToRules(relation, this.relationConfig);
            const criteriaRelations = {
                ...relation,
                restrictionType: relation.restrictionType || 'JoinedRestrictionMetadata',
            };
            this.onCriteriaChange({
                ...this.filterCriteria,
                criteriaRelations: criteriaRelations,
            });
            if (trigger) {
                this._utils.alertCustomSuccess(translate('Relation has been applied'));
                this.relationsUpdated = true;
            }
        }
    };

    private appendToRelationCriteria = () => {
        const tabLevelRetainedRelations = [];
        let relationsClone: Restriction;
        if (this.relationQuery?.rules?.length > 0) {
            relationsClone = CommonUtilsService.cloneObject(this.relationQuery);
        } else {
            relationsClone = CommonUtilsService.cloneObject(this.filterCriteria.criteriaRelations);
        }
        if (Object.keys(relationsClone || {}).length > 0 && relationsClone.rules?.length > 0) {
            relationsClone.rules.forEach((rule) => {
                this.applyRecurssionForRelations(rule);
            });
            delete relationsClone.restrictionType;
            tabLevelRetainedRelations.push(relationsClone);
            this.relationQuery = this.transformBooleanToLowerCase(relationsClone);
        }
    };

    private applyRecurssionForRelations = (rule) => {
        if ('rules' in rule) {
            rule.rules.forEach((innerRule) => {
                this.applyRecurssionForRelations(innerRule);
            });
        } else {
            rule['field'] = rule.field ?? rule.fieldId ?? rule.id;
        }
    };

    private transformBooleanToLowerCase = (queryObj) => {
        if (!queryObj) {
            return;
        }
        if (queryObj['condition'] === 'AND') {
            queryObj['condition'] = 'and';
        } else if (queryObj['condition'] === 'OR') {
            queryObj['condition'] = 'or';
        }
        if (queryObj.rules && queryObj.rules.length) {
            for (let i = 0; i < queryObj.rules.length; i++) {
                queryObj.rules[i] = this.transformBooleanToLowerCase(queryObj.rules[i]);
            }
        }
        return queryObj;
    };

    public setSources = (sources: SearchSource[]) => {
        this.sources = sources;
    };

    public setServiceId = (serviceId: string) => {
        this.setFilterCriteria({
            ...this.filterCriteria,
            serviceId: serviceId,
        });
    };

    public convertEntitiesAndSetAsSources = (entities: Entity[], setDefaults?: boolean, skipBuildAutoRelations?: boolean) => {
        const sources = entities
            .filter((entity) => entity.show)
            .reduce((sources, entity) => {
                sources.push(this.convertEntity(entity));
                return sources;
            }, [] as SearchSource[]);
        this.setSources(sources);
        setDefaults && this.setPrimaryAsSource();
        setDefaults && this.clearSearchQueries(undefined, undefined, undefined, skipBuildAutoRelations);
    };

    public setPrimaryAsSource = () => {
        const primarySourceId = this.sources?.find((source) => source.primary)?.id;
        primarySourceId && this.setSource(primarySourceId);
    };

    public convertEntities = (entities: Entity[]): SearchSource[] => {
        return entities
            .filter((entity) => entity.show)
            .reduce((sources, entity) => {
                sources.push(this.convertEntity(entity));
                return sources;
            }, [] as SearchSource[]);
    };

    public convertEntity = (entity: Entity): SearchSource => {
        return {
            name: entity.displayName || entity.name,
            fields: this.convertFields(entity.fields),
            id: entity.uid || entity['id'],
            primary: entity.primary,
            sources: this.convertEntities(entity.entities || entity['sources'] || []),
        };
    };

    private convertFields = (fields: Field[]): SearchField[] => {
        return fields
            .filter((field) => field.show)
            .reduce((searchFields, field) => {
                searchFields.push(this.convertField(field));
                return searchFields;
            }, [] as SearchField[]);
    };

    private convertField = (field: Field): SearchField => {
        return {
            id: field.uid,
            name: field.displayName || field.name,
            searchable: field.searchable,
            sortable: field.sortable,
            value: field.defaultValue,
            datatype: field.datatype,
            format: field.outputFormat,
        };
    };

    public removeRequestRule = (criteria?: FilterCriteria) => this.removeCustomRules(criteria, ['requestId']);

    public removeCustomRules = (criteria?: FilterCriteria, customProps?: string[]) => {
        criteria = criteria || this.filterCriteria;
        if (!criteria) {
            return;
        }
        criteria.entityFilterCriterias.forEach((entityFilterCriteria) => {
            let rulesLength = 0;
            entityFilterCriteria.criteriaDefinitions.forEach((criteriaDefinition) => {
                ['assetDataId', 'parentRecordId', ...(customProps || [])].forEach((id) => {
                    const index = criteriaDefinition.restriction.rules.findIndex((rule) => rule.fieldId === id);
                    index > -1 && criteriaDefinition.restriction.rules.splice(index, 1);
                });
                rulesLength = Math.max(rulesLength, criteriaDefinition.restriction.rules.length);
            });
            if (rulesLength === 0) {
                entityFilterCriteria.criteriaDefinitions = [];
                const index = criteria.criteriaRelations.rules.findIndex((rule) => rule.value === entityFilterCriteria.entityUid);
                index > -1 && criteria.criteriaRelations.rules.splice(index, 1);
            }
        });
        criteria.entityFilterCriterias = criteria.entityFilterCriterias.filter(
            (filterCriteria) => filterCriteria.criteriaDefinitions.length > 0
        );
    };

    private getRuleSource = (entityFilterCriterias: EntityFilterCriterias[], entityId: string) => {
        return entityFilterCriterias?.find((criteria) => criteria.entityUid === entityId);
    };

    public filteroutEntities = (criteria: FilterCriteria, entities: string[]) => {
        criteria.criteriaRelations.rules = criteria.criteriaRelations.rules.filter((rule) => entities.includes(rule.value as string));
        criteria.entityFilterCriterias = criteria.entityFilterCriterias.filter((criteria) => entities.includes(criteria.entityUid));
    };

    public changeRelationToAnd = (criteria: FilterCriteria) => {
        criteria?.criteriaRelations && (criteria.criteriaRelations.condition = 'AND');
    };

    public pushRule = (ruleToPush: SearchRule, criteria: FilterCriteria, entityId: string) => {
        let entityCriteria = this.getRuleSource(criteria.entityFilterCriterias, entityId);
        const previousRestriction = CommonUtilsService.cloneObject(entityCriteria?.criteriaDefinitions?.[0]?.restriction);
        if (!entityCriteria) {
            entityCriteria = {
                entityUid: entityId,
                criteriaDefinitions: [
                    {
                        criteriaName: entityId,
                        restriction: {
                            condition: 'AND',
                            rules: [ruleToPush],
                            restrictionType: 'JoinedRestrictionMetadata',
                        },
                        type: 'CriteriaDefinition',
                    },
                ],
            };
            criteria.entityFilterCriterias.push(entityCriteria);
        } else if (previousRestriction.condition === 'AND') {
            this.checkAndPushRule(entityCriteria.criteriaDefinitions[0].restriction.rules, ruleToPush);
        } else {
            entityCriteria.criteriaDefinitions[0].restriction.rules = [ruleToPush, previousRestriction];
            entityCriteria.criteriaDefinitions[0].restriction.condition = 'AND';
        }
        if (!criteria.criteriaRelations?.rules?.length) {
            const criteriaRelations = {
                condition: 'AND',
                rules: [],
                restrictionType: 'JoinedRestrictionMetadata',
            };
            criteria.entityFilterCriterias.forEach((entityFilterCriteria) => {
                criteriaRelations.rules.push({
                    operator: 'EQ',
                    value: entityFilterCriteria.criteriaDefinitions[0].criteriaName,
                    fieldId: 'Criteria',
                    restrictionType: 'SingleRestrictionMetadata',
                });
            });
            criteria.criteriaRelations = criteriaRelations;
        } else {
            const index = criteria.criteriaRelations.rules.find((rule) => rule.value === entityId);
            !index &&
                criteria.criteriaRelations.rules.push({
                    operator: 'EQ',
                    value: entityId,
                    fieldId: 'Criteria',
                    restrictionType: 'SingleRestrictionMetadata',
                });
        }
    };

    private checkAndPushRule = (rules: SearchRule[], ruleToPush: SearchRule) => {
        const ruleFound = rules.find((ruleObject) => ruleObject.fieldId === ruleToPush.fieldId);
        if (ruleFound) {
            Object.assign(ruleFound, ruleToPush);
        } else {
            rules.push(ruleToPush);
        }
    };

    static getRecordsSearchCriteria = (
        restApiName: string,
        serviceId: string,
        instanceId: string,
        arrayEntity: {
            entityId: string;
            parentRecordId: string;
        },
        size?: number
    ) => {
        const hasParentId = arrayEntity.parentRecordId;
        let entityDetailsToPush: EntityFilterCriterias = {
            entityUid: arrayEntity.entityId,
            criteriaDefinitions: [
                {
                    criteriaName: arrayEntity.entityId,
                    restriction: {
                        condition: 'AND',
                        rules: [
                            {
                                fieldId: hasParentId ? 'parentRecordId' : 'assetDataId',
                                operator: 'EQ',
                                value: arrayEntity.parentRecordId || instanceId,
                                restrictionType: 'SingleRestrictionMetadata',
                            },
                        ],
                        restrictionType: 'JoinedRestrictionMetadata',
                    },
                    type: 'CriteriaDefinition',
                },
            ],
        };
        this.searchFields?.length > 0 &&
            this.searchFields.forEach((fld) => {
                entityDetailsToPush.criteriaDefinitions[0].restriction.rules.push({
                    fieldId: fld.id,
                    operator: ['INT', 'LONG', 'DOUBLE', 'DECIMAL', 'FLOAT', 'DATE'].includes(fld.datatype) ? 'EQ' : 'CONTAINS',
                    value: this.getValueByDatatype(fld),
                    restrictionType: 'SingleRestrictionMetadata',
                });
            });
        return {
            entityId: arrayEntity.entityId,
            restApiServiceName: restApiName,
            filterCriteria: {
                serviceId: serviceId,
                entityFilterCriterias: [entityDetailsToPush],
                criteriaRelations: {
                    condition: 'AND',
                    rules: [
                        {
                            operator: 'EQ',
                            value: arrayEntity.entityId,
                            fieldId: 'Criteria',
                            restrictionType: 'SingleRestrictionMetadata',
                        },
                    ],
                    restrictionType: 'JoinedRestrictionMetadata',
                },
            },
            size: size || 20,
        };
    };

    private static getValueByDatatype = (field) => {
        switch (field.datatype) {
            case 'INT':
            case 'LONG':
            case 'DOUBLE':
            case 'DECIMAL':
            case 'FLOAT':
                return field.value;
            case 'DATE':
                return CommonUtilsService.transformDateToLocale(field.value, 'dd/mm/yyyy', 'dd-mm-yyyy', false);
            default:
                return `*${field.value}*`;
        }
    };

    public filteroutRules = (criteria: FilterCriteria, entities: string[], exemptedFieldIds: string[]) => {
        criteria.entityFilterCriterias = criteria.entityFilterCriterias.reduce((filterCriterias, criteria) => {
            if (entities.includes(criteria.entityUid)) {
                criteria.criteriaDefinitions = criteria.criteriaDefinitions.reduce((definitions, definition) => {
                    definition.restriction.rules = definition.restriction.rules.reduce((rules, rule) => {
                        exemptedFieldIds.includes(rule.fieldId) && rules.push(rule);
                        return rules;
                    }, []);
                    definition.restriction.rules?.length > 0 && definitions.push(definition);
                    return definitions;
                }, []);
            }
            criteria.criteriaDefinitions?.length > 0 && filterCriterias.push(criteria);
            return filterCriterias;
        }, []);
    };

    static getOutputSources = (entities: Entity[], criteria?: FilterCriteria) => {
        if (criteria.outputEntities?.length > 0) {
            return criteria.outputEntities;
        }
        return Search.getSubEntities(entities);
    };

    private static getSubEntities = (entities: Entity[]) => {
        return entities.reduce((entitiesList, entity) => {
            entitiesList.push(entity.uid);
            entity.entities?.length > 0 && entitiesList.push(...this.getSubEntities(entity.entities));
            return entitiesList;
        }, [] as string[]);
    };

    public getSearchFields = () => this.searchFields;

    public pushFieldOptions = (fieldId: string, options: { name: string; value: string }[]) => {
        if (!this.fieldOptions[fieldId]) {
            this.fieldOptions[fieldId] = [];
        }
        this.fieldOptions[fieldId] = options;
        this.setFieldOptions(fieldId);
    };

    private setFieldOptions = (fieldId: string) => {
        if (this.searchConfig.fields?.[fieldId]) {
            const hasOptions = this.fieldOptions[fieldId] && this.fieldOptions[fieldId].length > 0;
            this.searchConfig.fields[fieldId] = {
                name: translate('Workflow Status'),
                nullable: false,
                type: hasOptions ? 'category' : 'string',
                options: hasOptions ? this.fieldOptions[fieldId] : [],
                operators: hasOptions && ['EQ', 'IN'],
            };
            this.searchQueryConfigChange.next({ searchConfig: this.searchConfig, sortConfig: this.sortConfig });
        }
    };
}

export interface SearchSource {
    id: string;

    name: string;

    sources?: SearchSource[];

    fields: SearchField[];

    primary: boolean;
}

export interface SearchField {
    id: string;

    value: any;

    searchable: boolean;

    name: string;

    sortable: boolean;

    datatype: string;

    format: string;
}
