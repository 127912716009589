import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Record } from 'taxilla-library';

@Component({
    selector: 'entity-search-filter',
    templateUrl: './entity-search-filter.component.html',
    styleUrls: ['./entity-search-filter.component.scss'],
})
export class EntitySearchFilter implements OnInit, OnDestroy {
    @Input() columnName: string;
    @Input() record: Record;
    @Output() onSearch = new EventEmitter();

    public fieldId: string;

    ngOnInit() {
        this.fieldId = this.record.getFieldByFieldName(this.columnName)?.id || this.columnName;
    }

    ngOnDestroy() {}
}
